import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Listagem from "../../../componentes/Listagem";
import FormGroup from "../../../componentes/form-group";
import { getRols, salvarRol, excluirRol, filtroRol } from "../../../util/Api";
import "./index.css";

const esquema = Yup.object().shape({
  titulo: Yup.string().min(3, "Obrigatório").required("Obrigatório"),
});

const CadastroRol = (props) => {
  const [rols, setRols] = useState({ content: [] });
  const [atualiza, setAtualiza] = useState(false);
  const formikRef = useRef();
  const [classBtn, setClassBtn] = useState("btn-success");
  const { setLoading } = props;
  const [filtro, setFiltro] = useState({});

  const findRols = () => {
    setLoading(true);
    getRols().then((data) => {
      setRols({ content: data });
      setLoading(false);
    });
  };

  const filtrarRols = () => {
    filtroRol(filtro).then((data) => {
      data.changePage = pageChange;
      setRols(data);
      setLoading(false);
    });
  }

  useEffect(() => {
    findRols();
    filtrarRols();
    // eslint-disable-next-line
  }, []);

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    filtroRol(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setRols(data);
      setLoading(false);
    });
  };

  const excluir = (rol) => {
    excluirRol(rol.id)
      .then(() => {
        Alert.warning("Rol excluído com sucesso!");
        findRols();
        filtrarRols();
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  const editarRol = async (procedimento) => {
    await setAtualiza(true);
    setClassBtn("btn-info");
    formikRef.current.values.titulo = procedimento.titulo;
    formikRef.current.values.id = procedimento.id;
    formikRef.current.values.tipo = procedimento.tipo;
    await setAtualiza(false);
  };

  const salvar = (formulario) => {
    if (formulario?.tipo === null || formulario?.tipo === '') {
      Alert.warning("Selecione um tipo");
      return;
    }
    salvarRol(formulario)
      .then(() => {
        if (classBtn === "btn-success") {
          Alert.success("Rol cadastrado com sucesso!");
        } else {
          Alert.success("Rol alterado com sucesso!");
        }
        findRols();
        filtrarRols();
        formulario.titulo = "";
        formulario.id = null;
        formulario.tipo = "";
        setClassBtn("btn-success");
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  return (
    <div className="container robotR">
      <div className="row">
        {atualiza ? null : (
          <div className="col-12 mt-2">
            <h4>Cadastro de Rols</h4>
          </div>
        )}
      </div>
      <Formik
        className="row"
        innerRef={formikRef}
        initialValues={{
          titulo: "",
          tipo: "",
          id: null,
        }}
        validationSchema={esquema}
        onSubmit={(values) => {
          salvar(values);
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form className="mb-1">
            <div className="col-lg-12">
              <div className="bs-component">
                <div className="row">
                  <div className="col-md-8">
                    <FormGroup
                      label="Título: "
                      htmlFor="inputTipoDeProcedimento"
                    >
                    <Field
                        type="text"
                        placeholder="digite o rol a ser cadastrado"
                        className={`form-control ${
                          errors.titulo && touched.titulo ? "invalid" : ""
                        }`}
                        name="titulo"
                      />
                      {errors.titulo && touched.titulo ? (
                        <span className="text-error">{errors.titulo}</span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-4">
                    <FormGroup label="Tipo " htmlFor="inputTipoDeProcedimento">
                      <Field name="tipo" as="select" className="form-control">
                        <option value="" selected disabled hidden>
                          Selecione o tipo
                        </option>
                        {/* {rol.map((r) => {
                          return (
                            <option value={r.id} key={r.id}>
                              {r.titulo}
                            </option>
                          );
                        })} */}
                        <option value="CIVEL" key="CIVEL">
                          Cível
                        </option>
                        <option value="PREVIDENCIARIO" key="PREVIDENCIARIO">
                          Previdenciário
                        </option>
                        <option value="TRABALHISTA" key="TRABALHISTA">
                          Trabalhista
                        </option>
                      </Field>
                      {errors?.tipo && touched?.tipo ? (
                        <span className="text-error erroSelectTexto">
                          {errors?.tipo}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-5 ptop">
                    <button
                      type="submit"
                      onClick={() => {
                        if (!isValid) {
                          Alert.warning("Preencha todos os campos destacados");
                        }
                      }}
                      className="btn buttonSalvarRol mx-1"
                    >
                      Salvar
                    </button>
                    <button type="reset" className="btn buttonLimparRol mx-1">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-1 bg-branco">
        <Listagem
          tituloPlano
          tipoRol
          paginacao={rols}
          botaoEditar={editarRol}
          botaoExcluir={excluir}
        />
      </div>
    </div>
  );
};

export default CadastroRol;
