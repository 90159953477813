import React, { useState, useEffect } from "react";
import Alert from "react-s-alert";
// import ReactTooltip from "react-tooltip";
import Cards from "react-credit-cards";
import GoBack from "./goBack.jsx";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import "./index.css";
import "react-credit-cards/es/styles-compiled.css";
import { checkOutPayMathc, getPlanoById, getVerificaCupom, getCurrentUser } from "../util/Api";
import { createHashHistory } from "history";
import { ACCESS_TOKEN } from "../constantes";
import { useParams } from "react-router-dom";
import "./pagamento.css";
import AppFooterLogado from "./AppFooterLogado.jsx";

const history = createHashHistory();

const pagamentoSchema = Yup.object().shape({
    name: Yup.string().min(6, "Obrigatório").required("Obrigatório"),
    number: Yup.string()
      .min(19, "Número inválido")
      .max(19, "Número inválido")
      .required("Obrigatório"),
    expiry: Yup.string()
      .min(7, "ex: 12/2028")
      .max(7, "Número inválido!")
      .required("Obrigatório"),
    cvc: Yup.string()
      .min(3, "Número inválido")
      .max(3, "Número inválido")
      .required("Obrigatório"),
    parcelas: Yup.number()
      .min(1, "Mínino uma parcela")
      .max(12, "Máximo 12 parcelas")
      .required("Obrigatório"),
});


const Pagamento = (props) => {
    const [isBoleto, setIsBoleto] = useState(false);
    const { id } = useParams();
    const { setLoading } = props;
    const [plano, setPlano] = useState({});
    const [formaPagamento, setFormaPagamento] = useState("boleto");
    const [isValidCupom, setIsValidCupom] = useState({});
    const [cupom, setCupom] = useState("");
    const [newValue, setNewValue] = useState(0);
    const [buttonDesativado, setButtonDesativado] = useState(false);

    const [currentUser, setCurrentUser] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
  // const [apareceRodape, setApareceRodape] = useState(true);

  const loadCurrentlyLoggedInUser = (pagina) => {
    if(pagina === undefined){
      pagina = "/modulos";
    }
    setLoading(true);

    getCurrentUser()
      .then((data) => {
        setAuthenticated(true);
        setCurrentUser(data);
        setLoading(false);
        history.push(pagina);
      })
      .catch((error) => {
        localStorage.removeItem(ACCESS_TOKEN);
        setLoading(false);
      });
  };

    const trocarBoleto = (boletoNome, boletoBoolean) => {
      setIsBoleto(boletoBoolean);
      setFormaPagamento(boletoNome);
    }
  
    function cc_format(value) {
      var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      var matches = v.match(/\d{4,16}/g);
      var match = (matches && matches[0]) || "";
      var parts = [];
      var len = 0;
  
      // eslint-disable-next-line
      for (var i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
  
      if (parts.length) {
        return parts.join(" ");
      } else {
        return value;
      }
    }

    const verificaCupomExistente = (titulo, plano, desativar) => {
        setLoading(true);
        getVerificaCupom(titulo).then((data) => {
          setIsValidCupom(data);
        });

        if (desativar === "cancelar") {
            setButtonDesativado(false);
            setNewValue(plano.valor);
        }

        if (isValidCupom.length > 0 && desativar === "ok") {
            setButtonDesativado(true);
            setNewValue((plano.valor - (plano.valor - (plano.valor - ((plano.valor * isValidCupom[0].porcentagem)/100)))).toFixed(2));
            Alert.info("Cupom ativado");
        } else {
            setButtonDesativado(false);
            setNewValue(plano.valor);
        }
        setLoading(false);
    }
  
    const handleSubmit = (values) => {
      props.setLoading(true);
      checkOutPayMathc({
        ...values,
        idPlano: plano.id,
        valor: newValue,
        isBoleto: isBoleto,
        isModulo: props.modulo === true,
        idCupom: isValidCupom.length > 0 ? isValidCupom[0].id : 0,
      })
        .then((data) => {
          setLoading(false);
          // props.setIsOpen(false);
          Alert.info(data.ResponseDetail.Description, { timeout: 10000 });
          if (isBoleto) {
            window.open(data.ResponseDetail.BankSlipUrl, "_blank");
          } else {
            loadCurrentlyLoggedInUser("/help-legis");
          }
        })
        .catch(({ response }) => {
          props.setLoading(false);
          Alert.warning(
            response && response.data
              ? response.data.Error
              : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
          );
        });
    };
  
    const FormularioCartao = () => {
      return (
        <Formik
          initialValues={{
            cvc: "",
            expiry: "",
            focus: "",
            name: "",
            number: "",
            parcelas: 1,
          }}
          validationSchema={pagamentoSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, values, setFieldValue, isValid }) => (
            <Form>
              <div className="container-fluid robotR">
                <div className="row justify-content-between">
                  <div className="col-md-12">
                    <Cards
                      cvc={values.cvc}
                      expiry={values.expiry}
                      focused={values.focus}
                      name={values.name}
                      number={values.number}
                      placeholders={{
                        expiry: "Validade",
                        name: "Nome no Cartão",
                      }}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-12 pt-3">
                          <Field
                            type="text"
                            name="number"
                            value={values.number}
                            placeholder="Número do cartão"
                            className={`form-control ${
                              errors.number && touched.number ? "invalid" : ""
                            }`}
                            onChange={(e) =>
                              setFieldValue("number", cc_format(e.target.value))
                            }
                            onFocus={() => setFieldValue("focus", "number")}
                          />
                          {errors.number && touched.number ? (
                            <span className="error-text">{errors.number}</span>
                          ) : null}
                        </div>
                        <div className="col-12 pt-3">
                          <Field
                            type="text"
                            name="name"
                            placeholder="Nome como no cartão"
                            className={`form-control ${
                              errors.name && touched.name ? "invalid" : ""
                            }`}
                            onFocus={() => setFieldValue("focus", "name")}
                          />
                          {errors.name && touched.name ? (
                            <span className="error-text">{errors.name}</span>
                          ) : null}
                        </div>
                        <div className="col-8 pt-3">
                          <Field
                            type="text"
                            name="expiry"
                            placeholder="Data de validade"
                            className={`form-control ${
                              errors.expiry && touched.expiry ? "invalid" : ""
                            }`}
                            onFocus={() => setFieldValue("focus", "expiry")}
                            onChange={(e) => {
                              if (
                                e.target.value.length &&
                                e.target.value[e.target.value.length - 1] !==
                                  "/" &&
                                isNaN(
                                  parseInt(
                                    e.target.value[e.target.value.length - 1],
                                    10
                                  )
                                )
                              ) {
                                return;
                              }
                              if (e.target.value.length <= 7) {
                                setFieldValue(
                                  "expiry",
                                  e.target.value.length === 2 &&
                                    values.expiry.length === 1
                                    ? e.target.value + "/"
                                    : e.target.value
                                );
                              }
                            }}
                          />
                          {errors.expiry && touched.expiry ? (
                            <span className="error-text">{errors.expiry}</span>
                          ) : null}
                        </div>
                        <div className="col-4 pt-3">
                          <Field
                            type="text"
                            name="cvc"
                            placeholder="cvc"
                            className={`form-control ${
                              errors.cvc && touched.cvc ? "invalid" : ""
                            }`}
                            onFocus={() => setFieldValue("focus", "cvc")}
                          />
                          {errors.cvc && touched.cvc ? (
                            <span className="error-text">{errors.cvc}</span>
                          ) : null}
                        </div>
                        <div className="col-12 pt-3">
                          <Field
                            name="parcelas"
                            as="select"
                            className="form-control"
                          >
                            {plano.parcela >= 1 && (
                              <option value="1">
                                1x de{" "}
                                {(newValue / 1).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 2 && (
                              <option value="2">
                                2x de{" "}
                                {(newValue / 2).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 3 && (
                              <option value="3">
                                3x de{" "}
                                {(newValue / 3).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 4 && (
                              <option value="4">
                                4x de{" "}
                                {(newValue / 4).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 5 && (
                              <option value="5">
                                5x de{" "}
                                {(newValue / 5).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 6 && (
                              <option value="6">
                                6x de{" "}
                                {(newValue / 6).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 7 && (
                              <option value="7">
                                7x de{" "}
                                {(newValue / 7).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 8 && (
                              <option value="8">
                                8x de{" "}
                                {(newValue / 8).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 9 && (
                              <option value="9">
                                9x de{" "}
                                {(newValue / 9).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 10 && (
                              <option value="10">
                                10x de{" "}
                                {(newValue / 10).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 11 && (
                              <option value="11">
                                11x de{" "}
                                {(newValue / 11).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                sem juros
                              </option>
                            )}
                            {plano.parcela >= 12 && (
                              <option value="12">
                                12x de{" "}
                                {(newValue / 12).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )}{" "}
                                sem juros
                              </option>
                            )}
                          </Field>
                        </div>
                        <div className="col-4 pt-3">
                          <button
                            type="submit"
                            className="btn buttonPagar"
                            onClick={() => {
                              if (!isValid) {
                                Alert.warning(
                                  "Os campos não estão preenchidos corretamente!"
                                );
                              }
                            }}
                          >
                            Realizar Pagamento
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    };
  
    const PagamentoBoleto = () => {
      return (
        <button
          className="btn buttonPagar robotM"
          onClick={() => handleSubmit({})}
        >
          Gerar Boleto
        </button>
      );
    };


    useEffect(() => {
        setLoading(true);
        getPlanoById(id).then((data) => {
          setPlano(data);
          setNewValue(data.valor);
        });
        
        if(cupom){
            verificaCupomExistente(cupom, plano, "ok");
        }
        setLoading(false);
        // eslint-disable-next-line
      }, [cupom]);
    return (
      <>
        <div className="bg-light">
            <div className="container robotR pt-5">
            <GoBack link={"/help-legis"} />
                <div className="row mb-2">
                    <div className="col-md-6 bg-branco p-5">
                      {currentUser ? null : null}
                      {authenticated ? null : null}
                        <h4>Como vc prefere pagar?</h4>
                        <div className="accordion" id="accordionExample">
                            <div id="cardPagamento" className="card">
                                <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" onClick={() => trocarBoleto("cartao", false)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Cartão de crédito
                                    </button>
                                </h2>
                                </div>

                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                    <FormularioCartao />
                                </div>
                                </div>
                            </div>
                            <div id="cardPagamento" className="card">
                                <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" onClick={() => trocarBoleto("boleto", true)} type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Boleto
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                    <PagamentoBoleto />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="cardPagamento" className="col-md-6">
                        <div className="m-2">
                            <h4>Detalhe da sua compra</h4>
                            <hr />
                            <div className="float-left">
                                Plano {plano.titulo}
                            </div>
                            <div className="float-right">
                                {(plano.valor / 1).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </div>
                        </div>
                        <div>
                            <div className="input-group">
                                <input type="text" className="form-control" disabled={buttonDesativado ? true : false} onChange={(e) => setCupom(e.target.value)} placeholder="Cupom de desconto"/>
                                <div className="input-group-append">
                                    <button className="btn btn-primary mx-1" onClick={() => verificaCupomExistente(cupom,plano, "ok")} type="button">Aplicar</button>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-secondary mx-1" onClick={() => verificaCupomExistente(cupom,plano,"cancelar")} type="button">Cancelar</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row justify-content-around">
                            <div className="col-md-9">
                                <p>Forma de pagamento</p>
                            </div>
                            <div className="col-md-3">
                                <p>{formaPagamento === "boleto" ? "Boleto" : "Cartão de crédito"}</p>
                            </div>
                        </div>
                        <div className="row justify-content-around">
                            <div className="col-md-9">
                                <p>Subtotal</p>
                            </div>
                            <div className="col-md-3">
                                <p>{(plano.valor / 1).toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })}</p>
                            </div>
                        </div>
                        <div className="row">
                        {formaPagamento === "boleto" ? (
                        <>
                            <div className="col-md-9">
                                <p>Desconto boleto</p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                {(plano.valor - (plano.valor - plano.valorDesconto)).toLocaleString(
                                    "pt-br",
                                    {
                                    style: "currency",
                                    currency: "BRL",
                                    }
                                )} 
                                </p>
                            </div>
                        </>
                        ):
                        <>
                          
                        </>
                        }

                        {isValidCupom.length > 0 && buttonDesativado ? (
                                <>
                                    <div className="col-md-9">
                                        <p>Desconto cupom</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p>
                                        {(plano.valor - (plano.valor - ((plano.valor * isValidCupom[0].porcentagem)/100))).toLocaleString(
                                            "pt-br",
                                            {
                                            style: "currency",
                                            currency: "BRL",
                                            }
                                        )} 
                                        </p>
                                    </div>
                                </>
                        ): null}
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-9">
                                <p>A pagar</p>
                            </div>
                            <div className="col-md-3">
                                {formaPagamento === "boleto" ? (
                                        <>
                                        {isValidCupom.length > 0 && buttonDesativado ? 
                                          <>
                                            <div className="col-md-3">
                                                    <p>
                                                    {(plano.valor - (plano.valor - (plano.valor - ((plano.valor * isValidCupom[0].porcentagem)/100))) - plano.valorDesconto).toLocaleString(
                                                        "pt-br",
                                                        {
                                                        style: "currency",
                                                        currency: "BRL",
                                                        }
                                                    )} 
                                                    </p>
                                            </div>
                                          </> :
                                            <p>{(plano.valor - plano.valorDesconto).toLocaleString(
                                                "pt-br",
                                                {
                                                style: "currency",
                                                currency: "BRL",
                                                }
                                            )}</p>
                                              }
                                        </>
                                    ):
                                    <>
                                        {isValidCupom.length > 0 && buttonDesativado ? (
                                            <>
                                                <div className="col-md-3">
                                                    <p>
                                                    {(plano.valor - (plano.valor - (plano.valor - ((plano.valor * isValidCupom[0].porcentagem)/100)))).toLocaleString(
                                                        "pt-br",
                                                        {
                                                        style: "currency",
                                                        currency: "BRL",
                                                        }
                                                    )} 
                                                    </p>
                                                </div>
                                            </>
                                        ): 
                                          <>
                                            <div className="col-md-3">
                                                <p>
                                                {(plano.valor).toLocaleString(
                                                    "pt-br",
                                                    {
                                                    style: "currency",
                                                    currency: "BRL",
                                                    }
                                                )} 
                                                </p>
                                            </div>
                                          </>
                                        }
                                    </>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooterLogado isFixed={false}></AppFooterLogado>
        </div>        
      </>
    );
  };
  
  export default Pagamento;
  