import React, { useEffect, useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import GoBack from "../../componentes/goBack";
import Alert from "react-s-alert";
import { saveAs } from "file-saver";
import RichTextEditor from "react-rte";
import ImageUploader from "react-images-upload";
import {
  upload,
  download,
  saveConsultoria,
  getCnsultoria,
  enviarMenssagem,
} from "../../util/Api";
import moment from "moment";
import folder from "../../assets/imagens/icones/folder.png";
import setaBaixo from "../../assets/imagens/icones/setaBaixo.png";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado";

const Consultoria = (props) => {
  const { currentUser, setLoading, loadCurrentlyLoggedInUser } = props;
  const [textoHtml, setTextoHtml] = useState(RichTextEditor.createEmptyValue());
  const [titulo, setTitulo] = useState("");
  const [anexos, setAnexos] = useState([]);
  const [consultoria, setConsultoria] = useState(null);
  const { id } = props;

  const uploadFotoMethod = (pictureFiles) => {
    const formData = new FormData();
    formData.append("arquivo", pictureFiles[pictureFiles.length - 1]);
    setLoading(true);
    upload(formData)
      .then((data) => {
        setLoading(false);
        setAnexos([
          ...anexos,
          { nome: pictureFiles[pictureFiles.length - 1].name, key: data },
        ]);
        pictureFiles = [];
      })
      .catch(() => setLoading(false));
  };

  const downLoadAnexo = (anexo) => {
    setLoading(true);
    download(anexo).then((arquivo) => {
      saveAs(arquivo, anexo.nome);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (currentUser && currentUser.consultorias === 0 && !id) {
      Alert.warning(
        "Você não tem mais consultorias disponíveis, contrate um novo plano!",
        { timeout: 10000 }
      );
      props.history.push("/contratar");
    } else {
      if (id) {
        setLoading(true);
        getCnsultoria(id).then((data) => {
          setLoading(false);
          setConsultoria(data);
        });
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const salvarResposta = () => {
    if (!id && (!titulo || titulo === "")) {
      Alert.warning("Preencha o título!");
      return;
    }

    if (!textoHtml || textoHtml.toString("html") === "<p><br></p>") {
      Alert.warning("Preencha o texto!");
      return;
    }

    setLoading(true);

    enviarMenssagem({ texto: textoHtml.toString("html"), anexos: anexos }, id)
        .then(() => {
          setLoading(true);
          loadCurrentlyLoggedInUser("/help-legis");
          Alert.info("Resposta enviada!");
          // props.history.push("/help-legis");
        })
        .catch(() => {
          setLoading(false);
          Alert.error(
            "Erro durante o envio da resposta, por favor, tente novamente em alguns instantes."
          );
    });
  };

  const salvar = () => {
    if (!id && (!titulo || titulo === "")) {
      Alert.warning("Preencha o título!");
      return;
    }

    if (!textoHtml || textoHtml.toString("html") === "<p><br></p>") {
      Alert.warning("Preencha o texto!");
      return;
    }

    setLoading(true);
    saveConsultoria({
      titulo: titulo,
      menssagem: {
        texto: textoHtml.toString("html"),
        anexos: anexos,
      },
    })
      .then((data) => {
        setLoading(false);
        loadCurrentlyLoggedInUser("/help-legis");
        Alert.info("Consultoria enviada, aguarde o retorno!");
        // props.history.push("/consultoria/" + data);
      })
      .catch(() => {
        setLoading(false);
        Alert.error(
          "Erro durante o envio da solicitação, por favor, tente novamente em alguns instantes."
        );
      });
  };

  return (
    <div className="container robotR">
      {!consultoria ? (
        <>
        <div className="mt-5">
          <GoBack link={"/help-legis"} />
        </div>
        <div className="row mt-2">
          <div className="col-md-9">
            <h4>Solicitar consultoria <span className="fa fa-comments"></span></h4>
          </div>
          <div className="col-md-3">
            <a href="#/help-legis" className="btn btn btn-voltar-novo">Voltar</a>
          </div>
        </div>  
          <div className="row box-shadow pt-1 pb-3 mt-3">
          <div className="col-12 mt-2">
            <h5>Título:</h5>
            <input
              className="form-control mb-2"
              placeholder="digite o título da consultoria"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <hr />
          </div>
          <div className="col-12">
            <h5>Texto:</h5>
            <RichTextEditor placeholder="digite sua consultoria" value={textoHtml} onChange={setTextoHtml} />
          </div>
          {/* <div className="col-12">
            <p>
              <span className="text-danger font-weight-bold">*</span> Campos
              obrigatórios
            </p>
          </div> */}
          <div className="col-12">
            <hr />
            <h5>
              Envio de arquivo: <span className="fa fa-cloud-upload"></span>
            </h5>
          </div>
          <div className="col-6">  
            <ImageUploader
              className="box-shadow"
              withIcon={true}
              onChange={(pictureFiles) => uploadFotoMethod(pictureFiles)}
              imgExtension={[
                ".jpg",
                ".png",
                ".jpeg",
                ".pdf",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
              ]}
              accept="accept=*/*"
              maxFileSize={5242880}
              singleImage={true}
              buttonText="Enviar arquivo"
              label="Tamanho máximo: 5mb, formatos: jpg, png, jpeg, pdf, doc, docx, xls, xlsx."
              fileTypeError=" não é suportado"
              fileSizeError="Imagem muito grande, máximo 5MB"
            />
          </div>
          {anexos.length ? (
             <div className="col-md-12 acordStyle">
             <hr />
             <h5>Anexos:</h5>
           </div>
          ) : null}
          {anexos.map((anexo) => {
            return (
              <div className="col-2">
                <div className="text-center">
                  <p className="my-2 text-muted">{anexo.nome}</p>
                  <button
                    type="button"
                    onClick={() => {
                      downLoadAnexo(anexo);
                    }}
                    className="btn btn-info btn-sm my-2"
                  >
                    <i className="fa fa-download" />
                  </button>
                </div>
                <hr />
              </div>
            );
          })}
          <div className="col-md-12 text-right">
            <button
              type="button"
              className="btn buttonEnviar btn-lg"
              onClick={salvar}
            >
              <span className="fa fa-check"></span> Enviar
            </button>
          </div>
        </div>
        <div><AppFooterLogado isFixed={false}></AppFooterLogado></div>
      </>
      ) : (
        <>
          <div className="row mt-2">
            <Accordion
              defaultActiveKey={`${consultoria.menssagems.length - 1}`}
              className="cardAcordion col-12 py-2"
            >
              {consultoria.menssagems.map((m, index) => {
                return (
                  <div key={m.id}>
                    <Card.Header className="acordStyle">
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={`${index}`}
                        className="acordSelect"
                      >
                        <div className="float-left">
                          <img src={folder ? folder : null} className="mx-1" alt="botão abrir conversa"/>
                          {/* <i className="fa fa-folder-open-o" /> */}
                          {"   "}
                          <span className="spanData">{moment(m.data).format("DD/MM/YYYY")}</span> - 
                          <span className="spanData mx-1">{m.remetente.nome}</span>
                        </div>
                        <div className="float-right pl-5">
                          <img src={setaBaixo ? setaBaixo : null} className="mx-1" alt="botão seta baixo"/>
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse className="acordSelect" eventKey={`${index}`}>
                      <Card.Body className="acordStyle">
                        <div><h5>Mensagem:</h5></div>
                        <div dangerouslySetInnerHTML={{ __html: m.texto }} />
                        {m.anexos.length ? (
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-12">
                                <hr />
                                <h5>Anexos:</h5>
                              </div>
                              {m.anexos.map((anexo) => {
                                return (
                                  <div className="col-3 text-center p-2 m-1">
                                    <p>{anexo.nome}</p>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        downLoadAnexo(anexo);
                                      }}
                                      className="btn btn-info btn-sm"
                                    >
                                      <i className="fa fa-download" />
                                    </button>
                                    <hr />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                );
              })}
            </Accordion>
          </div>

          {consultoria.status ? (
            <div className="row px-2 py-2 mt-2 box-shadow">
              <div className="col-12">
                <h4 className="acordStyle">Enviar resposta <span className="fa fa-comments"></span></h4>
                <hr />
              </div>
              <div className="col-12 acordStyle">
                <h5>Texto:</h5>
                 {/* <span className="text-danger font-weight-bold">*</span> */}
                <RichTextEditor placeholder="digite sua consultoria" value={textoHtml} onChange={setTextoHtml} />
              </div>
              {/* <div className="col-12 acordStyle">
                <span className="text-danger font-weight-bold">*</span> Campos
                obrigatórios
              </div> */}
              <div className="col-12 my-1 acordStyle">
                <hr />
                <h5>
                  Envio de arquivos:{" "}
                  <span className="fa fa-cloud-upload"></span>
                </h5>
              </div>  
              <div className="col-md-6">
                <ImageUploader
                  className="box-shadow"
                  withIcon={true}
                  onChange={(pictureFiles) => uploadFotoMethod(pictureFiles)}
                  imgExtension={[
                    ".jpg",
                    ".png",
                    ".jpeg",
                    ".pdf",
                    ".doc",
                    ".docx",
                    ".xls",
                    ".xlsx",
                  ]}
                  accept="accept=*/*"
                  maxFileSize={5242880}
                  singleImage={true}
                  buttonText="Enviar arquivo"
                  label="Tamanho máximo: 5mb, formatos: jpg, png, jpeg, pdf, doc, docx, xls, xlsx."
                  fileTypeError=" não é suportado"
                  fileSizeError="Imagem muito grande, máximo 5MB"
                />
              </div>

              {anexos.length ? (
                <div className="col-md-12 acordStyle">
                  <hr />
                  <h5>Anexos:</h5>
                </div>
              ) : null}
              {anexos.map((anexo) => {
                return (
                  <div className="col-2">
                      <p>
                        {anexo.nome}
                        <button
                          type="button"
                          onClick={() => {
                            downLoadAnexo(anexo);
                          }}
                          className="btn btn-info btn-sm mx-1"
                        >
                          <i className="fa fa-download" />
                        </button>
                      </p>
                    <hr />
                  </div>
                );
              })}
              <div className="col-md-12 text-center">
                <button
                  type="button"
                  className="btn buttonEnviar"
                  onClick={salvarResposta}
                >
                  <span className="fa fa-check"></span> Enviar
                </button>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Consultoria;
