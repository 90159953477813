import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import GoBack from "../../componentes/goBack";
import Listagem from "../../componentes/Listagem";
import FormGroup from "../../componentes/form-group";
import { salvarCupom, getCupons, getCuponsEnviados, filtroCupom } from "../../util/Api";
import "./index.css";

const esquema = Yup.object().shape({
    titulo: Yup.string().min(3, "Obrigatório").required("Obrigatório"),
    descricao: Yup.string()
      .min(5, "Poucos caracteres")
      .max(255, "Muitos caracteres!")
      .required("Obrigatório"),
    porcentagem: Yup.number().required("Obrigatório").min(1, "Obrigatório"),
  });

const Cupons = (props) => {
    const [cupons, setCupons] = useState({ content: [] });
    const formikRef = useRef();
    const [classBtn, setClassBtn] = useState("btn-success-novo");
    const [tabNow, setTabNow] = useState("pacotes-tab");
    const [cuponsEnviados, setCupsonsEnviados] = useState({ content: [] });
    const [filtro, setFiltro] = useState({});

    const findCupons = () => {
        props.setLoading(true);
        getCupons().then((data) => {
          setCupons({ content: data });
          props.setLoading(false);
        });
    };

    const filtrarCupons = () => {
        filtroCupom(filtro).then((data) => {
          data.changePage = pageChange;
          setCupons(data);
          props.setLoading(false);
        });
      }

    const findCuponsEnviados = () => {
        props.setLoading(true);
        getCuponsEnviados().then((data) => {
          setCupsonsEnviados({ content: data });
          props.setLoading(false);
        });
    };

    // const excluir = (plano) => {
    //     excluirPlano(plano.id)
    //       .then(() => {
    //         Alert.warning("Pacote excluído com sucesso!");
    //         findPlanos();
    //       })
    //       .catch((error) => {
    //         Alert.error((error && error.message) || "deu ruim!");
    //       });
    // };

    const pageChange = (pagina) => {
        const novoFiltro = { ...filtro, pagina: pagina };
        setFiltro(novoFiltro);
        props.setLoading(true);
        filtroCupom(novoFiltro).then((data) => {
          data.changePage = pageChange;
          setCupons(data);
          props.setLoading(false);
        });
      };

      useEffect(() => {
        findCupons();
        findCuponsEnviados();
        filtrarCupons();
        // eslint-disable-next-line
      }, []);

      const salvar = (formulario) => {
        salvarCupom(formulario)
          .then(() => {
            if (classBtn === "btn-success-novo") {
              Alert.info("Cupom salvo com sucesso!");
            } else {
              Alert.info("Cupom alterado com sucesso!");
            }
            findCupons();
            findCuponsEnviados();
            formulario.titulo = "";
            formulario.descricao = "";
            formulario.email = "";
            formulario.destinatario = "";
            formulario.porcentagem = 0;
            formulario.id = null;
            setClassBtn("btn-success-novo");
          })
          .catch((error) => {
            Alert.error((error && error.message) || "deu ruim!");
          });
    };

return (
    <div className="bg-light">
    <div className="container robotR pt-5">
        <GoBack link={"/cadastros"} />
        <div className="row mb-2">
            <h4 className="col-md-10">Cadastros de Cupons de Desconto</h4>
            {tabNow ? null : null}
            <div className="col-md-1">
                <a href="#/cadastros" className="btn btn-layout float-right">Voltar</a>
            </div>
        </div>
        <section className="pt-3">
            <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
            <li className="nav-item col-md-6">
                <a className="nav-link active color" id="pacotes-tab" data-toggle="tab" href="#pacotesTab" role="tab" aria-controls="pacotesTab" aria-selected="true" onClick={() => setTabNow("pacotes-tab")}>Cupons</a>
            </li>
            <li className="nav-item col-md-6">
                <a className="nav-link color" id="pja-tab" data-toggle="tab" href="#pja" role="tab" aria-controls="pja" aria-selected="false" onClick={() => setTabNow("pja")}>Enviados</a>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="pacotesTab" role="tabpanel" aria-labelledby="pacotes-tab">
                <div className="bg-branco">
                    <Formik
                        className="row"
                        innerRef={formikRef}
                        initialValues={{
                        titulo: "",
                        descricao: "",
                        porcentagem: 0,
                        email: "",
                        destinatario: "",
                        id: null,
                        }}
                        validationSchema={esquema}
                        onSubmit={(values) => {
                        salvar(values);
                        }}
                    >
                        {({ errors, touched, isValid }) => (
                        <Form>
                            <div className="col-lg-12">
                            <div className="bs-component">
                                <div className="row">
                                <div className="col-md-6">
                                    <FormGroup
                                    label="Título"
                                    htmlFor="inputTipoDeProcedimento"
                                    >
                                    <Field
                                        type="text"
                                        className={`form-control ${
                                        errors.titulo && touched.titulo ? "invalid" : ""
                                        }`}
                                        name="titulo"
                                        placeholder="Insira o código do cupom"
                                    />
                                    {errors.titulo && touched.titulo ? (
                                        <span className="text-error">{errors.titulo}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup label="Porcentagem %" htmlFor="inputPorcentagem">
                                    <Field
                                        type="number"
                                        step="1"
                                        className={`form-control ${
                                        errors.porcentagem && touched.porcentagem ? "invalid" : ""
                                        }`}
                                        name="porcentagem"
                                        placeholder="Ex: 10"
                                    />
                                    {errors.porcentagem && touched.porcentagem ? (
                                        <span className="text-error">{errors.porcentagem}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup label="Destinatário" htmlFor="inputDestinatario">
                                        <Field
                                            type="text"
                                            className={`form-control ${
                                            errors.destinatario && touched.destinatario ? "invalid" : ""
                                            }`}
                                            name="destinatario"
                                            placeholder="Insira o nome do destinatário"
                                        />
                                        {errors.destinatario && touched.destinatario ? (
                                            <span className="text-error">{errors.destinatario}</span>
                                        ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup label="E-mail do destinatário" htmlFor="inputEmail">
                                            <Field
                                                type="email"
                                                className={`form-control ${
                                                errors.email && touched.email ? "invalid" : ""
                                                }`}
                                                name="email"
                                                placeholder="Insira o e-mail do destinatário"
                                            />
                                            {errors.email && touched.email ? (
                                                <span className="text-error">{errors.email}</span>
                                            ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-12">
                                    <FormGroup label="Descrição" htmlFor="inputDescricao">
                                    <Field
                                        type="text"
                                        className={`form-control ${
                                        errors.descricao && touched.descricao ? "invalid" : ""
                                        }`}
                                        name="descricao"
                                        placeholder="Insira uma descrição"
                                    />
                                    {errors.descricao && touched.descricao ? (
                                        <span className="text-error">{errors.descricao}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                </div>

                                <button
                                type="submit"
                                onClick={() => {
                                    if (!isValid) {
                                    Alert.warning("Preencha todos os campos destacados");
                                    }
                                }}
                                className={`btn ${classBtn} mb-3 mr-3`}
                                >
                                {classBtn === "btn-success-novo" ? "Salvar" : "Editar"}
                                </button>
                                <button type="reset" className="btn btn-voltar-novo mb-3">
                                    Limpar
                                </button>
                            </div>
                            </div>
                        </Form>
                        )}
                    </Formik>
                </div>
                <div className="bg-branco mt-3">
                    <Listagem
                        titulo={<h5>Cupons cadastrados</h5>}
                        tituloPlano
                        porcentagem
                        destinatario
                        verTextoDesc
                        ativo
                        ativarComentarioBtn
                        paginacao={cupons}
                        // botaoExcluir={excluir}
                    />          
                </div>    
            </div>
            <div className="tab-pane fade" id="pja" role="tabpanel" aria-labelledby="pja-tab">
                <div className="bg-branco">
                    <Listagem
                        titulo={<h5>Cupons enviados</h5>}
                        cupom
                        data
                        enviado
                        paginacao={cuponsEnviados}
                    />   
                </div>
            </div>
            </div> 
        </section>
    </div>
    </div>
);

};

export default Cupons;