import React, { useState, useEffect  } from "react";
import RichTextEditor, {getTextAlignClassName} from "react-rte";
import Alert from "react-s-alert";
import { saveAs } from "file-saver";
import ListaRols from "../../componentes/ListaRols";
import GoBack from "../../componentes/goBack";
import { Container } from "react-bootstrap";
import { Redirect, useParams } from "react-router-dom";
import baixar from "../../assets/imagens/icones/baixar.png";
import qrCodeImg from "../../assets/imagens/icones/qrCode.png";
import scrollLateral from "../../assets/imagens/icones/scrollLateral.png";
import transcreverIcone from "../../assets/imagens/icones/transcrever.png";
import chatGPTlogo from "../../assets/imagens/icones/chatgpt.png";
import chatgptpic from "../../assets/imagens/icones/chatgptic.png";
import userpic from "../../assets/imagens/icones/userpic.png";
import chatGPT2 from "../../assets/imagens/icones/chatgpt2.png";
import ChatGPTPopup from "../../componentes/ChatGPTPopup.jsx";
import TextoLista from "./index.jsx";
import { URL_NOW } from "../../constantes/index.jsx";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  getTextoClienteById,
  salvarTextoCliente,
  gerarPdfTextoCliente,
  gerarQrcode,
  filtroTexto,
  isUsoGratuito,
  tempoGratuito,
  enviarImagemEditor,
  chatGptPeca
} from "../../util/Api";
import "./index.css";
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PulseLoader from "react-spinners/PulseLoader";
// import { Editor } from '@tinymce/tinymce-react';

const NovoTexto = (props) => {
  // const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  const { setLoading, currentUser } = props;
  const notTrial = currentUser.modulosAtivos.length > 0 ? true : false;
  const { id } = useParams();
  const [textoHtml, setTextoHtml] = useState(RichTextEditor.createEmptyValue());
  const [textoCliente, setTextoCliente] = useState({});
  const [qrcode, setQrcode] = useState("");
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [tabNow, setTabNow] = useState("dash-tab");
  const [pagina, setPagina] = useState({ content: [] });
  const [filtro, setFiltro] = useState({});
  const [abaAberta, setAbaAberta] = useState("inicio");
  const [numDoc, setNumDoc] = useState(1);
  const [numBus, setNumBus] = useState(1);
  const [acaoNew, setAcaoNew] = useState(id);
  const [usoGratuito, setUsoGratuito] = useState(1);
  const [qtdTempoGratuito, setTempoGratuito] = useState(1);

  const handleImagemChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      enviarImagemEditor(formData).then(async (data) => {
        await setTextoHtml(
          RichTextEditor.createValueFromString(
            `${textoHtml.toString(
              "html"
            )} <p><img src="${'data:image/png;base64,' + data}" style="max-width: 400px; height: auto;"/></p>`,
            "html"
          )
        );
      });
    }
  };

  function abaQueAbre (aba) {
    // console.log("estado function agora: " + aba);

    if(aba === "inicio"){
      setAbaAberta("inicio");
      // console.log("ini");
    }

    if((aba === "documento" && (numDoc % 2) === 0)){
      setNumDoc(numDoc => numDoc + 1);
      setAbaAberta("documento");
      // console.log("ABRA DOCUMENTO");
      return abaAberta;
    }

    if((numDoc % 2) !== 0 && aba === "documento"){
      setAbaAberta("buscador");
      // console.log("FECHA DOCUMENTO");
      return abaAberta;
    }
    
    if(aba === "buscador" && (numBus % 2) === 0){
      setNumBus(numBus => numBus + 1);
      setAbaAberta("buscador")
      // console.log("ABRA BUSCADOR");
      return abaAberta;
    }

    if((numBus % 2) !== 0 && aba === "buscador"){
      setAbaAberta("documento");
      // console.log("FECHA BUSCADOR");
      return abaAberta;
    } 

    // console.log("num doc: " + numDoc);
    // console.log("num busc: " + numBus);

    return undefined;
  }

  useEffect(() => {
    if (id !== "novo" && id !== undefined) {
      setLoading(true);
      setTabNow("dash-tab");
      debugger
      getTextoClienteById(id).then((data) => {
        setLoading(false);
        setTextoCliente(data);
        // console.log(data)
        setTextoHtml(RichTextEditor.createValueFromString(data.texto, "html"));
      });
    } else {
      setLoading(true);
      setTabNow("dash-tab");
      window.location.replace(URL_NOW+"#/pja");
    }

    isUsoGratuito(currentUser.id).then((data) => {
      setLoading(false);
      setUsoGratuito(data);
    });

    tempoGratuito(currentUser.id).then((data) => {      
      setLoading(false);
      setTempoGratuito(data);
    });

    filtroTexto(filtro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [id]);

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    filtroTexto(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
  };

  const [gptPopup, setGptPopup] = useState(false)
  const [textoHtmlSend, setTextoHtmlSend] = useState('')
  const [textoSend, setTextoSend] = useState(false)
  const [resposta, setResposta] = useState('')
  const [usage, setUsage] = useState('')
  const [inputTokens, setInputTokens] = useState()

  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index);
  }

  const handleGPTSubmit = () => {
    if (textoHtmlSend === "") {
      console.log('Sem texto');
    } else {
      setTextoSend(true)
      chatGptPeca(textoHtmlSend).then((data) => {
        setResposta(data.content);
        setUsage(data.usage);
        setInputTokens(data.inputTokens)
      })
      .catch(error => {
        console.error('Erro ao fazer requisição para o ChatGPT:', error);
      });
    }
  }

  return (
    <div className="bg-light">
      <Container className="pt-5 mb-3">
        <GoBack link={"/modulos"} />
        {
          !notTrial ? 
            <section className="row aviso-trial">
              <div className="col-12">
                <div className="ml-4 text-trial">  
                {
                  qtdTempoGratuito > 0 ?
                    qtdTempoGratuito > 1 ?
                      <span>Seu periodo gratuito se encerra em {qtdTempoGratuito} dias.</span>
                    :
                      <span>Seu periodo gratuito se encerra em {qtdTempoGratuito} dia.</span>
                    :
                    ""
                }              
                  
                </div>
              </div>
            </section>
        : ""
        }
        <section className="row">
          <div className="col-md-8">
            {pagina ? null : null}
            <h4 className="textoPJALegis">PJA Legis | <span className="spanPjaLegis">Peticionamento Judicial Automatizado</span></h4>
          </div>
          <div className="col-md-4 mb-2">
            {textoCliente.id && tabNow === "dash-tab" && id !== "novo" ? (
                <button
                  className="btn buttonBaixarPeticao mx-2"
                  type="button"
                  onClick={() => {
                    gerarPdfTextoCliente(textoCliente.id).then((data) => {
                      saveAs(data, textoCliente.titulo + ".pdf");
                    });
                  }}
                >
                  <img src={baixar ? baixar : null} className="mx-1" alt="botão baixar"/>
                  Download PDF
                </button>
              ) : null}
              {tabNow === "dash-tab" ? (
                <>
                  <button
                    className="btn buttonSalvarPeticao"
                    type="button"
                    onClick={() =>
                      salvarTextoCliente({
                        ...textoCliente,
                        // texto: editorRef.current.getContent(),
                        texto: textoHtml.toString("html"),
                      })
                        .then((data) => {
                          // debugger
                          if (!textoCliente.id && (acaoNew !== "novo" || acaoNew !== "novoN")) {
                            setTextoCliente({ ...textoCliente, id: data.id });
                            setAcaoNew("novo");
                          }
                          window.location.replace(URL_NOW+"#/pja");
                          getTextoClienteById(id).then((data) => {
                            setLoading(false);
                            setTextoCliente(data);
                            // console.log(data)
                            setTextoHtml(RichTextEditor.createValueFromString(data.texto, "html"));
                            // editorRef.current.setContent(data.texto)
                          });
                          setTabNow("cadas-tab");
                          // setAcaoNew("novoN");
                          Alert.info("Salvo com sucesso");
                        })
                        .catch(() => {
                          Alert.warning("Ops!, houve um erro de comunicação, verifique os campos");
                            })    
                        }
                      >
                        {/* <span className="fa fa-floppy-o m-1"></span> */}
                        Salvar
                  </button>
                </>
              ): null} 
          </div>
        </section>

        <section className="pt-3">
            <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
            <li className="nav-item col-md-6">
                <a className={`nav-link ${tabNow === 'dash-tab' ? 'active' : ''} color`} id="dash-tab" data-toggle="tab" href="#dashTab" role="tab" aria-controls="dashTab" aria-selected={tabNow === 'dash-tab' ? "true" : "false"} onClick={() => setTabNow("dash-tab")}>Dashboard</a>
            </li>
            <li className="nav-item col-md-6">
                <a className={`nav-link ${tabNow === 'dash-tab' ? '' : 'active'} color`} id="cadas-tab" data-toggle="tab" href="#cadastro" role="tab" aria-controls="cadastro" aria-selected={tabNow === 'dash-tab' ? "false" : "true"} onClick={() => setTabNow("cadastro")}>Cadastros</a>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${tabNow === 'dash-tab' ? 'show active' : ''}`} id="dashTab" role="tabpanel" aria-labelledby="dash-tab">
                <div className="bg-branco p-3 m-2">
                {currentUser.modulosAtivos.includes(1) || 
                    currentUser.nivel === "ADM" || usoGratuito === 1 ? (
                      <div className="row justify-content-center">
                        <div className="col-4">
                            Título:{" "}
                            <span className="text-danger font-weight-bold">*</span>
                            <input
                              type="text"
                              required="required"
                              placeholder="Insira um título para o seu documento"
                              className="form-control mb-2"
                              value={textoCliente.titulo}
                              onChange={(e) =>
                                setTextoCliente({
                                  ...textoCliente,
                                  titulo: e.target.value,
                                })
                              }
                            />
                        </div>
                        <div className="col-4">
                            QRCode:{" "}
                            <i
                              className="fa fa-info ml-2"
                              data-tip="caso deseje inserir um QRCode no PDF, insira o link e depois clique em gerar"
                            />
                            
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  placeholder="https//..."
                                  className="form-control"
                                  value={qrcode}
                                  onChange={(e) => setQrcode(e.target.value)}
                                />
                                {/* <input aria-label="Recipient's username" aria-describedby="basic-addon2"> */}
                                <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn buttonQrCode"
                                        onClick={() => {
                                          if (qrcode) {
                                            gerarQrcode(qrcode).then(async (data) => {
                                              // debugger
                                              Alert.info("QRCode gerado com sucesso");
                                              // let actual = editorRef.current.getContent();
                                              // actual += `<p><img src="${data}"/></p>`;
                                              // editorRef.current.setContent(actual);
                                              await setTextoHtml(
                                                RichTextEditor.createValueFromString(
                                                  `${textoHtml.toString(
                                                    "html"
                                                  )} <p><img src="${data}"/></p>`,
                                                  "html"
                                                )
                                              );
                                              await setQrcode("");
                                            }).catch(() => {
                                              Alert.warning("Ops!, houve um erro de comunicação");
                                            });
                                          }
                                        }}
                                      >
                                         <img src={qrCodeImg ? qrCodeImg : null} className="mx-1" alt="botão qrCode"/>
                                        Gerar
                                    </button>
                                </div>
                              </div>  
                        </div>
                        <div className="col-4 mt-4">
                          {browserSupportsSpeechRecognition ? (
                            <>
                              {listening ? (
                                <button
                                  className="buttonGravador"
                                  onClick={async () => {
                                    await setTextoHtml(
                                      RichTextEditor.createValueFromString(
                                        `${textoHtml.toString(
                                          "html"
                                        )} <p>${transcript}</p>`,
                                        "html"
                                      )
                                    );
                                    // let actualnew = editorRef.current.getContent();
                                    // actualnew += `<p>${transcript}</p>`;
                                    // editorRef.current.setContent(actualnew);
                                    await SpeechRecognition.stopListening();
                                    await resetTranscript();
                                  }}
                                  s
                                >
                                  <span className="fa fa-stop-circle m-1"></span>Parar
                                </button>
                              ) : (
                                <button
                                  className="buttonGravador"
                                  onClick={() =>
                                    SpeechRecognition.startListening({
                                      continuous: true,
                                      language: "pt-br",
                                    })
                                  }
                                >
                                  <img src={transcreverIcone ? transcreverIcone : null} className="mx-1" alt="botão transcrever"/>
                                  Transcrever
                                </button>
                              )}
                              {/* <p>Transcrevendo: {listening ? "sim" : "não"}</p> */}
                            </>
                          ) : (
                            // </div>
                            <p>Transcrição não suportada no seu navegador</p>
                          )}
                        </div>
                        <div className={`${abaAberta === "inicio" ? 'col-8' : `${abaAberta === "documento" ? 'col-11' : 'col-1'}`} parteText`}>
                          <div className="container-busca-rol">
                            <div className="row justify-content-center my-2">
                              {abaAberta === "documento" || abaAberta === "inicio" ? (
                                <>
                                  <div className="col-md-10">
                                      <h4 className="titDocEditor">{textoCliente.titulo}</h4>
                                  </div>
                                  <div className="col-md-1">
                                    <button className="btn btnScroll" onClick={() => abaQueAbre("documento")}>
                                      <img src={scrollLateral ? scrollLateral : null} className="mx-1" alt="botão diminuir"/>
                                    </button>
                                  </div>
                                  {/* <div className="row"> */}
                                  <div className="col-md-12 mb-1">
                                    <form className="alinharL">
                                      <label className="custom-file-upload">
                                        <input type="file" accept="image/*" onChange={handleImagemChange} />
                                        <span>Selecionar imagem</span>
                                      </label>
                                    </form>
                                  </div>
                                  <div className="col-12">
                                      {!listening ? (
                                        <RichTextEditor
                                          placeholder="digite seu texto"
                                          value={textoHtml}
                                          onChange={setTextoHtml}
                                          blockStyleFn={getTextAlignClassName}
                                          // draftEditorProps={{
                                          //   handlePastedFiles: (files) => {
                                          //       console.log("files")
                                          //       return "handled"
                                          //   },
                                          //   handlePastedText: (text, html, editorState) => {
                                          //       console.log("text")
                                          //       return "handled"
                                          //   }
                                          // }}
                                        />
                                      //   <Editor
                                      //   onInit={(evt, editor) => editorRef.current = editor}
                                      //   initialValue=""
                                      //   init={{
                                      //     height: 500,
                                      //     menubar: false,
                                      //     plugins: [
                                      //       'advlist autolink lists link image charmap print preview anchor',
                                      //       'searchreplace visualblocks code fullscreen',
                                      //       'insertdatetime media table paste code help wordcount'
                                      //     ],
                                      //     toolbar: 'undo redo | formatselect | ' +
                                      //     'bold italic backcolor | alignleft aligncenter ' +
                                      //     'alignright alignjustify | bullist numlist outdent indent | ' +
                                      //     'removeformat | help',
                                      //     content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                      //   }}
                                      // />
                                      ) : (
                                        <p>{transcript}</p>
                                      )}
                                    </div>
                                  {/* </div> */}
                                </>
                              ) : (
                                <>
                                  <div className="col-md-12 deck">
                                    <button className="btn btnScroll" onClick={() => abaQueAbre("inicio")}>
                                      <img src={scrollLateral ? scrollLateral : null} className="mx-1 iconeFechadoEdit" alt="botão diminuir"/>
                                    </button>
                                  </div>
                                  <div className="col-md-12 deck">
                                    <h4 className="titFechadoEdit">{textoCliente.titulo}</h4>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={`${abaAberta === "inicio" ? 'col-4' : `${abaAberta === "buscador" ? 'col-11' : 'col-1'}`} parteText`}>
                          {abaAberta === "buscador" || abaAberta === "inicio" ? (
                            <>
                              <div className="container-tabs">
                                <div className="bloc-tabs">
                                  <div>
                                      <button className="btn btnScroll" onClick={() => abaQueAbre("buscador")}>
                                        <img src={scrollLateral ? scrollLateral : null} className="mx-1" alt="botão diminuir"/>
                                      </button>
                                  </div>
                                  <div 
                                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                                  onClick={() => {toggleTab(1); setGptPopup(false)}}
                                  >Buscador</div>
                                  <div 
                                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                                  onClick={() => {toggleTab(2); setGptPopup(true)}}
                                  >ChatGPT</div>
                                </div>

                                <div className="content-tabs">
                                  <div className={toggleState === 1 ? "content active-content" : "content"}>
                                    <div className="container-busca-rol">
                                      <ListaRols setLoading={setLoading} />
                                    </div>
                                  </div>
                                  <div className={toggleState === 2 ? "content active-content" : "content"}>
                                    <div className="container-busca-rol">
                                      <ChatGPTPopup trigger={gptPopup}>
                                        <button type="button" className="btnGptPopup" style={textoSend ? {display: "block"} : {display: "none"}} onClick={() => {setTextoHtmlSend(''); setResposta(''); setUsage(''); setTextoSend(false)}}>✖</button>
                                        <div className="chatgptpopup">
                                          <img src={chatGPT2} alt="ChatGPT" style={{width: "50px"}} /><br />
                                        </div>
                                        {textoSend === true ? (
                                          <>
                                            <div className="chatgptpergunta">
                                              <span className="gptuser"><img src={userpic} alt="" style={{width: "23px", marginRight: "8px"}}/>Você</span>
                                              {inputTokens > 4096 ? (
                                                <span><p className="gptanswer">{textoHtmlSend.split(' ').slice(0, 3000).join(' ')}</p><p>(Apenas as 3000 primeiras palavras foram enviadas em questão das limitações do ChatGPT.)</p></span>
                                              ) : (
                                                <p className="gptanswer">{textoHtmlSend}</p>
                                              )}
                                            </div>
                                            <div className="chatgptpergunta">
                                              <span className="gptuser"><img src={chatgptpic} alt="" style={{width: "23px", marginRight: "8px"}}/>ChatGPT</span>
                                              {resposta ? (
                                                <p className="gptanswer">{resposta}</p>
                                              ) : (
                                                <PulseLoader color="white" size="8" />
                                              )}
                                            </div>
                                            <div className="chatgptpopup">
                                            </div>
                                            <br />
                                            {usage ? (
                                              <>
                                                <p style={{display: "flex", justifyContent: "center", fontSize: "18px"}}>Créditos utilizados: {usage}</p>
                                                <br />
                                                <button
                                                  className="buttonChatGPT"
                                                  onClick={() => {
                                                    setTextoHtmlSend(''); 
                                                    setResposta('');
                                                    setUsage('');
                                                    setTextoSend(false)
                                                  }}
                                                >
                                                <img src={chatGPTlogo ? chatGPTlogo : null} className="mx-1" alt="botão chatgpt"/>
                                                  Gerar nova requisição
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <PulseLoader color="white" size="8" />
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <br />
                                            <form onSubmit={handleGPTSubmit}>
                                              <input
                                                type="text" 
                                                placeholder="Mensagem ChatGPT..."
                                                className="gptinput"
                                                name="textoHtmlSend"
                                                value={textoHtmlSend} 
                                                onChange={(e) => setTextoHtmlSend(e.target.value)} 
                                              />
                                              <button
                                                className="buttonChatGPT"
                                                style={{marginTop: "85px"}}
                                                type="submit"
                                              >
                                              <img src={chatGPTlogo ? chatGPTlogo : null} className="mx-1" alt="botão chatgpt"/>
                                                Gerar requisição
                                              </button>
                                            </form>
                                          </>
                                        )}
                                      </ChatGPTPopup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-1">
                                <button className="btn btnScroll" onClick={() => abaQueAbre("inicio")}>
                                  <img src={scrollLateral ? scrollLateral : null} className="mx-1 iconeFechado" alt="botão diminuir"/>
                                </button>
                                <h4 className="titFechado">Buscador</h4>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Redirect to={"/contratarModulo/1"} />
                    )}
                </div>
                {/* <div className="bg-branco mt-3">
                    list     
                </div>     */}
            </div>
            <div className={`tab-pane fade ${tabNow === 'dash-tab' ? '' : 'show active'} `} id="cadastro" role="tabpanel" aria-labelledby="cadas-tab">
              <div className="bg-branco mb-4">
                <TextoLista 
                  setLoading={setLoading}
                  currentUser={currentUser}
                />
              </div>
            </div>
            </div>
        </section>
      </Container>  
    </div>
  );
};

export default NovoTexto;
