import React, { useState, useEffect } from "react";
import Listagem from "../../componentes/Listagem";
import Alert from "react-s-alert";
import { filtroTexto, deletaTextoCliente } from "../../util/Api";

const Texto = (props) => {
  const { currentUser, setLoading, isNew } = props;
  const [pagina, setPagina] = useState({ content: [] });
  const [filtro, setFiltro] = useState({});


  const excluir = (texto) => {
    deletaTextoCliente(texto.id)
      .then(() => {
        Alert.info("Documento excluído com sucesso!");
        setLoading(true);
        filtroTexto(filtro).then((data) => {
          data.changePage = pageChange;
          setPagina(data);
          setLoading(false);
        });
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  useEffect(() => {
    setLoading(true);
    filtroTexto(filtro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [isNew]);

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    filtroTexto(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Listagem
            titulo={<h4>Documentos cadastrados</h4>}
            novoLancamento={"/texto/novo"}
            linkNovoLancamento={"/texto/novo"}
            goToEdit={"/texto/"}
            labelLink={"Novo texto"}
            data
            tituloPagina
            // acao
            notVisualize
            paginacao={pagina}
            currentUser={currentUser}
            botaoExcluir={excluir}
            isNew={isNew}
          />
        </div>
      </div>
    </div>
  );
};

export default Texto;
