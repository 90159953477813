import React, { useEffect } from "react";
import "./index.css";
import Card from "../../componentes/card";
import pasta from "../../assets/imagens/icones/cadastros.png";
import relatorios from "../../assets/imagens/icones/relatorios.png";
import pagamentos from "../../assets/imagens/icones/pagamentos.png";
import consultorias from "../../assets/imagens/icones/consultorias.png";
import pja from "../../assets/imagens/icones/pja.png";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import { Link } from "react-router-dom";



const modulos = [
  {
    uri: "/cadastros",
    icon: pasta,
    titulo: "Cadastros",
    permissao: ["ADM"],
  },
  // {
  //   uri: "/dashboard",
  //   icon: "fa-cogs",
  //   titulo: "Dashboard",
  //   permissao: ["ADM"],
  // },
  {
    uri: "/dashboard",
    icon: relatorios,
    titulo: "Relatórios",
    permissao: ["ADM"],
  },
  {
    uri: "/pagamentos",
    icon: pagamentos,
    titulo: "Pagamentos",
    permissao: ["CLIENTE", "ADM"],
  },
  {
    uri: "/help-legis",
    icon: consultorias,
    titulo: "Help Legis Consultorias",
    permissao: ["CLIENTE", "ADM"],
  },
  {
    uri: "/pja",
    icon: pja,
    titulo: "Peticionamento Judicial Automatizado",
    permissao: ["CLIENTE", "ADM"],
  },
  // {
  //   uri: "/comentarios",
  //   icon: "fa-comments",
  //   titulo: "Comentários",
  //   permissao: ["ADM"],
  // },
  // {
  //   uri: "/contratar",
  //   icon: "fa-plus",
  //   titulo: "Contratar Plano",
  //   permissao: ["CLIENTE"],
  // },
  // {
  //   uri: "/contratarModulo",
  //   icon: "fa-plus",
  //   titulo: "Contratar Modulo",
  //   permissao: ["CLIENTE"],
  // },
  // {
  //   uri: "/cadastroOtimize",
  //   icon: "fa-paper-plane",
  //   titulo: "Otimize!",
  //   permissao: ["ADM"],
  // },
  // {
  //   uri: "/moduloPago",
  //   icon: "fa-file-text",
  //   titulo: "Módulos pagos",
  //   permissao: ["ADM"],
  // },
  // {
  //   uri: "/site/blog",
  //   icon: "fa-plus",
  //   titulo: "Blog",
  //   permissao: ["ADM"],
  // },
];



const checaPermissao = (modulo, usuario) => {
  if (!modulo.permissao) {
    return true;
  } else {
    return modulo.permissao.find((m) => m === usuario.nivel);
  }
};

const Modulos = (props) => {
  const { currentUser, loadCurrentlyLoggedInUser } = props;
  const notTrial = currentUser.modulosAtivos.length > 0 ? true : false;
  
  useEffect(() => {
    // setLoading(true);
    loadCurrentlyLoggedInUser();
    // eslint-disable-next-line
  }, []);

  return currentUser && currentUser.nivel === 'ADM' ? (
    <div id="modulo" className="container mt-5 robotR">
      <div className="row mt-5">
        {modulos.map((modulo) => {
          return checaPermissao(modulo, currentUser) ? (
            <Card key={modulo.titulo} {...modulo} />
          ) : null;
        })}
      </div>
      <AppFooterLogado/>
    </div>
  ) : 
    <div id="modulo" className="container mt-5 pt-5 robotR">
      <div className="row mt-5 justify-content-between bg-light">
        <div className="col-md-6 pja p-4">
            <h4 className="pjaTitulo">PJA - Peticionamento <br/>Judicial Automatizado</h4>
            <p className="paragrafoPja mb-5">
            A escrita de peças judiciais tem sido <br/> cansativa e monótona? O esforço em <br/> pesquisar fundamentação legal, <br/>
            doutrinária e jurisprudencial torna <br/> a sua rotina mais desafiadora?
            </p>
            <Link className="pjaButton mt-5" to={notTrial ? "/pja" : "/opcao"}>Acessar</Link>
        </div>
        <div className="col-md-6 help p-4">
            <h4 className="pjaTitulo">Help Legis <br/> Consultorias</h4>
            <p className="paragrafoPja mb-5">
              A iLegis oferece uma ferramenta de <br/> consultoria e mentoria para advogados, 
              que <br/> proporciona apoio de especialistas na rotina <br/> e carreira
              profissional de maneira rápida, <br/> prática e totalmente on-line.
            </p>
            <Link className="helpButton mt-5" to="/help-legis">Acessar</Link>
        </div>
      </div>
      <AppFooterLogado/>
    </div>
  };

export default Modulos;
