import axios from "axios";
import * as jwtDecode from "jwt-decode";
import { API_BASE_URL, ACCESS_TOKEN } from "../constantes";

export const getToken = () => localStorage.getItem(ACCESS_TOKEN);

const getTokenDecoded = () => {
  let tokenDecoded;

  try {
    tokenDecoded = jwtDecode(getToken());
  } catch (err) {
    localStorage.removeItem(ACCESS_TOKEN);
    return "";
  }

  return tokenDecoded;
};

export const isAuthenticated = () => {
  if (getToken() !== null) {
    const tokenDecoded = getTokenDecoded();

    if (tokenDecoded instanceof Object) {
      const now = new Date().getTime();
      return tokenDecoded.exp > now / 1000;
    }
  }

  return false;
};

const api = () => {
  const api1 = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "content-type": "application/json",
    },
  });

  // Add a request interceptor
  api1.interceptors.request.use(
    (config) => {
      const token = getToken();

      if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api1.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api1;
};

/*
 * usuario
 *
 */

export function getCurrentUser() {
  return api().get(`/user/me`);
}

export const getUsuarios = (id) => {
  return api().get(`/user`);
};

export function login(login) {
  return api().post("/auth/login", login);
}

export function signupCadastro(signup) {
  return api().post("/auth/signup", signup);
}

export function updateUsuario(user) {
  return api().post("/user/update", user);
}

export function updateSenha(user) {
  return api().post("/user/updateSenha", user);
}

/*
 * outras
 *
 */

export function buscarCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}

export function getEstados() {
  return api().get("/publico/estados");
}

/*
 *
 * Pagamentos
 *
 */

export const checkOutPayMathc = (id) => {
  return api().post(`/pagamentos`, id);
};

export const getTransacoes = (filtro) => {
  return api().post(`/pagamentos/filtro`, filtro);
};

export const atualizarStatusPagamento = (id) => {
  return api().get(`pagamentos/reenviaNotificacao/${id}`);
};

/*
 *
 * Planos
 *
 */

export function getPlanos() {
  return api().get(`/planos`);
}

export function salvarPlano(plano) {
  return api().post("/planos", plano);
}

export function getPlanoById(id) {
  return api().get(`/planos/${id}`);
}

export function excluirPlano(id) {
  return api().delete(`/planos/${id}`);
}

export function laboratorioSave(cliente) {
  return api().post("/auth/laboratorio", cliente);
}

/*
 * upload
 */

export const upload = (foto) => {
  return api().post(`/file`, foto);
};

/*
 * download
 */

export const download = (foto) => {
  return api().post(`/file/download`, foto, { responseType: "blob" });
};

/*
 * consultoria
 */

export const saveConsultoria = (consultoria) => {
  return api().post(`/consultoria`, consultoria);
};

export const filtroConsultoria = (filtro) => {
  return api().post(`/consultoria/filtro`, filtro);
};

export const getCnsultoria = (id) => {
  return api().get(`/consultoria/${id}`);
};

export const getCnsultoriaById = (id) => {
  return api().get(`/consultoria/quantidade/${id}`);
};

export const getConsultoriaByIdByStatus = (id) => {
  return api().get(`/consultoria/qtdPorStatus/${id}`);
};

export const enviarMenssagem = (menssagem, id) => {
  return api().post(`/consultoria/${id}/menssagem`, menssagem);
};

export const finalizarConsultoria = (id) => {
  return api().put(`/consultoria`, { id: id });
};

/*
 *
 * Redefinir senha
 *
 */

export const validarHash = (hash) => {
  return api().post(
    `/publico/validarHash/${hash}--ATFYUT-HKHMK-5WDAAAF5-6DF5-EBFF89`
  );
};

export const redefinirSenha = (redefinicao, hash) => {
  return api().post(
    `/publico/redefinirSenha/${hash}--ATFYUT-HKHMK-5WDAAAF5-6DF5-EBFF89`,
    redefinicao
  );
};

export const recuperarSenha = (email) => {
  return api().post(
    `/publico/recuperarEmail/aadgdfgdfvtcwtetvdrfgdfysgaet/${email}`
  );
};

export const ativaeUsuario = (hash) => {
  return api().post(
    `/publico/valida/email/6626af3sf6sdf7f69sdf7s6fsd9f/${hash}`
  );
};

export const testeIp = () => {
  return api().post(`/publico/testeIp`);
};

/*
 * Dashboard
 *
 */

export const dashboard = () => {
  return api().post(`/dashboard`);
};

export function getDashboard() {
  return api().get(`/dashboard`);
}

/*
 *
 * Livros
 *
 */

export const getConteudos = (filtro) => {
  return api().post(`/conteudo/filtro`, filtro);
};

export const getConteudo = (id) => {
  return api().get(`/conteudo/${id}`);
};

export const salvarConteudo = (conteudo) => {
  if (conteudo.id) {
    return api().put(`/conteudo`, conteudo);
  }
  return api().post(`/conteudo`, conteudo);
};

export const excluirConteudo = (id) => {
  return api().delete(`/conteudo/${id}`);
};

/*
 *
 * Publico
 *
 */

export const getPlanosPublico = () => {
  return api().get(`/publico/planos`);
};

export const getModulosPublico = () => {
  return api().get(`/publico/modulos`);
};

/*
 * Foto
 */

export const getFotoFiltro = (filtro) => {
  return api().post(`/foto`, filtro);
};

export const salvarPagina = (pagina) => {
  return api().post(`/paginas`, pagina);
};

export const uploadFoto = (foto) => {
  return api().post(`/foto/upload`, foto);
};

export const getPagina = (id) => {
  return api().get(`/paginas/${id}`);
};

export const getPaginasFiltroPublico = (filtro) => {
  return api().post(`/publico/paginas/filtro`, filtro);
};

export const getPaginasFiltro = (filtro) => {
  return api().post(`/paginas/filter`, filtro);
};

export const excluirPagina = (id) => {
  return api().delete(`/paginas/${id}`);
};

export const ativarPagina = (id) => {
  return api().get(`/paginas/ativa/${id}`);
};

export const getPaginaPublica = (id) => {
  return api().get(`/publico/paginas/${id}`);
};

/*
 *
 * Contato
 *
 */

export const enviarContato = (contato) => {
  return api().post(`/publico/contato`, contato);
};

/*
 *
 * Notificação
 *
 */

export const notificacoes = () => {
  if (getToken() !== null) {
    return api().get(`/notificacao`);
  }
  return new Promise((() => {}, () => {}));
};

export const deletaNotificacao = (id) => {
  return api().delete(`/notificacao/${id}`);
};

/*
 *
 * Comentário Podcast
 *
 */

export const enviarComentario = (comentarios) => {
  return api().post(`publico/comentario`, comentarios);
};

/*
 *
 * Noticias vindas do site ruim!
 *
 */

export const noticias = () => {
  return api().get(`publico/paginas/noticia`);
};

/*
 *
 * Aba Comentários
 *
 */

export function getComentarios() {
  return api().get(`/publico/abaComentario`);
}

export function getComentariosUltimos() {
  return api().get(`/publico/abaComentario/comentariosUltimos`);
}

export const enviarComentarioLegalis = (abaComentario) => {
  return api().post(`/publico/abaComentario`, abaComentario);
};

export function getComentariosTodos() {
  return api().get(`/publico/abaComentario/comentarios`);
}

export const ativarComentario = (ativarComentario) => {
  return api().post(`/publico/abaComentario/ativar`, ativarComentario);
};

export const removerComentario = (removerComentario) => {
  return api().post(`/publico/abaComentario/remover`, removerComentario);
};

/*
 *
 * Rols
 *
 */

export function getRols() {
  return api().get(`/rol`);
}

export function filtroRol(filtro) {
  return api().post(`/rol/filtro`, filtro);
}

export function salvarRol(rol) {
  return api().post("/rol", rol);
}

export function excluirRol(id) {
  return api().delete(`/rol/${id}`);
}

export function getRolsOrdenados() {
  return api().get(`/rol/ordenados`);
}

export function getRolsPorTipoOrdenados(tipo) {
  return api().get(`/rol/ordenados/${tipo}`);
}

/*
 *
 * Seções
 *
 */

export function getSecoes() {
  return api().get(`/secao`);
}

export function salvarSecao(rol) {
  return api().post("/secao", rol);
}

export function excluirSecao(id) {
  return api().delete(`/secao/${id}`);
}

export function getSecoesOrdenadas() {
  return api().get(`/secao/ordenados`);
}

/*
 *
 * Texto
 *
 */

export function getTextos() {
  return api().get(`/texto`);
}

export function salvarTexto(texto) {
  return api().post("/texto", texto);
}

export function excluirTexto(id) {
  return api().delete(`/texto/${id}`);
}

export function filtroTexto(filtro) {
  return api().post(`/textosCliente/filter`, filtro);
}

export function getTextoClienteById(id) {
  return api().get(`/textosCliente/${id}`);
}

export function salvarTextoCliente(texto) {
  return api().post(`/textosCliente`, texto);
}

export function gerarPdfTextoCliente(id) {
  return api().get(`/textosCliente/pdf/${id}`, { responseType: "blob" });
}

export function gerarPdfTextoClienteComModelo(modeloPdfDTO) {
  return api().post(`/textosCliente/pdfComModelo`, modeloPdfDTO, { responseType: "blob" });
}

export function deletaTextoCliente(id) {
  return api().delete(`/textosCliente/${id}`);
}

export function filtroTextoRol(filtro) {
  return api().post(`/texto/filtro`, filtro);
}

export function gerarQrcode(url) {
  return api().post(`/qrcode`, url);
}

export function chatGptPeca(peca) {
  return api().post(`/chatgpt`, peca);
}

/*
 *
 * Modelos PDF
 *
 */

export function verificaModelosByCliente(id) {
  return api().get(`/modelo/byUser/${id}`);
}

export function salvarCabecalhoImg(file) {
  return api().post(`/modelo`, file);
}

export function salvarCabecalhoTexto(texto) {
  return api().post(`/modelo/cabecalho/text`, texto);
}

export function salvarMarca(file) {
  return api().post(`/modelo/marca`, file);
}

export function salvarRodapeImg(file) {
  return api().post(`/modelo/rodape/img`, file);
}

export function salvarRodapeTexto(texto) {
  return api().post(`/modelo/rodape/text`, texto);
}

export function getModelosByUserCabecalho() {
  return api().get(`/modelo/getAllByUserCabecalho`);
}

export function getModelosByUserMarca() {
  return api().get(`/modelo/getAllByUserMarca`);
}

export function getModelosByUserRodape() {
  return api().get(`/modelo/getAllByUserRodape`);
}



// export function salvarModelo(modelo, file) {
//   const formData = new FormData();
//   formData.append('modelo', JSON.stringify(modelo));
//   formData.append('file', file);
//   console.log(formData);
//   return api().post(`/modelo`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   });
// }

// export const salvarModelo = async (imagemFile, jsonData) => {
//   const formData = new FormData();
//   formData.append('imagem', imagemFile);
//   formData.append('dadosJson', JSON.stringify(jsonData));

//   try {
//     const response = await api().post('/modelo', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     console.log(response.data);
//   } catch (error) {
//     console.error('Erro ao enviar dados:', error);
//   }
// };

/*
 *
 * Jurisprudencia
 *
 */

export function getJurisprudencia(filtro) {
  return api().post(`/jurisprudencia`, filtro);
}

export function getJurisprudenciaCompleto(link, filtro) {
  return api().post(`/jurisprudencia/link`, link + `?q=${filtro.texto}`);
}

/*
 *
 * Modulos Pagos
 *
 */

export function getModulos() {
  return api().get(`/modulos`);
}

export function salvarModulo(plano) {
  return api().post("/modulos", plano);
}

export function isUsoGratuito(id) {
  return api().get(`/modulos/usoGratuito/${id}`);
}

export function tempoGratuito(id) {
  return api().get(`/modulos/usoGratuitoTempo/${id}`);
}

export function getModulosById(id) {
  return api().get(`/modulos/${id}`);
}

/*
 *
 * Cupons
 *
 */

export function getCupons() {
  return api().get(`/cupons`);
}

export function salvarCupom(cupom) {
  return api().post("/cupons", cupom);
}

export function excluirCupom(id) {
  return api().delete(`/cupons/${id}`);
}

export function getCuponsEnviados() {
  return api().get(`/cupons/enviados`);
}

export const ativarCupomUso = (cupom) => {
  return api().put(`/cupons/ativar`, cupom);
};

export const inativarCupom = (cupom) => {
  return api().put(`/cupons/inativar`, cupom);
};

export function getVerificaCupom(titulo) {
  return api().get(`/cupons/validar/${titulo}`);
};

export function filtroCupom(filtro) {
  return api().post(`/cupons/filtro`, filtro);
};

export function enviarImagemEditor(imagem) {
  return api().post(`/foto/editor`, imagem)
};
