import React, { useState, useEffect } from "react";
import GoBack from "../../componentes/goBack";
import Listagem from "../../componentes/Listagem";
import { getUsuarios } from "../../util/Api";
import AppFooterLogado from "../../componentes/AppFooterLogado";

const Usuarios = (props) => {
  const [usuarios, setUsuarios] = useState({ content: [] });
  //const [atualiza, setAtualiza] = useState(false);

  const findUsuarios = () => {
    props.setLoading(true);
    getUsuarios().then((data) => {
      setUsuarios({ content: data });
      props.setLoading(false);
    });
  };

  useEffect(() => {
    findUsuarios();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container robotR">
      <div className="row mt-4 mb-2">
        {/* {atualiza ? null : (
          <div className="col-12 mt-2">
            <h3>Usuários Ativos</h3>
          </div>
        )} */}

      <div className="col-md-9">
        <GoBack link={"/dashboard"} />
      </div>
      <div className="col-md-3">
          <a href="#/dashboard" className="btn btn btn-voltar-novo">Voltar</a>
      </div>

      <Listagem
        titulo={<h5>Clientes</h5>}
        nome
        email
        telefone
        ativo
        paginacao={usuarios}
      />
      </div>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
  );
};

export default Usuarios;
