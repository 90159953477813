import React, { useState, useEffect } from "react";
import { notificacoes, deletaNotificacao } from "../util/Api";
import { Link } from "react-router-dom";
import "./notificacao.css";

const Notificacao = ({ usuario }) => {
  const [notificacao, setNotificacao] = useState([]);

  useEffect(() => {
    notificacoes().then((data) => {
      setNotificacao(data);
    });
    setInterval(() => {
      // if (usuario) {
      notificacoes().then((data) => {
        setNotificacao(data);
      });
      // }
    }, [100000]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {usuario && usuario.nivel === "ADM" ? (
        <div className="dropdown" id="notif">
          <div className="btn-group">
            {notificacao.length === 0 ? (
              <div>
                <span className="fa fa-bell-o sinoS"></span>
              </div>
            ) : (
              <>
                <a
                  className="dropdown-toggle"
                  href="#/"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="fa fa-bell sinoC fa-pulse"></span>
                </a>
              </>
            )}
            <div>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuLink"
                role="menu"
              >
                <div className="scrollable-menu">
                  {notificacao.map((n) => {
                    return (
                      <div
                        className="dropdown-item"
                        key={`${n.id}-notificacao`}
                      >
                        <p className="tituloNotify">{n.titulo}</p>
                        <div className="d-flex justify-content-between">
                          <p className="pNotify">{n.descricao}</p>
                          <div className="float-right">
                            {n.titulo === "Novo comentário" ? (
                              <Link
                                to="/comentarios"
                                className="btn btn-info btn-sm m-1"
                              >
                                {" "}
                                <i className="fa fa-arrow-circle-o-right" />
                              </Link>
                            ) : (
                              <Link
                                to="/help-legis"
                                className="btn btn-info btn-sm m-1"
                              >
                                {" "}
                                <i className="fa fa-arrow-circle-o-right" />
                              </Link>
                            )}

                            <button
                              className="btn btn-danger btn-sm m-1"
                              type="button"
                              onClick={() => {
                                deletaNotificacao(n.id).then(() => {
                                  notificacoes().then((data) => {
                                    setNotificacao(data);
                                  });
                                });
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </div>
                        </div>
                        <div className="cle"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Notificacao;
