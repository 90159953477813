import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import "./footer.css";
// import moment from "moment";
// import business from "../assets/imagens/whatsBusiness.png";
// import whatsNormal from "../assets/imagens/whatsNormal.png";

const AppFooterLogado = (props) => {
//   const [ativo, setAtivo] = useState(false);
//   const [ativo2, setAtivo2] = useState(false);
//   const handleClose = () => setAtivo(false);
//   const handleClose2 = () => setAtivo2(false);
    const [isFixed, setIsFixed] = useState(props.isFixed);
    const [ativoPoli, setAtivoPoli] = useState(false);
    const handleClosePoli = () => setAtivoPoli(false);
    const [ativo, setAtivo] = useState(false);
    const handleClose = () => setAtivo(false);
    // if (props.isFixed === null) {
    //   setIsFixed(true);
    // }
    // setIsFixed(props.isFixed === true ? true : false);
    // const isFixed = props.isFixed === true ? true : false;

    useEffect(() => {
      if (isFixed === undefined) {
        setIsFixed(true);
      }
    }, [isFixed]);

  return (
    <footer className={`container ${isFixed ? "fixed-bottom mb-5" : "my-2"}`}>
      <Modal id="modCome" show={ativoPoli} onHide={() => setAtivoPoli(false)} size="xl">
          <Modal.Header>
            <Modal.Title className="text-center">Política de Privacidade</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-justify">

            <p>A Legalis Soluções LTDA (iLegis), CNPJ 47.822.589/0001-77, com sede na rua João Pinheiro, 35, centro, Alfenas/MG, CEP: 37.130-035, 
            tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus clientes durante todo o processo de interação com nosso site, 
            em todas as etapas, seja no cadastro, na compra ou no conteúdo fornecido.</p>

            <p>A iLegis esclarece que sua razão social é Legalis Soluções LTDA.</p>

            <p>A política de privacidade se aplica a todos os clientes da Legalis Soluções (iLegis) que tenham seus dados pessoais tratados dentro da plataforma.</p>

            <p>A Legalis Soluções (iLegis) coleta os seguintes tipos de informação:</p>

            <ol>
              <li>As suas informações pessoais que você nos fornece, como CPF, nome completo, telefone, endereço e número de cartão de crédito/débito.</li>
              <li>Fontes públicas (por exemplo, dados demográficos); Agências ou órgãos de crédito (Por exemplo, relatórios de crédito);
                  Provedores de dados (Por exemplo, dados demográficos relacionados a anúncios publicitários on-line e com base nos interesses do cliente).
              </li>
            </ol>

            <p><strong>Por que a Legalis Soluções (iLegis) solicita seus dados pessoais?</strong></p>

            <p>Para que possamos fornecer nosso serviço com qualidade e eficiência, sempre mantendo uma interação do cliente com a empresa, afim de garantir o melhor atendimento.</p>

            <p>Seus dados pessoais nos permitem filtrar seus interesses e trazer à você ofertas, brindes, benefícios, anúncios, todos voltados ao seu interesse.</p>

            <p>Também com seus dados, temos a garantia legal para que você, cliente, se sinta seguro quanto a entrega do serviço adquirido, da mesma forma, damos a garantia dos
            direitos inerentes ao consumidor garantidos pelo Código de Defesa do Consumidor.</p>

            <p>Ademais, todos os dados coletados, são resguardados e tratados, obedecido todo o estabelecido na LGPD.</p>

            <p><strong>Sobre nossos serviços de Marketing</strong></p>

            <p>São fornecidos serviços de marketing gratuitos, através de anúncios, ofertas, promoções, todos relacionados ao seu perfil de navegação,
            encaminhando a você conteúdo que seja do seu interesse. Ressalta que seu cadastro pode ser excluído a qualquer momento, caso queira, na aba “excluir conta”.</p>

            <p><strong>Com quem são compartilhados seus dados?</strong></p>

            <p>Seus dados não serão compartilhados com terceiros. Os dados tem uso somente pela equipe iLegis. Todos os seus dados são tratados pela Legalis Soluções
            (iLegis) e utilizados apenas pelos setores responsáveis quando necessário, sempre obedecendo o estabelecido na LGPD, garantindo segurança ao cliente desde o início do cadastro.</p>

            <p><strong>Como você pode manter suas informações pessoais seguras?</strong></p>

            <p>A Legalis Soluções (iLegis) conta com profissionais que asseguram sua segurança quanto aos dados que você fornece. 
            Porém, é necessário que o cliente tenha alguns cuidados para manter seus dados seguros.</p>
            
            <p>Nunca forneça seu login ou senha para terceiros.</p>

            <p>Quando acessar a plataforma em computadores públicos, lembre-se sempre de fazer seu “logout” da plataforma, para evitar que acessem seu ambiente no nosso site.</p>

            <p>Lembramos que a Legalis Soluções (iLegis) NÃO solicita fornecimento de dados pessoais por meio de telefone, email ou qualquer outro meio. Apenas os clientes nos 
            fornecem a documentação. Nunca entraremos em contato para solicitar nenhum dado ou atualização de cadastro.</p>
            
            
            <p>Os dados são fornecidos mediante preenchimento de cadastro no site, mediante interesse do cliente, sendo os dados armazenados com segurança.</p>

            <p>É recomendado ler os termos de uso do site <a href="https://www.ilegis.com.br" target="_blank" rel="noopener noreferrer">www.ilegis.com.br</a>.</p>

            <p>A ilegis está à disposição. Dúvidas, orientações ou sugestões nos contatar pelo <a href="mailto:contato@ilegis.com.br">contato@ilegis.com.br</a>.</p>

    
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-info" onClick={handleClosePoli}>
              Fechar
            </Button>
          </Modal.Footer>
      </Modal>

      <Modal id="modCome" show={ativo} onHide={() => setAtivo(false)} size="xl">
          <Modal.Header>
            <Modal.Title className="text-center">Política de pagamentos e descontos</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-justify">

            <p>A ILEGIS conta com variadas formas de pagamento para melhor atender você:</p>

            <p>Parcele sem juros no cartão ou tenha 5% de desconto para pagamento via boleto ou pix</p>

            <p>Quer renovar um pacote? Ótimo! A ILEGIS te dá 5% de desconto na renovação de seu pacote</p>

            <ul>
              <li>O desconto será aplicado a cada renovação</li>
              <li>O desconto será aplicado sobre o valor vigente no site</li>
            </ul>

          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-info" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
      </Modal>
        <section className="row justify-content-between">
          <div className="col-md-5 pt-2">
            <h5 className="txtNew">© Copyright {moment().format("YYYY")}  | iLegis. Todos os direitos reservados</h5>
          </div>
          <div className="col-md-3 pt-2">
            <h5 className="txtNew cursorRodape" onClick={() => {setAtivo(true);}}>Política de pagamentos e descontos</h5>
          </div>
          <div className="col-md-2 pt-2">
            <h5 className="txtNew cursorRodape" onClick={() => {setAtivoPoli(true);}}>Política de privacidade</h5>
          </div>
        </section>  
    </footer>
  );
};

export default AppFooterLogado;
