import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import seta from "../assets/imagens/icones/seta.png";

const nomeHome = () => {
  const nameUrl = window.location.href
  return nameUrl;
}

const verificaNomeReturn = (rota) => {
  if (rota === '/cadastros' && nomeHome().split("#/").at(1) === 'pacotes') {
    return <> Home / Cadastros / <span className="setaAzul">Pacotes</span> </>;
  } else if (rota === '/modulos' && nomeHome().split("#/").at(1) === 'contratarModulo/1') {
    return <> Home / <span className="setaAzul">PJA</span> </>;
  } else if (rota === '/cadastros' && nomeHome().split("#/").at(1) === 'cadastroPja') {
    return <> Home / Cadastros / <span className="setaAzul">PJA</span> </>;
  } else if (rota === '/cadastros' && nomeHome().split("#/").at(1) === 'cupons') {
    return <> Home / Cadastros / <span className="setaAzul">Cupons</span> </>;
  } else if (rota === "/modulos" && nomeHome().split("#/").at(1) === 'cadastros') {
    return <> Home / <span className="setaAzul">Cadastros</span> </>;
  } else if (rota === "/help-legis" && nomeHome().split("#/").at(1).match('pagamento')) {
    return <> Help Legis / <span className="setaAzul">Pagamento</span> </>;
  } else if (rota === "/pja" && nomeHome().split("#/").at(1).match('pagamentoModulo')) {
    return <> PJA / <span className="setaAzul">Pagamento</span> </>;
  } else if (rota.includes("/texto")) {
    return <> PJA / <span className="setaAzul">Texto</span> </>;
  } else if (rota === "/help-legis" && nomeHome().split("#/").at(1).match('consultoria')) {
    return <> Help Legis / <span className="setaAzul">Nova consultoria</span> </>;
  } else if (rota === "/modulos" && nomeHome().split("#/").at(1).match('pagamentos')) {
    return <> Home / <span className="setaAzul">Pagamentos</span> </>;
  } else if (rota === "/dashboard" && nomeHome().split("#/").at(1).match('usuarios')) {
    return <> Relatórios / <span className="setaAzul">Usuários</span> </>;
  } else if (rota === '/modulos') {
    return "  Home";
  } else {
    return "Dashboard";
  }
};

const GoBack = (props) => {

  return (
    <div className="go-back">
      <Link to={props.link}>
      <p className="returnSeta">
        <img
            className="mr-3"
            src={seta ? seta : null}
            alt="seta"
        />
         {verificaNomeReturn(props.link)}</p>
      </Link>
    </div>
  );
};

export default GoBack;
