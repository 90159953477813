import React, { useState } from "react";
import * as moment from "moment";
import "moment/locale/pt-br";
import ReactPaginate from "react-paginate";
import { createHashHistory } from "history";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Alert from "react-s-alert";
import { ativarComentario, removerComentario, inativarCupom, ativarCupomUso } from "../util/Api";
import { Modal, Button } from "react-bootstrap";
import botaoExcluirImg from "../assets/imagens/icones/lixeira.png";
import botaoEditarImg from "../assets/imagens/icones/editarBotao.png";
import modalExcluir from "../assets/imagens/icones/modalExcluir.svg";
import setaAnterior from "../assets/imagens/icones/setaAnterior.png";
import setaProximo from "../assets/imagens/icones/setaProximo.png";
import eyeCinza from "../assets/imagens/icones/eyeCinza.png";
import iconeX from "../assets/imagens/icones/x-circle.png";
import "./listagem.css";

const history = createHashHistory();

const Listagem = (props) => {
  const [ativarText, setAtivarText] = useState(false);
  const [textoVer, setTextoVer] = useState("");
  const [secaoTextoTipo, setSecaoTextoTipo] = useState("");
  const [subtituloVer, setSubtituloVer] = useState("");
  const handleClose = () => setAtivarText(false);
  moment.locale("pt-BR");
  // const atualizar = () => props.history.push("/comentarios");

  const iconePaginacaoAnterior = () => {
    return (
      <img src={setaAnterior ? setaAnterior : null} className="mx-1" alt="botão seta baixo"/>
    )
  }

  const iconePaginacaoProximo = () => {
    return (
      <img src={setaProximo ? setaProximo : null} className="mx-1" alt="botão seta próximo"/>
    )
  }

  const {
    data,
    nome,
    tipo,
    acao,
    goToEdit,
    botaoEditar,
    botaoExcluir,
    botaoAtivar,
    reload,
    // funcaoAtivar,
    paginacao,
    recarregar,
    login,
    cpfCNPJ,
    titulo,
    novoLancamento,
    linkNovoLancamento,
    labelLink,
    filtroAvancado,
    filtroSimples,
    buscaSimplificada,
    labelFiltroSimples,
    abrirModalPesquisa,
    visualizar,
    via,
    email,
    telefone,
    tipoDeProcedimento,
    descricao,
    dt,
    valor,
    dataPedido,
    gerarOrdemServico,
    custonConfirmTitulo,
    custonConfirmLabel,
    custonConfirmFunction,
    //cliente,
    dataDaEntrega,
    clientePedido,
    botaoExcluirDropdown,
    dataEntrega,
    modalPagamento,
    nomeOrdemServico,
    statusPagamento,
    status,
    ativo,
    tituloPlano,
    tipoRol,
    currentUser,
    tituloConsultoria,
    tituloPagina,
    subtitulo,
    verTexto,
    decreto,
    rol,
    secao,
    parcela,
    notVisualize,
    meses,
    isNew,
    porcentagem,
    destinatario,
    verTextoDesc,
    cupom,
    enviado,
    ativarComentarioBtn,
    statusConsultoria,
    verConsultoria,
    acaoConsultoria,
    dataPagamento,
    servico,
    servicoQual,
    acaoNewPagamento,
  } = props;

  function funcaoAtivarComentario(item) {
    if (item.ativo === false) {
      ativarComentario(item)
        .then(() => {
          reload();
          Alert.success("Comentário ativado com sucesso!");

          // window.location.reload();
          // window.location.pathname
          //findComentarios();
          //window.location.replaceState();
          //window.location.href = "/";
        })
        .catch((error) => {
          Alert.error((error && error.message) || "deu ruim!");
        });
    } else {
      removerComentario(item)
        .then(() => {
          reload();
          Alert.info("Comentário desativado com sucesso!");
        })
        .catch((error) => {
          Alert.error((error && error.message) || "deu ruim!");
        });
    }
  }

  function funcaoAtivarCupom(item) {
    if (item.ativo === false) {
      ativarCupomUso(item)
        .then(() => {
          Alert.info("Cupom ativado com sucesso!");
          history.push("/cupons");
        })
        .catch((error) => {
          Alert.error((error && error.message) || "deu ruim!");
        });
    } else {
      inativarCupom(item)
        .then(() => {
          history.push("/cupons");
          Alert.info("Cupom desativado com sucesso!");
        })
        .catch((error) => {
          Alert.error((error && error.message) || "deu ruim!");
        });
    }
  }

  function tipoRolNome(tipo) {
    if (tipo !== null){
      if(tipo === 'CIVEL'){
        return 'Cível'
      }
  
      if(tipo === 'TRABALHISTA'){
        return 'Trabalhista'
      }

      if (tipo === 'PREVIDENCIARIO'){
        return 'Previdenciário'
      }
    }
    return '-'
  }

  const ativar = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-fluid box-shadow pt-3 pb-3 box-confirmation">
            <div className="row justify-content-around">
              <div className="col-12 text-center">
                <h3>
                  {item.ativo === true ? "Retirar " : "Ativar "}
                  comentário!
                </h3>
                <p>
                  Deseja {item.ativo === true ? "retirar " : "ativar "} o
                  comentário do usuário {item.nome}?
                </p>
              </div>
              <div className="row text-center">
                <div className="col-md-6">
                  <button className="btn btn-outline-warning" onClick={onClose}>
                    Não
                  </button>
                </div>

                <div className="col-md-6">
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      funcaoAtivarComentario(item);
                      onClose();
                    }}
                  >
                    Sim
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const ativarCupom = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-fluid box-shadow pt-3 pb-3 box-confirmation">
            <div className="row justify-content-around">
              <div className="col-12 text-center">
                <h3>
                  {item.ativo === true ? "Inativar " : "Ativar "}
                  cupom!
                </h3>
                <p>
                  Deseja {item.ativo === true ? "inativar " : "ativar "} o
                  cupom?
                </p>
              </div>
              <div className="row text-center">
                <div className="col-md-6">
                  <button className="btn btn-outline-warning" onClick={onClose}>
                    Não
                  </button>
                </div>

                <div className="col-md-6">
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      funcaoAtivarCupom(item);
                      onClose();
                    }}
                  >
                    Sim
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const exluir = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-fluid box-shadow pt-3 pb-3 box-confirmation">
            <div className="row justify-content-around">
              <div className="col-12 text-center">
                <img
                  className="image-logo pl-md-2"
                  src={modalExcluir ? modalExcluir : null}
                  alt="excluir modal"
                />
                <h3>{item.titulo ? "Excluir " + item.titulo + "?" : "Deseja excluir?"}</h3>
                <p>
                  Textos vinculados a esta Rol serão excluídos.<br />Não será possível desfazer. <br/>Deseja realmente excluir?
                </p>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-warning-new btn-lg m-2"
                  onClick={onClose}
                >
                  Não
                </button>

                <button
                  className="btn btn-danger-new-modal btn-lg m-2"
                  onClick={() => {
                    botaoExcluir
                      ? botaoExcluir(item)
                      : botaoExcluirDropdown(item);
                    onClose();
                  }}
                >
                  Excluir
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const custonConfirm = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="container-fluid box-shadow p-4 box-confirmation">
            <div className="justify-content-center robotR">
              <div className="col-12 text-center">
                <h4>{custonConfirmTitulo}</h4>
                <h5>{custonConfirmLabel}</h5>
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-warning btn-lg m-2"
                  onClick={onClose}
                >
                  Não, quero cancelar!
                </button>
                <button
                  className="btn btn-outline-success btn-lg m-2"
                  onClick={() => {
                    custonConfirmFunction(item);
                    onClose();
                  }}
                >
                  Sim, desejo continuar!
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  function verDesc(item) {
    // console.log(item)
    setTextoVer(item.descricao);
    setSecaoTextoTipo(item?.secao.titulo);
    setSubtituloVer(item.subtitulo);
    setAtivarText(true);
  }

  const onPageChange = ({ selected }) => {
    paginacao.changePage(selected);
  };

  return (
    <div className="container pb-2">
      <Modal
        id="modCome"
        show={ativarText}
        onHide={() => setAtivarText(false)}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>{subtituloVer ? "Texto do título: " + subtituloVer : "Descrição do cupom"}</Modal.Title>
          <button className="btn btn-sm btnNoBack" type="button" onClick={handleClose}><img src={iconeX ? iconeX : null} className="mx-1" alt="botão fechar"/></button>
        </Modal.Header>
        <Modal.Body>
          {secaoTextoTipo === 'VÍDEOS RECOMENDADOS' && (textoVer.includes('https') || textoVer.includes('youtube')) ? (
            <span>Vídeo recomendado: <a href={textoVer} target="_blank" rel="noopener noreferrer">Clique aqui</a></span>
          ) : (
            <span>{textoVer}</span>
          )}
          {/* <p>{textoVer}</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn buttonAtualizarListagem" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row mb-3">
        <div className="col-12 pt-3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4 className="textDark">{titulo}</h4>
              {filtroAvancado && (
                <div style={{ marginLeft: 5 }}>
                  <button
                    className="btn btn-info btn-sm mb-1"
                    onClick={abrirModalPesquisa}
                  >
                    <i className="fa fa-search-plus" /> Pesquisa avançada
                  </button>
                </div>
              )}
            </div>

            {novoLancamento || (paginacao && paginacao.changePage) ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginLeft: 10,
                  }}
                > */}
                  {novoLancamento && currentUser.nivel === "CLIENTE" && isNew !== "novo" ? (
                    <div className="col-md-6">
                      <Link
                        className="btn buttonNovaPeticao my-1"
                        to={linkNovoLancamento}
                      >
                        <i className="fa fa-plus" /> {labelLink}
                      </Link>
                    </div>
                  ) : null}
                  {novoLancamento && currentUser.nivel === "ADM" && (isNew !== "novo" || isNew !== "novoN") ? (
                    <div className="col-md-6">
                      <Link className="btn buttonNovaPeticao my-1" to={linkNovoLancamento}>
                        <i className="fa fa-plus" /> {labelLink}
                      </Link>
                    </div>
                  ) : null}
                  {paginacao.changePage && (
                    <div className="mb-1 col-md-6">
                      <span
                        className="visulizar-checklist ho btn buttonAtualizarListagem"
                        onClick={() => paginacao.changePage(paginacao.number)}
                      >
                        <i className="fa fa-refresh" /> Atualizar Listagem
                      </span>
                    </div>
                  )}
                </div>
              // </div>
            ) : undefined}
          </div>
          <hr className="widHr"/>
          {filtroSimples && (
            <div>
              <input
                style={{ width: "100%", marginBottom: 5 }}
                placeholder={labelFiltroSimples}
                className="form-control "
                onChange={(e) => buscaSimplificada(e.target.value)}
              />
            </div>
          )}
          <div className="table-responsive-md mb-4">
            <table className="table table-hover table-sm text-center">
              <thead className="thead-light-new table-bordered">
                <tr>
                  {data || dataPedido ? <th scope="col" className="itemTabela">Data</th> : null}
                  {dataPagamento ? <th scope="col" className="itemTabela">Data / Hora</th> : null}
                  {/* {cliente && currentUser?.nivel === "ADM" ? <th scope="col" className="itemTabela">Cliente</th> : null} */}
                  {nome ? <th scope="col">Nome</th> : null}
                  {tituloPlano ? <th scope="col" className="itemTabela">Título</th> : null}
                  {tipoRol ? <th scope="col" className="itemTabela">Tipo</th> : null}

                  {currentUser?.nivel === "ADM" ? (
                    <th scope="col" className="itemTabela">Usuário</th>
                  ) : null}
                  {servico ? (
                    <th scope="col" className="itemTabela">Serviço</th>
                  ) : null}
                  {servicoQual ? <th scope="col" className="itemTabela">Plano</th> : null}
                  {dataDaEntrega ? <th scope="col" className="itemTabela">Data da entrega</th> : null}
                  {clientePedido ? <th scope="col" className="itemTabela">Cliente</th> : null}
                  {tipo ? <th scope="col" className="itemTabela">Tipo</th> : null}
                  {dataEntrega ? <th scope="col" className="itemTabela">Data entrega</th> : null}
                  {email ? <th scope="col" className="itemTabela">Email</th> : null}
                  {subtitulo ? <th scope="col" className="itemTabela">Subtítulo</th> : null}
                  {decreto ? <th scope="col" className="itemTabela">Decreto</th> : null}
                  {rol ? <th scope="col" className="itemTabela">Rol</th> : null}
                  {secao ? <th scope="col" className="itemTabela">Seção</th> : null}
                  {descricao ? <th scope="col" className="itemTabela">Descrição</th> : null}
                  {porcentagem ? <th scope="col" className="itemTabela">Desconto</th> : null}
                  {destinatario ? <th scope="col" className="itemTabela">Destinatário</th> : null}
                  {dt ? <th scope="col" className="itemTabela">Data comentário</th> : null}
                  {verTexto ? <th scope="col" className="itemTabela">Ver Texto</th> : null}
                  {telefone ? <th scope="col" className="itemTabela">Telefone</th> : null}
                  {ativo ? <th scope="col" className="itemTabela">Status</th> : null}
                  {/* {verTextoDesc ? <th scope="col" className="itemTabela">Descrição</th> : null} */}
                  {cupom ? <th scope="col" className="itemTabela">Cupom</th> : null}
                  {enviado ? <th scope="col" className="itemTabela">Foi enviado?</th> : null}
                  {via ? <th scope="col" className="itemTabela">Via de aplicação</th> : null}
                  {cpfCNPJ ? <th scope="col" className="itemTabela">CPF/CNPJ</th> : null}
                  {statusPagamento ? <th scope="col" className="itemTabela">Status</th> : null}
                  {tituloPagina ? <th scope="col" className="itemTabela">Titulo</th> : null}
                  {valor ? <th scope="col" className="itemTabela">Valor</th> : null}
                  {parcela ? <th scope="col" className="itemTabela">Parcelas</th> : null}
                  {meses ? <th scope="col" className="itemTabela">Meses</th> : null}
                  {/* {ativo ? <th scope="col">Situação</th> : null} */}
                  {tituloConsultoria ? <th scope="col" className="itemTabela">Título</th> : null}
                  {status ? <th scope="col" className="itemTabela">Status</th> : null}
                  {statusConsultoria ? <th scope="col" className="itemTabela">Status</th> : null}
                  {botaoAtivar ? <th scope="col" className="itemTabela">Situação</th> : null}
                  {tipoDeProcedimento ? (
                    <th scope="col" className="itemTabela">Tipo de Procedimento</th>
                  ) : null}
                  {acaoNewPagamento ? <th scope="col" className="itemTabela">Ações</th> : null}
                  {acao ? <th scope="col" className="itemTabela">Ações</th> : null}
                  {verConsultoria && currentUser.nivel === "CLIENTE" ? <th scope="col" className="itemTabela">Ações</th> : null}
                  {acaoConsultoria && currentUser.nivel === "ADM" ? <th scope="col" className="itemTabela">Ações</th> : null}
                  {login ? <th scope="col" className="itemTabela">Login</th> : null}
                  {botaoExcluir || recarregar || botaoEditar || visualizar || goToEdit || verTextoDesc ? (
                    <th scope="col" className="itemTabela">Ações</th>
                  ) : null}
                </tr>
              </thead>
              <tbody className="table-hover">
                {props.paginacao
                  ? props.paginacao.content.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          className={`${
                            item.ativo === false ? "" : ""
                          }`}
                        >
                          {data ? (
                            <th scope="row">{moment(item.data).format("L")}</th>
                          ) : null}

                          {dataPedido ? (
                            <th scope="row">
                              {moment(item.dataPedido).format("L")}
                            </th>
                          ) : null}

                          {dataPagamento ? (
                            <td>
                              {moment(item.data).format("DD/MM/YYYY HH:mm:ss")}
                            </td>
                          ) : null}

                          {/* {cliente && currentUser?.nivel === "ADM" ? (
                            <th scope="row">
                              {item?.cliente?.nome}
                            </th>
                          ) : null} */}

                          {nome && item.nome ? <td>{item.nome}</td> : null}
                          {tituloPlano && item.titulo ? (
                            <td>{item.titulo}</td>
                          ) : null}

                          {/* {tipo && item.tipo ? <td>{tipoRolNome(item.tipo)}</td> : null} */}
                          {tipoRol && item.tipo ? (
                            <td>{tipoRolNome(item.tipo)}</td>
                          ) : null}

                          {email ? <td>{item.email}</td> : null}

                          {currentUser?.nivel === "ADM" ? (
                            <td>{item.cliente.nome}</td>
                          ) : null}

                          {servico ? (
                            <td>
                              {item?.modulo ? "PJA" : "Consultoria"}
                            </td>
                          ): null}

                          {servicoQual ? (
                            <td>
                              {item?.modulo ? "PJA" : item?.plano?.titulo}
                            </td>
                          ): null}

                          {clientePedido ? (
                            <td>{item.pedido.cliente.nome}</td>
                          ) : null}

                          {nomeOrdemServico ? (
                            <td>{item.ordemDeServico.pedido.cliente.nome}</td>
                          ) : null}

                          {dataDaEntrega ? (
                            <th scope="row">
                              {moment(item.dataDaEntrega).format("L")}
                            </th>
                          ) : null}

                          {dataEntrega ? (
                            <th scope="row">
                              {moment(item.pedido.dataDaEntrega).format("L")}
                            </th>
                          ) : null}

                          {tituloPagina ? <td>{item.titulo}</td> : null}

                          {telefone ? <td>{item.telefone}</td> : null}

                          {subtitulo ? <td>{item.subtitulo}</td> : null}

                          {decreto ? <td>{item.decreto}</td> : null}

                          {rol ? <td>{item?.rol?.titulo}</td> : null}

                          {secao ? <td>{item?.secao?.titulo}</td> : null}

                          {descricao && item.descricao ? (
                            <td>{item.descricao}</td>
                          ) : null}

                          {porcentagem && item.porcentagem ? (
                            <td>{item.porcentagem}%</td>
                          ) : null}

                          {destinatario ? (
                            <td>{item.destinatario ? item.destinatario : "-"}</td>
                          ) : null}

                          {dt && item.dt ? (
                            <td>{item.dt}</td>
                          ) : null}

                          {verTexto ? (
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm mr-1"
                                onClick={(e) => verDesc(item)}
                              >
                                <img src={eyeCinza ? eyeCinza : null} className="mx-1" alt="botão ver"/>
                              </button>
                            </td>
                          ) : null}

                          {ativo ? (
                            <td className={`${item.ativo === true ? "autorizadoClass" : "inativoClass"}`}>{item.ativo === true ? "Ativo" : "Inativo"}</td>
                          ) : null}

                          {cupom ? (
                            <td>{item?.cupom?.titulo}</td>
                          ) : null}

                          {enviado ? (
                            <td className={`${item.enviado === true ? "autorizadoClass" : "inativoClass"}`}>{item.enviado === true ? "Sim" : "Não"}</td>
                          ) : null}

                          {cpfCNPJ && item.cpfCNPJ ? (
                            <td>{item.cpfCNPJ}</td>
                          ) : null}

                          {login && item.login ? <td>{item.login}</td> : null}

                          {tipoDeProcedimento && item.tipoDeProcedimento ? (
                            <td>{item.tipoDeProcedimento}</td>
                          ) : null}
{/* 
                          {statusPagamento ? (
                            <td>{item.statusPagamento}</td>
                          ) : null} */}

                          {statusPagamento && (item.statusPagamento === "AUTORIZADO" || item.statusPagamento === "APROVADO") ? (
                            <td className="autorizado">Autorizado</td>
                          ): null}

                          {statusPagamento && item.statusPagamento === "PENDENTE" ? (
                            <td className="pendente">Pendente</td>
                          ): null}

                          {statusPagamento && item.statusPagamento === "REJECTED" ? (
                            <td className="recusado">Recusado</td>
                          ): null}

                          {valor ? (
                            <td>
                              {" "}
                              {item.valor
                                ? item.valor.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                : "--"}
                            </td>
                          ) : null}

                          {parcela && <td>{item.parcela}</td>}
                          {meses && <td>{item.meses}</td>}

                          {tituloConsultoria ? <td>{item.titulo}</td> : null}
                          
                          {status ? (
                            <td>{item.status ? "Aberta" : "Encerrada"}</td>
                          ) : null}

                          {statusConsultoria ? (
                            <td className={`${item.status ? "abertaClass" : "finalizadaClass"}`}>{item.status ? "Aberta" : "Finalizada"}</td>
                          ) : null}

                          {verConsultoria && currentUser.nivel === "CLIENTE" ? (
                            <td>
                              <button
                                type="button"
                                className="btn noBorder btn-sm mr-1"
                                onClick={() => props.setIten(item)}
                              >
                                <img src={eyeCinza ? eyeCinza : null} className="mx-1" alt="botão ver"/>
                              </button>
                            </td>
                          ) : null}

                          {acaoNewPagamento ? (
                            <td>
                              <button
                                type="button"
                                className="btn noBorder btn-sm mr-1"
                                onClick={() => props.setIten(item)}
                              >
                                <img src={eyeCinza ? eyeCinza : null} className="mx-1" alt="botão ver"/>
                              </button>
                            </td>
                          ) : null}

                          {botaoExcluir ||
                          botaoEditar ||
                          botaoAtivar ||
                          goToEdit ||
                          visualizar ||
                          verTextoDesc ? (
                            <td>
                              {botaoExcluir && !item.resposta ? (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger-new mr-1"
                                  onClick={(e) => exluir(item)}
                                >
                                  <img
                                    src={botaoExcluirImg ? botaoExcluirImg : null}
                                    alt="botão excluir"
                                  />
                                </button>
                              ) : null}

                              {verTextoDesc ? (
                                  <button
                                    type="button"
                                    className="btn btn-sm mr-1"
                                    onClick={(e) => verDesc(item)}
                                  >
                                    <img src={eyeCinza ? eyeCinza : null} className="mx-1" alt="botão ver"/>
                                  </button>
                              ) : null}

                              {botaoAtivar ? (
                                <button
                                  type="button"
                                  className="btn btn-sm btAtivar mr-1"
                                  onClick={(e) => ativar(item)}
                                >
                                  <i
                                    className={`fa ${
                                      item.ativo
                                        ? "fa-toggle-on p-2"
                                        : "fa-toggle-off p-2"
                                    }`}
                                  ></i>
                                </button>
                              ) : null}

                              {botaoEditar ? (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info-new mr-1"
                                  onClick={(e) => {
                                    botaoEditar(item);
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                >
                                  <img
                                    src={botaoEditarImg ? botaoEditarImg : null}
                                    alt="botão editar"
                                  />
                                </button>
                              ) : null}

                              {goToEdit ? (
                                  <Link
                                    to={`${goToEdit}${item.id}`}
                                    className="btn btn-sm btn-info-new mr-1">
                                    <img
                                      src={botaoEditarImg ? botaoEditarImg : null}
                                      alt="botão editar"
                                    />
                                  </Link>
                              ) : null}

                              {ativarComentarioBtn ? (
                                <button
                                    type="button"
                                    className="btn btn-sm btAtivar mr-1"
                                    onClick={(e) => ativarCupom(item)}
                                  >
                                  <i
                                    className={`fa ${
                                      item.ativo
                                        ? "fa-toggle-on p-2"
                                        : "fa-toggle-off p-2"
                                    }`}
                                  ></i>
                                </button>
                              ) : null}

                              {visualizar ? (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info mr-1"
                                  rel="noopener noreferrer"
                                  onClick={(e) => props.setIten(item)}
                                >
                                  <i className={`fa fa-eye`}></i>
                                </button>
                              ) : null}
                            </td>
                          ) : null}


                        {acaoConsultoria && currentUser.nivel === "ADM" ? (
                            <td>
                              <button
                                type="button"
                                className="btn btn-info btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Ação
                              </button>
                              <div className="dropdown-menu">
                                {!notVisualize && (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => props.setIten(item)}
                                  >
                                    <i className="fa fa-eye" />
                                    <span className="ml-2">Visualizar</span>
                                  </div>
                                )}

                                {goToEdit ? (
                                  <Link
                                    to={`${goToEdit}${item.id}`}
                                    className="dropdown-item"
                                  >
                                    <i className="fa fa-pencil" />
                                    <span className="ml-2">Editar</span>
                                  </Link>
                                ) : null}

                                {gerarOrdemServico && !item.ordemServico ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => custonConfirm(item)}
                                  >
                                    <i className="fa fa-pencil" />
                                    <span className="ml-2">
                                      Gerar Ordem de Serviço
                                    </span>
                                  </div>
                                ) : null}

                                {botaoExcluirDropdown &&
                                !item.ordemServico &&
                                item.status !== "FINALIZADA" ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => exluir(item)}
                                  >
                                    <i className="fa fa-trash" />
                                    <span className="ml-2">Excluir</span>
                                  </div>
                                ) : null}

                                {custonConfirmFunction &&
                                currentUser.nivel === "ADM" &&
                                item.status ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => custonConfirm(item)}
                                  >
                                    <i className="fa fa-check" />
                                    <span className="ml-2">Finalizar</span>
                                  </div>
                                ) : null}

                                {modalPagamento &&
                                item.status !== "FINALIZADA" ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => modalPagamento(item)}
                                  >
                                    <i className="fa fa-external-link" />
                                    <span className="ml-2">Pagar</span>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                          
                          {acao ? (
                            <td>
                              <button
                                type="button"
                                className="btn btn-info btn-sm dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Ação
                              </button>
                              <div className="dropdown-menu">
                                {!notVisualize && (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => props.setIten(item)}
                                  >
                                    <i className="fa fa-eye" />
                                    <span className="ml-2">Visualizar</span>
                                  </div>
                                )}

                                {goToEdit ? (
                                  <Link
                                    to={`${goToEdit}${item.id}`}
                                    className="dropdown-item"
                                  >
                                    <i className="fa fa-pencil" />
                                    <span className="ml-2">Editar</span>
                                  </Link>
                                ) : null}

                                {gerarOrdemServico && !item.ordemServico ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => custonConfirm(item)}
                                  >
                                    <i className="fa fa-pencil" />
                                    <span className="ml-2">
                                      Gerar Ordem de Serviço
                                    </span>
                                  </div>
                                ) : null}

                                {botaoExcluirDropdown &&
                                !item.ordemServico &&
                                item.status !== "FINALIZADA" ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => exluir(item)}
                                  >
                                    <i className="fa fa-trash" />
                                    <span className="ml-2">Excluir</span>
                                  </div>
                                ) : null}

                                {custonConfirmFunction &&
                                currentUser.nivel === "ADM" &&
                                item.status ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => custonConfirm(item)}
                                  >
                                    <i className="fa fa-check" />
                                    <span className="ml-2">Finalizar</span>
                                  </div>
                                ) : null}

                                {modalPagamento &&
                                item.status !== "FINALIZADA" ? (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => modalPagamento(item)}
                                  >
                                    <i className="fa fa-external-link" />
                                    <span className="ml-2">Pagar</span>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {props.lista && props.lista.length === 0 ? (
              <h6 className="text-center">Sem dados encontrados!</h6>
            ) : null}
            <section className="row justify-content-between">
              <div className="col-md-3 mt-3">
              {paginacao && paginacao.totalPages > 0 ? (
                <>
                  <div>
                    <p className="totalItens">Total de itens: {paginacao.totalElements} 
                      {/* <img src={setaBaixo ? setaBaixo : null} className="mx-1" alt="botão seta baixo"/> */}
                    </p>
                  </div>
                </>
              ) : (
                null
              )}  
              </div>
              <div className="col-md-9">
                {paginacao && paginacao.totalPages > 1 ? (
                  <ReactPaginate
                    pageCount={paginacao.totalPages}
                    pageRangeDisplayed={6}
                    marginPagesDisplayed={5}
                    previousLabel={iconePaginacaoAnterior()}
                    nextLabel={iconePaginacaoProximo()}
                    breakClassName="li-border"
                    containerClassName="d-flex no-li-decoration pt-1"
                    pageClassName="li-border"
                    onPageChange={onPageChange}
                    activeClassName="bgSelected"
                    previousClassName="mr-3 link-page"
                    nextClassName="ml-3 link-page"
                    disabledClassName="disable-page"
                  />
                ) : null}
              </div>  
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listagem;
