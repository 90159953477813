import React, { useEffect, useState } from "react";
import GoBack from "../../componentes/goBack";
import { getPlanos, getCnsultoriaById } from "../../util/Api";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import vendido from "../../assets/imagens/icones/vendido.png";

const Cotratar = (props) => {
  const [planos, setPlanos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [plano, setPlano] = useState({});
  const { id } = useParams();
  const [isConsultoriaAntes, setIsConsultoriaAntes] = useState();

  function enviarPlano(plano) {
    // window.location.href = "/pagamento/" + plano.id;
    props.history.push("/pagamento/" + plano.id);
    // setPlano(plano);
    // setIsOpen(true);
  }


  useEffect(() => {
    props.setLoading(true);
    getPlanos().then((data) => {
      setPlanos(data);
      props.setLoading(false);
      if (id) {
        setPlano(data.find((p) => p.id === parseInt(id)));
        setIsOpen(true);
      }
    });

    getCnsultoriaById(props.currentUser.id).then((data) => {
      setIsConsultoriaAntes(data);
      props.setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="container bodyFont mt-3">
        <GoBack link={"/modulos"} />
        <div className="row fundoHelpNao">
                {isOpen ? null : null}
                {plano ? null : null}
                <div className="col-5 mt-2">
                  <p className="escritoPena p-4">
                    Ah que pena :( <br /> No momento você não possui nenhum <br />{" "}
                    plano ativo. Comece um agora mesmo
                  </p>
                </div>
                {/* <div className="col-12 text-center my-3">
                  <PagseguroPaymatch
                    loadCurrentlyLoggedInUser={props.loadCurrentlyLoggedInUser}
                    plano={plano}
                    modulo={false}
                    setLoading={props.setLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </div> */}
                <div className="col-md-2 pjaNomeEmbaixo">Help Legis</div>
                <div className="m-1">
                  {planos.filter(p => p.titulo === "Consulta Individual").map(plano => {
                    return (
                      <section className="fundoPjaCard mt-3">
                        <h2 className="tituloCardPja p-3">
                          Help Legis - {plano.titulo}
                        </h2>
                        <div className="cardPreco mx-auto cardPrecoBorda">
                          <h4 className="text-center corPlano pt-4">
                            <span className="apartirDe">A partir de</span>
                            <br />
                            {(plano.valor/1).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                          </h4>
                        </div>
                        <button
                          type="button"
                          onClick={() => enviarPlano(plano)}
                          className="btn botaoNovoContratar mx-auto mt-4">
                          Começar agora
                        </button>
                      </section>
                  );
                  })}    
                </div>
                {/* <p className="paraPagar">Nosso sistema não armazena nenhum meio de pagamento. <br/> Todo processo de compra é feito pelo nosso parceiro Mercado Pago</p> */}
                {isConsultoriaAntes > 0 ? (
                  <>
                    <div className="col-12">
                      <Link to="/help-legis" className="btn buttonAtualizarListagem">
                        Ver minhas consultorias antigas
                      </Link>
                    </div>
                  </>
                ): null}
              </div>

              <div className="row">
                <h5 className="col-md-12 my-3">Confira nossos planos</h5>
                {planos.filter(plano => plano.titulo !== "Consulta Individual").map(p => {
                  return (
                    <div className="col-md-3">
                      {/* className={`container ${isFixed ? "fixed-bottom mb-5" : "my-2"}`} */}
                      <div
                        className={`${
                          p.titulo === "Ouro"
                            ? "cardNovoPlanosOuro"
                            : "cardNovoPlanos"
                        } `}
                        key={p.id}
                      >
                        {p.titulo === 'Ouro' ? (<><img src={vendido ? vendido : null} className="mx-1 maisVendido text-center" alt="botão mais vendido"/></>) : null }
                        <div className={``}>
                          <div className="">
                            <h2
                              className={`${
                                p.titulo === "Ouro"
                                  ? "tituloCardNovoOuro"
                                  : "tituloCardNovo"
                              } mt-3 pt-3`}
                            >
                              {p.titulo}
                            </h2>
                          </div>
                          <div className="text-center">
                            <p
                              className={`${
                                p.titulo === "Ouro"
                                  ? "paragrafoCardNovoOuro"
                                  : "paragrafoCardNovo"
                              }`}
                            >
                              {p.descricao}
                            </p>
                            <hr
                              className={`${
                                p.titulo === "Ouro" ? "hrClassOuro" : "hrClass"
                              }`}
                            />
                            <h4 className={`${
                                p.titulo === "Ouro" ? "corPlanoBranco" : "corPlano"
                              }`}>
                              {" "}
                              <span className="text-muted">{p.parcela}x</span>{" "}
                              {(p.valor / p.parcela).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h4>
                            <button
                              // onClick={() => {
                              //     setAtivo(true);
                              // }}
                              type="button"
                              className={`btn ${
                                p.titulo === "Ouro"
                                  ? "buttonContratarPacotesOuro"
                                  : "buttonContratarPacotes"
                              } mt-5`}
                              onClick={() => enviarPlano(p)}
                            >
                              Começar agora
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <p className="pDescPlanoNovo">
                          <small className="text-muted">
                            ***Total:{" "}
                            {(p.valor - p.valorDesconto).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </small>
                          <br />
                          <small className="text-muted">
                            {(p.valor / p.execucoes).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}{" "}
                            cada consultoria
                          </small>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
      </div>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
  );
};

export default Cotratar;
