import React from "react";
// import { Modal, Button } from "react-bootstrap";
// import logoAguia from "../assets/imagens/aguiaIlegis.png";
// import logoAguiaEscrito from "../assets/imagens/pjaLegis.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Notificacao from "./Notificacao";
// import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import "./header.css";
import logoNova from "../assets/imagens/ilegis-h.png";

const AppHeader = (props) => {

  return (
    <Navbar
      id="navH"
      bg="white"
      expand="lg"
      className="box-shadow no-radius robotR d-flex align-items-stretch pb-4 navAltura"
    >
      <Navbar.Brand href="#/" className="fundoHeader">
        {/* <img
          className="image-logo pl-md-2"
          src={logoAguia ? logoAguia : null}
          alt="logo iLegis"
        /> */}
        <img
          className="img-fluid imgNova mx-5"
          src={logoNova ? logoNova : null}
          alt="logo iLegis"
        />
      </Navbar.Brand>
      <h5 className="mt-5">Seja bem-vindo, {props.currentUser ? props.currentUser.nome.split(" ")[0] : ""}</h5>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* <Nav>
          <Nav.Link className="navSocial mt-2 mb-2 ml-3">
            <Notificacao usuario={props.currentUser} />
          </Nav.Link>
        </Nav> */}
        <Nav className="mx-auto text-center">
          {!props.authenticated && !props.currentUser ? (
            <>
              <Nav.Link href="#/login" className="links2">
                Login/Cadastre-se
              </Nav.Link>
            </>
          ) : (
            <>
            <Nav.Link className="navSocial mx-5">
              <Notificacao usuario={props.currentUser} />
            </Nav.Link>
            <NavDropdown
              className="linkNome px-1"
              title={
                props.currentUser ? props.currentUser.nome.split(" ")[0] : ""
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="#/perfil" className="mt-2 font-weight-bold">
                Perfil
              </NavDropdown.Item>
              <NavDropdown.Item href="#/pagamentos" className="font-weight-bold">
                Pagamentos
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={props.onLogout} className="colorSairMenu">
                Sair
              </NavDropdown.Item>
            </NavDropdown>
            </>  
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppHeader;
