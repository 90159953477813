import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Listagem from "../../componentes/Listagem";
import FormGroup from "../../componentes/form-group";
import { getModulos, salvarModulo } from "../../util/Api";

const esquemaModulo = Yup.object().shape({
  titulo: Yup.string().min(3, "Obrigatório").required("Obrigatório"),
  descricao: Yup.string()
    .min(5, "Poucos caracteres")
    .max(3001, "Muitos caracteres!")
    .required("Obrigatório"),
  valor: Yup.number().required("Obrigatório").min(1, "Obrigatório"),
  meses: Yup.number().min(0, "Obrigatório").nullable(),
  parcela: Yup.number().min(0, "Obrigatório").nullable(),
});

const Modulos = (props) => {
  const [modulos, setModulos] = useState({ content: [] });
  const [atualiza, setAtualiza] = useState(false);
  const formikRef = useRef();
  const [classBtn, setClassBtn] = useState("btn-success");

  const findPlanos = () => {
    props.setLoading(true);
    getModulos().then((data) => {
      setModulos({ content: data });
      props.setLoading(false);
    });
  };

  useEffect(() => {
    findPlanos();
    // eslint-disable-next-line
  }, []);

  const editarModulo = async (procedimento) => {
    await setAtualiza(true);
    setClassBtn("btn-info");
    formikRef.current.values.titulo = procedimento.titulo;
    formikRef.current.values.descricao = procedimento.descricao;
    formikRef.current.values.valor = procedimento.valor;
    formikRef.current.values.meses = procedimento.meses;
    formikRef.current.values.parcela = procedimento.parcela;
    formikRef.current.values.id = procedimento.id;
    await setAtualiza(false);
  };

  const salvarM = (formulario) => {
    salvarModulo(formulario)
      .then(() => {
        if (classBtn === "btn-success") {
          Alert.success("Modulo cadastrado com sucesso!");
        } else {
          Alert.success("Modulo alterado com sucesso!");
        }
        findPlanos();
        formulario.titulo = "";
        formulario.descricao = "";
        formulario.valor = 0;
        formulario.meses = 0;
        formulario.parcela = 0;
        formulario.id = null;
        setClassBtn("btn-success");
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  return (
    <>
      {classBtn !== "btn-success" ? (
        <Formik
          className="row"
          innerRef={formikRef}
          initialValues={{
            titulo: "",
            descricao: "",
            meses: "",
            valor: 0,
            parcela: 1,
            id: null,
          }}
          validationSchema={esquemaModulo}
          onSubmit={(values) => {
            salvarM(values);
          }}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              <div className="col-lg-12">
                {atualiza ? null : null}
                <div className="bs-component">
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup
                        label="Título: *"
                        htmlFor="inputTipoDeProcedimento"
                      >
                        <Field
                          type="text"
                          className={`form-control ${
                            errors.titulo && touched.titulo ? "invalid" : ""
                          }`}
                          name="titulo"
                        />
                        {errors.titulo && touched.titulo ? (
                          <span className="text-error">{errors.titulo}</span>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="col-lg-6">
                      <FormGroup label="Descrição: *" htmlFor="inputDescricao">
                        <Field
                          type="text"
                          className={`form-control ${
                            errors.descricao && touched.descricao
                              ? "invalid"
                              : ""
                          }`}
                          name="descricao"
                        />
                        {errors.descricao && touched.descricao ? (
                          <span className="text-error">{errors.descricao}</span>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup label="Valor: *" htmlFor="inputValor">
                        <Field
                          type="number"
                          step="0.01"
                          className={`form-control ${
                            errors.valor && touched.valor ? "invalid" : ""
                          }`}
                          name="valor"
                        />
                        {errors.valor && touched.valor ? (
                          <span className="text-error">{errors.valor}</span>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup label="Meses: *" htmlFor="inputValor">
                        <Field
                          type="number"
                          className={`form-control ${
                            errors.meses && touched.meses ? "invalid" : ""
                          }`}
                          name="meses"
                        />
                        {errors.meses && touched.meses ? (
                          <span className="text-error">{errors.meses}</span>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="col-lg-4">
                      <FormGroup label="Parcela: *" htmlFor="inputValor">
                        <Field
                          type="number"
                          className={`form-control ${
                            errors.parcela && touched.parcela ? "invalid" : ""
                          }`}
                          name="parcela"
                        />
                        {errors.parcela && touched.parcela ? (
                          <span className="text-error">{errors.parcela}</span>
                        ) : null}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="submit"
                      onClick={() => {
                        if (!isValid) {
                          Alert.warning("Preencha todos os campos destacados");
                        }
                      }}
                      className={`btn btn-success-novo mb-3 mr-3`}
                    >
                      <i className="fa fa-save"></i>{" "}
                      {classBtn === "btn-success" ? "Salvar" : "Editar"}
                    </button>
                  </div>  
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : null}
      <Listagem
        titulo={<h5>Módulos Cadastrados</h5>}
        tituloPlano
        valor
        descricao
        meses
        paginacao={modulos}
        botaoEditar={editarModulo}
      />
    </>
  );
};

export default Modulos;
