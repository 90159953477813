import React, { useState } from "react";
import * as Yup from "yup";
import { Row, Container, Modal, Button } from "react-bootstrap";
import { recuperarSenha } from "../../util/Api";
import { Formik, Form, Field } from "formik";
import Alert from "react-s-alert";
import { Link } from "react-router-dom";
import "./index.css";
import logo from "../../assets/imagens/ilegis-h.png";
// import back from "../../assets/imagens/login.jpeg";
import seta from "../../assets/imagens/icones/chevron.png";
import termos from "../../assets/imagens/icones/termos.png";
import suporte from "../../assets/imagens/icones/suporte.png";
import confirmada from "../../assets/imagens/icones/confirmada.png";


const loginValidation = Yup.object().shape({
  email: Yup.string().min(7, "Obrigatório").email().required("Obrigatório"),
});

const RedefinirSenhaEmail = (props) => {
  const teste =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  // const teste =
  //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [recuperada, setRecuperada] = useState(false);
  const [email, setEmail] = useState();
  const [ativo, setAtivo] = useState(false);
  const handleClose = () => setAtivo(false);



  const submit = (values) => {
    // console.log(values.email);
    if (!teste.test(values.email)) {
        Alert.warning("E-mail fora do padrão esperado!");
    }
    props.setLoading(true);
    recuperarSenha(values.email)
    .then(() => {
        Alert.info("E-mail de recuperação enviado!");
        props.setLoading(false);
        setRecuperada(true);
        setEmail(values.email);
    })
    .catch(() => {
        Alert.error("E-mail de recuperação não enviado!");
        props.setLoading(false);
    });
  };

  return (
    <div id="login">
      <Modal id="modCome" show={ativo} onHide={() => setAtivo(false)} size="xl">
        <Modal.Header>
          <Modal.Title className="text-center">CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE CONSULTORIA ONLINE, LICENÇA DE SOFTWARE DE PETICIONAMENTO AUTOMATIZADO E TERMO DE AUTORIZAÇÃO
           E CONSENTIMENTO DE USO SITE WWW.ILEGIS.COM.BR</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-justify">

          <p>De um lado <strong>LEGALIS SOLUÇÕES LTDA</strong>, pessoa jurídica inscrita no CNPJ 47.822.589/0001-77, com sede na rua João Pinheiro, 35, 
          centro, Alfenas/MG, CEP: 37.130-035, doravante denominada contratada ou “ILEGIS” e de outro o contratante que livremente assina este termo, 
          doravante denominada contratante celebram o presente contrato, que será regido conforme as cláusulas e condições a seguir expostas:</p>

          <h5><strong>DAS DISPOSIÇÕES INICIAIS</strong></h5>
          
          <p><strong>CLÁUSULA 1ª: </strong>O contratante declara ciência que os produtos são vinculados à marca iLegis,
          PJA Legis e Help Legis, no entanto, sabe que a razão social da empresa contratada é LEGALIS SOLUÇÕES LTDA, 
          sendo assim, boletos, notas fiscais, comprovantes de pagamento cartão de crédito/débito, 
          virão em nome de LEGALIS SOLUÇÕES, concordando o contratante.</p>

          <h5><strong>DO SISTEMA DE CONSULTORIA ONLINE – HELP LEGIS</strong></h5>

          <p><strong>CLÁUSULA 2ª:</strong> O sistema de consultoria on-line, denominado help legis, é um sistema que fornece ao advogado um apoio on line, 
          via sistema próprio no qual o usuário compra consultorias e recebe apoio de advogados. O usuário, necessariamente advogado, 
          compra uma consultaria e manda sua dúvida ou orientação jurídica. Os responsáveis pela empresa contratada responderão 
          com um parecer no prazo informado no site (24 horas para consultorias e 72 para teses e pareceres).</p>

          <p><strong>CLÁUSULA 3ª:</strong> A contratada não será obrigada a
          responder perguntas e conteúdos ofensivos, vexatórios, provocativos, de cunho político, 
          religioso ou outro tema que instigue polêmica, a critério da contratada.</p>

          <p><strong>CLÁUSULA 4ª:</strong> A contratada fornecerá e teses baseados em fundamentação teórica, não se responsabilizando pelo 
          sucesso ou aceitação dos pareceres por clientes ou órgãos do judiciário ou pela procedência das ações.</p>

          <p><strong>CLÁUSULA 5ª:</strong> A utilização dos pareceres é de uso exclusivo do contratante, não podendo fornecer para terceiros,
          sob pena de multa de R$ 10.000,00 (dez mil reais) devida à empresa contratada.</p>

          <p><strong>CLÁUSULA 6ª:</strong> O usuário poderá juntar pareceres nos seus processos, mediante citação da referida fonte e manutenção da identidade visual da empresa contratada. <br />
          <strong>Parágrafo único:</strong> A apresentação, juntada em processos, divulgação ou utilização sem a devida referenciação de autor incorre em plagio e violação de direitos autorais.</p>

          <p><strong>CLÁUSULA 7ª:</strong> No caso dos produtos da linha HELP LEGIS, o usuário compra as consultorias, obtendo retorno via sistema, não possuindo direito a uma “réplica” pela mesma consultoria.</p>

          <p><strong>CLÁUSULA 8ª:</strong> A contratação de consultorias por pacote deve ser utilizada num período de 12 meses. O saldo de consultorias não
          utilizadas será “zerado” ao cabo de doze meses, não sendo possível usar após mais de um ano da sua contratação. <br />
          <strong>Parágrafo único:</strong> O saldo de consultoria não utilizada não será reembolsado ao contratante.</p>

          <p><strong>CLÁUSULA 9ª:</strong> As consultorias do HELP LEGIS enquadram-se como prestação de serviços de profissional para profissional, 
          caracterizando prestação de serviços, não sendo o contratante considerado usuário final, portanto, não é considerado consumidor, para fins legais. <br /> 
          <strong>Parágrafo único:</strong> Por ser prestação de serviços de utilização instantânea e personalizada sob medida, não é garantido ao usuário desistir da 
          consulta contratada após a formalização da compra, não tendo a contratada obrigação de restituir valores.</p>

          <p><strong>CLÁUSULA 10ª:</strong> A contratada se compromete a restituir o valor da consulta em caso de área jurídica ou pergunta que não se 
          sentir à vontade ou confiante para formular parecer com segurança, a critério da contratada.</p>

          <p><strong>CLÁUSULA 11ª:</strong> São áreas de abrangência das consultorias do HELP LEGIS: direito trabalhista, b) processo trabalhista, c) direito cível, d)
          direito de família, e) direito imobiliário e registros públicos, f) direito do consumidor, g) direito constitucional, processo civil, h) previdenciário, 
          i) direito administrativo, j) cobranças, k) direito médico e da saúde, e direito contratual. <br />
          <strong>Parágrafo único:</strong> Soluções de consulta de área diversa às elencadas no caput se darão mediante aceitação da contratada.</p>

          <p><strong>CLÁUSULA 12ª:</strong> A contratada atua fornecendo pareceres e solução de consulta com autonomia profissional de seus pareceristas, 
          não obrigando a aceitação ou concordância do contratante ou de terceiros.</p>

          <p><strong>CLÁUSULA 13ª:</strong> Os pareceres e soluções de consulta da contratada não se obrigam a conter fundamentação de lei estadual, municipal ou posicionamento jurisprudencial
          de tribunal específico, sendo que a contratada fornecerá parecer com qualidade e fundamentação.</p>

          <p><strong>CLÁUSULA 14ª:</strong> Os contratantes concordam que a contratação dos produtos do help legis é considerada prestação de serviços de pareceristas, 
          serviços personalizados, não estando sujeitas a normas de direito do consumidor, sendo a contratação de pareceristas, serviços de autônomo, de 
          esfera trabalhista, excluído do CDC conforme art. 3º,§2º da Lei 8078/1990.</p>

          <h5><strong>DO SISTEMA DE PETICIONAMENTO AUTOMATIZADO</strong></h5>

          <p><strong>CLÁUSULA 15ª:</strong> O sistema de Peticionamento Judicial Automatizado da iLegis permite ao usuário uma consulta automatizada de fundamentação legal,
          doutrinária e jurisprudencial necessária para criar uma petição de qualidade.</p>

          <p><strong>CLÁUSULA 16ª:</strong> Ao fazer o cadastro no site, o contratante ganha sete dias de utilização gratuita do sistema. O cadastro no site não obrigará a contratação.</p>

          <p><strong>CLÁUSULA 17ª:</strong> A CONTRATADA deverá garantir o funcionamento do sistema online 24 horas por dia, sete dias por semana, independente de feriados ou recessos. <br />
          <strong>Parágrafo primeiro:</strong> Pausas para manutenções no sistema podem ocorrer, sendo que a contratada, preferencialmente, avisará ao contratante sempre que possível 
          sobre eventuais manutenções no sistema. <br />
          <strong>Parágrafo Segundo:</strong> Eventuais períodos de manutenção que ocasionarem instabilidade no sistema não serão ressarcidos pela contratada. Somente haverá restituição ao
          contratante se o sistema ficar fora do ar por mais de 7 (sete) dias no período de um mês. Nesta hipótese haverá restituição 
          proporcional ao período de instabilidade, não gerando cancelamento do contrato.</p>

          <p><strong>CLÁUSULA 18ª:</strong> A contratação por um ano não acarretará a renovação automática de contratação dos planos.</p>

          <p><strong>CLÁUSULA 19ª:</strong> É resguardado ao contratante o direito de arrependimento de sete dias, a partir da compra, hipótese na qual, a contratada restitui o valor integralmente pago pelo contratante.</p>

          <p><strong>CLÁUSULA 20ª:</strong> O login de usuário e senha são personalíssimos e intransferíveis. A cessão onerosa ou gratuita a qualquer título para terceiro representa ofensa contratual, 
          quebra de confiança e ma-fé, gerando para o infrator multa no valor de R$ 20.000,00 (vinte mil reais), servido este como título executivo.</p>

          <p><strong>CLÁUSULA 21ª:</strong> A contratada se compromete a atualizar o banco de dados do sistema frequentemente, não se responsabilizando, contudo, por leis e atos normativos ausentes no sistema.</p>

          <p><strong>CLÁUSULA 22ª:</strong> Com a compra do PJA LEGIS o contratante adquire o direito de usar o sistema pelo período de um ano,
          sendo vedada a renovação automática do contrato, renovando somente por expressa vontade do contratante.</p>

          <h5><strong>DO ASSINADOR DIGITAL</strong></h5>

          <p><strong>CLÁUSULA 23ª:</strong> A contratada disponibiliza um assinador digital, através do qual é possível a assinatura de documentos por sistema online. 
          As assinaturas são cobradas por documento, não tendo acréscimo de valor por número de assinaturas ou quantidade de páginas no documento. <br /> 
          <strong>Parágrafo único:</strong> A contratada emite página de autenticação garantindo as assinaturas. No entanto, não se responsabiliza por documentos assinados 
          por terceiros que tenha acesso a token, certificado digital, senha ou e-mail dos assinantes.</p>

          <p><strong>CLÁUSULA 24ª:</strong> A lei n. 14.063/2020, o Decreto nº 10.543/2020 e a Medidas Provisórias números 2200-2 garantem e asseguram o uso de assinaturas eletrônicas do Brasil, 
          sendo que o assinador da iLegis obedece a tais atos normativos. No entanto, a contratada não se responsabiliza por órgãos do judiciário, Ministério Público, órgãos públicos, cartórios 
          ou pessoas que não aceitarem o meio eletrônico como hábil para comprovar autenticidade de assinaturas.</p>

          <p><strong>CLÁUSULA 25ª:</strong> A CONTRATADA deverá garantir o funcionamento do sistema online 24 horas por dia, sete dias por semana, independente de feriados ou recessos. <br />
          <strong>Parágrafo primeiro:</strong> Pausas para manutenções no sistema podem ocorrer, sendo que a contratada, preferencialmente, avisará ao contratante sempre que possível 
          sobre eventuais manutenções no sistema</p>

          <p><strong>CLÁUSULA 26ª:</strong> Por ser prestação de serviços de utilização instantânea e personalizada sob medida, não é garantido ao usuário desistir do documento assinado,
          visto que já se concretizou a assinatura do documento, consolidando o ato.</p>

          <h5><strong>DAS DISPOSIÇÕES GERAIS</strong></h5>

          <p><strong>CLÁUSULA 27ª:</strong> O contratante declara ciência e concordância que a contratada não atua como advogada. A atuação da contratada se dá via prestação de serviços a advogados, 
          com apoio ao mesmo e com estímulo a qualificação profissional.</p>

          <p><strong>CLÁUSULA 28ª:</strong> Em quaisquer dos produtos ofertados, a contratada não garante sucesso das teses ou êxitos em processos. 
          A contratada atua auxiliando o advogado contratado, num apoio jurídico. <br />
          <strong>Parágrafo primeiro:</strong> A total responsabilidade dos processos é dos advogados contratantes. <br />
          <strong>Parágrafo segundo:</strong> A contratada não atua como advogado, não podendo se responsabilizar por protocolo de petições, falhas em sistema de peticionamento dos tribunais, prazos, dentre outros.</p>

          <p><strong>CLÁUSULA 29ª:</strong> A contratada se obriga a cumprir a Lei Geral de Proteção de Dados (Lei. 13.709/2018), se comprometendo a manter sob sigilo os dados de seus usuários. <br />
          <strong>Parágrafo único:</strong> O usuário está ciente que, anuindo com este contrato, poderá receber anúncios, e-mails, promoções por parte da contratada.</p>

          <p><strong>CLÁUSULA 30ª :</strong> Os dados do contratante serão armazenados no sistema para fins de contratação, login, pagamentos e acessos aos produtos e plataforma, 
          sendo vedada a divulgação de qualquer dado em qualquer meio. <br />
          <strong>Parágrafo único:</strong> O contratante autoriza participar de campanhas publicitárias, divulgações, sorteios e receber informativos, sendo respeitado o sigilo de nome.</p>

          <p><strong>CLÁUSULA 31ª:</strong> O preço dos produtos é determinado pela ILEGIS, sendo este o que for vinculado e anunciado no site.</p>

          <p><strong>CLÁUSULA 32ª:</strong> Preços promocionais, ofertas e cupons de desconto utilizados para contratação não serão automaticamente aplicados na renovação de contratos,
          sendo que novas condições podem ser ofertadas para renovação de planos e contratação de novos pacotes.</p>

          <p><strong>CLÁUSULA 33ª:</strong> A contratada poderá encerrar promoção e descontos a qualquer momento, sendo respeitado, claro, os contratos já celebrados, mantendo os valores acordados.</p>

          <p><strong>CLÁUSULA 34ª:</strong> A precificação fica a critério da contratada não sendo considerada preço abusivo o aumento de preço ou encerramento de promoções e descontos. 
          Da mesma forma, não será considerado preço vil a adoção de promoções e politicas de desconto.</p>

          <p><strong>CLÁUSULA 35ª:</strong> A plataforma e site podem passar por manutenções periódicas e preventivas, que podem acarretar oscilação de acesso. 
          Sempre que possível a contratada irá agendar tais manutenções e avisará usuários sobre qualidade do acesso.</p>

          <p><strong>CLÁUSULA 36ª:</strong> A contratada não se responsabiliza pela manutenção da página no ar diante atos de terceiros, que não dependem de sua vontade, nada podendo fazer para impedir,
          tais, como, quedas de servidor, ataques hacker ou terroristas. <br />
          <strong>Parágrafo:</strong> A contratada não se responsabiliza por oscilações de sinais de rede,  quedas de energia ou qualquer falha de computador, smartphone ou máquina similar do contratante.</p>

          <p><strong>CLÁUSULA 37ª:</strong> A contratada garante a segurança da plataforma e dados dos usuários.</p>

          <p><strong>CLÁUSULA 38ª:</strong> Este contrato e termo de termo de uso deverá interpretado com o que for disposto no site <a href="https://www.ilegis.com.br" target="_blank" rel="noopener noreferrer">www.ilegis.com.br</a>.</p>

          <p><strong>CLÁUSULA 39ª:</strong> O contratante está ciente que a forma de pagamento será online, concordando com as formas de pagamento do site. <br />
          <strong>Parágrafo único:</strong> A contratada deverá manter sigilo dos dados do contratante.</p>

          <p><strong>CLÁUSULA 40ª:</strong> Serão solicitados os seguintes dados do contratante: nome completo, número de CPF, número de telefone com DDD, endereço de e-mail, endereço completo com CEP, 
          cidade, bairro e Estado da Federação e senha. Assim sendo, o contratante concorda em fornecer tais dados à contratada, que por sua vez, deverá guarda sigilo dos mesmos. <br />
          <strong>Parágrafo único:</strong> A contratada deverá manter sob sigilo os dados do contratante, não podendo ceder para quem for, a qualquer título, ainda que gratuito.</p>

          <p><strong>CLÁUSULA 41ª:</strong> O contratante está ciente que poderá receber ligações, mensagens de texto SMS, mensagens de whatsapp e e-mails com anúncios, promoções, divulgações de produtos da contratada.</p>

          <p><strong>CLÁUSULA 42ª:</strong> É de responsabilidade do contratante o preenchimento correto dos dados no momento de cadastro na plataforma.</p>

          <p><strong>CLÁUSULA 43ª:</strong> Para compra de produtos, renovação de pacote ou para usar dos produtos (PJA Legis, Help Legis ou assinador digital) é necessário fazer o login na plataforma.</p>

          <p><strong>CLÁUSULA 44ª:</strong> É obrigação do contratante memorizar e proteger sua senha, evitando o acesso de terceiros.</p>

          <p><strong>CLÁUSULA 45ª:</strong> A plataforma permite o recadastramento de nova senha.</p>

          <p><strong>CLÁUSULA 46ª:</strong> Cada cliente só poderá efetuar um cadastro por CPF.</p>

          <p><strong>CLÁUSULA 47ª:</strong> É obrigação do contratante usar a plataforma respeitando ética, bons costumes, lei e ordenamento vigentes.</p>

          <p><strong>CLÁUSULA 48ª:</strong> É obrigação da contratada auxiliar o cliente contratante, prestando o suporte necessário em caso de dúvidas de como usar o site e produtos.</p>

          <p><strong>CLÁUSULA 49ª:</strong> A contratada atenderá o cliente via ligação, e-mail e redes sociais constantes no site.</p>

          <p><strong>CLÁUSULA 50ª:</strong> É obrigação da contratada orientar e explicar para o cliente como usar cada produto e serviço vendido no site.</p>

          <p><strong>CLÁUSULA 51ª:</strong> É obrigação da contratada disponibilizar uma plataforma segura.</p>

          <p><strong>CLÁUSULA 52ª:</strong> É obrigação da contratada fornecer nota fiscal dos produtos e serviços vendidos no site.</p>

          <p><strong>CLÁUSULA 53ª:</strong> Os produtos da iLegis são usados de forma online, pelo próprio site. A contratada não solicita a instalação de qualquer software no computador do cliente.</p>

          <p><strong>CLÁUSULA 54ª:</strong> A plataforma possui registro da marca.</p>

          <p><strong>CLÁUSULA 55ª:</strong> A contratada não se responsabiliza por links externos.</p>

          <p><strong>CLÁUSULA 56ª:</strong> Caso haja publicidade no site da iLegis, o teor dos anúncios é de responsabilidade dos anunciantes.</p>

          <p><strong>CLÁUSULA 57ª:</strong> Em caso de pagamento via boleto, se encerrar a data de vencimento sem o devido pagamento, será considerado como desistência da compra.</p>

          <p><strong>CLÁUSULA 58ª:</strong> Qualquer outra forma de pagamento ou licença dos produtos, de forma alheia à anunciada no site, 
          deverá ser negociada diretamente com a contratada através do e-mail <a href="mailto:vendas@ilegis.com.br">vendas@ilegis.com.br</a>.</p>

          <p><strong>CLÁUSULA 59ª:</strong> Cupons de desconto possuem data de vencimento, devendo o contratante se atentar para os períodos de validade.</p>

          <p><strong>CLÁUSULA 60ª:</strong> Em caso de cancelamento de pedidos ou estorno, entrar em contato com contratada através do e-mail <a href="mailto:vendas@ilegis.com.br">vendas@ilegis.com.br</a>. <br />
          <strong>Parágrafo único:</strong> estorno de vendas com pagamento Á boleto somente serão realizados após a compensação deste, que poderá demorar 3 dias úteis.</p>

          <p><strong>CLÁUSULA 61ª:</strong> A troca de um produto do help legis para outro ou para licença do PJA Legis deverá ser negociada diretamente com a contratada através do e-mail <a href="mailto:adm@ilegis.com.br">adm@ilegis.com.br</a>.</p>

          <p><strong>CLÁUSULA 62ª:</strong> A política de privacidade é um documento à parte e constante no site.</p>

          <p><strong>CLÁUSULA 63ª:</strong> Fica eleito o foro da Comarca de Alfenas/MG para dirimir quaisquer dúvidas em relação a este contrato, com renuncia expressa a qualquer outro.</p>

          <h4 className="text-center"><strong>Declaro que li, compreendi e aceito e termo acima.</strong></h4>
          
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-info" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Container>
        <Row className="d-flex align-items-center">
          <section className="col-md-4 float-left">
            <img
              className="img-fluid imgLogoTela mx-3"
              src={logo ? logo : null}
              alt="Logo"
            ></img>
            {recuperada ? (
                <>
                    <div className="text-center mt-5">
                        <img
                        className="mx-3"
                        src={confirmada ? confirmada : null}
                        alt="Confirmada"
                        ></img>
                        <p className="paragrafoOk">
                        Por favor, verifique seu e-mail: <span className="corEmail">{email}</span>, siga as instruções e faça login novamente
                        </p>
                        <Link to="/login" className="btn btn-block btnEntrarNovo mt-2 btn-lg">
                            Fazer login novamente
                            <img
                            className="mx-3"
                            src={seta ? seta : null}
                            alt="Seta"
                            ></img>
                        </Link>
                    </div>
                </>
            ) : 
            (
                <>
                    <h3 className="titLogin mb-2 mt-4">Recuperar senha</h3>
                    <form>
                    <Formik
                        initialValues={{
                        email: "",
                        }}
                        validationSchema={loginValidation}
                        onSubmit={(values) => {
                        submit(values);
                        }}
                    >
                        {({ errors, touched, isValid }) => (
                        <Form className="p-2">
                            <div className="form-item">
                            {/* <label className="tituloLabel">E-mail: </label> */}
                                <p className="labels">E-mail</p>
                                <Field
                                aria-label="Usuário"
                                aria-describedby="basic-addon1"
                                type="email"
                                name="email"
                                required="required"
                                placeholder="digite seu email"
                                className={`form-control ${
                                    errors.email && touched.email ? "invalid" : ""
                                }`}
                                />
                            <button
                                type="submit"
                                onClick={() => {
                                if (!isValid) {
                                    Alert.warning("Preencha todos os campos destacados");
                                }
                                }}
                                className="btn btn-block btnEntrarNovo mt-2 btn-lg"
                            >
                                Solicitar recuperação
                                <img
                                className="mx-3"
                                src={seta ? seta : null}
                                alt="Seta"
                                ></img>
                            </button>
                            </div>
                        </Form>
                        )}
                    </Formik>
                    </form>
                </>
            )}
            <div className="mt-4">
              <div className="float-left">
                  <p className="footLogCad curs" onClick={() => {
                    setAtivo(true);
                    }}>
                    <img className="mx-1" src={termos ? termos : null} alt="Termos"></img>
                    Termos de uso
                  </p>
              </div>
              <div className="float-right">
                  <a className="footLogCad" href="mailto:suporte@ilegis.com.br">
                    <img className="mx-1" src={suporte ? suporte : null} alt="Suporte"></img>
                    Suporte
                  </a>
              </div>
            </div>  
          </section>
          <section className="col-md-8 imgBack">
            {/* <img className="img-fluid imgLogin" src={back ? back : null} alt="Logo"></img> */}
          </section>
        </Row>
      </Container>  
    </div>
  );
};

export default RedefinirSenhaEmail;
