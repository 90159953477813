import React, { useState } from "react";
import Alert from "react-s-alert";
import { Formik, Form, Field } from "formik";
import { Container } from "react-bootstrap";
import * as Yup from "yup";
import { buscarCep, updateUsuario, updateSenha } from "../../util/Api";
import GoBack from "../../componentes/goBack";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import "./index.css";

const contatoEsquema = Yup.object().shape({
  nome: Yup.string().min(6, "Obrigatório").required("Obrigatório"),
  cpfCnpj: Yup.string()
    .min(14, "Mínimo de 14 caracteres")
    .max(18, "Máximo de 18 caracteres")
    .required("Obrigatório"),
  email: Yup.string()
    .min(3, "Poucos caracteres")
    .max(100, "Muitos caracteres!")
    .email("Obrigatiro")
    .required("Obrigatório"),
  telefone: Yup.string()
    .min(10, "Poucos caracteres")
    .max(20, "Muitos caracteres!")
    .required("Obrigatório"),
  cep: Yup.string()
    .min(9, "Poucos caracteres")
    .max(10, "Muitos caracteres!")
    .required("Obrigatório"),
  logradouro: Yup.string()
    .min(4, "Poucos caracteres")
    .max(150, "Muitos caracteres!")
    .required("Obrigatório"),
  cidade: Yup.string()
    .min(5, "Poucos caracteres")
    .max(50, "Muitos caracteres!")
    .required("Obrigatório"),
  estado: Yup.string()
    .min(2, "Poucos caracteres")
    .max(2, "Muitos caracteres!")
    .required("Obrigatório"),
  numero: Yup.string()
    .min(1, "Poucos caracteres")
    .max(10, "Muitos caracteres!")
    .required("Obrigatório"),
  bairro: Yup.string()
    .min(3, "Poucos caracteres")
    .max(50, "Muitos caracteres!")
    .required("Obrigatório"),
});

const contatoEsquemaSenhaNova = Yup.object().shape({
  senha: Yup.string()
    .min(6, "Poucos caracteres")
    .max(30, "Muitos caracteres!")
    .required("Obrigatório")
    .matches(/^.*(?=.{8,}).*$/, "Coloque no mínimo 8 caracteres")
    .matches(/^.*((?=.*[A-Z]){1}).*$/, "Coloque um caractere Maíusculo")
    .matches(/^.*((?=.*[a-z]){1}).*$/, "Coloque um caractere Mínusculo")
    .matches(/[^a-zA-Z 0-9]+/g, "Coloque um caractere especial"),
  senhaAtual: Yup.string().required("Obrigatório"),
});

const Perfil = (props) => {
  const { setLoading, currentUser } = props;

  const [senha, setSenha] = useState();
  const Show = "fa fa-eye";
  const Hide = "fa fa-eye-slash";
  const [icon, setIcon] = useState(Show);
  const [type, setType] = useState("password");
  const [isVisible, setIsVisible] = useState(false);

  function handleVisibility() {
    if (isVisible === false) {
      setIcon(Hide);
      setType("text");
      setIsVisible(true);
    } else {
      setIcon(Show);
      setType("password");
      setIsVisible(false);
    }
  }

  const handleSubmitSenha = (value) => {
    if (value.senha !== senha) {
      Alert.error("Ops, repita a senha corretamente");
      return;
    }
    setLoading(true);
    updateSenha({senhaAtual: value.senhaAtual, novaSenha: value.senha})
      .then(() => {
        setLoading(false);
        Alert.info("Senha alterada com sucesso!");
        props.history.push("/login");
      })
      .catch(({response}) => {
        setLoading(false);
        Alert.error(
          (response && response.data) ||
            "Desculpe, ocorreu um erro durante a alteração da sua senha, por favor tente novamente!"
        );
      });
  };

  function mphone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const handleSubmit = (value) => {
    // if (value.senha !== senha) {
    //   Alert.error("Ops, repita a senha corretamente");
    //   return;
    // }
    setLoading(true);
    updateUsuario(value)
      .then(() => {
        setLoading(false);
        Alert.info("Usuário alterado com sucesso!");
        props.history.push("/login");
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(
          (error && error.message) ||
            "Desculpe, ocorreu um erro durante a alteração dos dados, por favor tente novamente!"
        );
      });
  };
  return (
    <div className="bg-light">
      <Container className="pt-5">
        <GoBack link={"/modulos"} />
        <div className="row mb-2">
            <h4 className="col-md-10">Perfil</h4>
            <div className="col-md-1">
                <a href="#/modulos" className="btn btn-layout float-right">Voltar</a>
            </div>
        </div>
        <section className="pt-3">
            <div className="dadosUsu bg-branco mb-1 mx-auto">
              <h5 className="my-2 ml-2">
                <span>Dados</span> do Usuário
              </h5>
            </div>
            <div className="bg-branco">
              <Formik
              className="col-12"
              initialValues={{
                ...currentUser,
                cidade: currentUser.municipio.nome,
                estado: currentUser.municipio.codigoEstado,
              }}
              validationSchema={contatoEsquema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                setFieldError,
                isValid,
              }) => (
                <Form className="container-fluid">
                  <div className="row mx-2 my-2">
                    <div className="col-md-6 px-1 py-1">
                      <div className="form-group">
                        <label>Nome:</label>
                        <Field
                          type="text"
                          name="nome"
                          placeholder="digite seu nome completo"
                          className={`form-control ${
                            errors.nome && touched.nome ? "invalid" : ""
                          }`}
                        />
                        {errors.nome && touched.nome ? (
                          <span className="error-text">{errors.nome}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 px-1 py-1">
                      <div className="form-group">
                        <label>CPF/CNPJ:</label>
                        <Field
                          type="text"
                          name="cpfCnpj"
                          placeholder="Ex.: 000.000.000-00"
                          className={`form-control`}
                          value={values.cpfCnpj}
                          disabled={true}
                        />
                        {errors.cpfCnpj && touched.cpfCnpj ? (
                          <span className="error-text">{errors.cpfCnpj}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 px-1 py-1">
                      <div className="form-group">
                        <label>E-mail</label>
                        <Field
                          type="email"
                          name="email"
                          placeholder="nome@exemplo.com"
                          className={`form-control ${
                            errors.email && touched.email ? "invalid" : ""
                          }`}
                          disabled={true}
                        />
                        {errors.email && touched.email ? (
                          <span className="error-text">{errors.email}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 px-1 py-1">
                      <div className="form-group">
                        <label>Telefone:</label>
                        <Field
                          type="text"
                          name="telefone"
                          placeholder="(xx) xxxxx-xxxx"
                          value={values.telefone}
                          onChange={(e) =>
                            setFieldValue("telefone", mphone(e.target.value))
                          }
                          className={`form-control ${
                            errors.email && touched.email ? "invalid" : ""
                          }`}
                        />
                        {errors.email && touched.email ? (
                          <span className="error-text">{errors.email}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 px-1 py-1">
                      <div className="form-group">
                        <label>CEP:</label>
                        <Field
                          type="text"
                          name="cep"
                          placeholder="cep ex. xxxxx-xxx"
                          minlength="8"
                          maxlength="15"
                          value={values.cep}
                          onChange={(e) => {
                            if (e.target.value.length >= 8) {
                              setFieldValue("cep", e.target.value);
                              buscarCep(e.target.value).then((data) => {
                                setFieldValue("cep", data.cep);
                                setFieldValue("logradouro", data.logradouro);
                                setFieldValue("bairro", data.bairro);
                                setFieldValue("estado", data.uf);
                                setFieldValue("cidade", data.localidade);
                                setFieldValue("municipio", {
                                  codigoIbge: data.ibge,
                                });
                              });
                            } else {
                              setFieldValue("cep", e.target.value);
                            }
                          }}
                          className={`form-control ${
                            errors.email && touched.email ? "invalid" : ""
                          }`}
                        />
                        {errors.email && touched.email ? (
                          <span className="error-text">{errors.email}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-1 px-1 py-1">
                      <div className="form-group">
                        <label>Estado:</label>
                        <Field
                          type="text"
                          name="estado"
                          placeholder="ex. MG"
                          className={`form-control ${
                            errors.estado && touched.cidestadoade ? "invalid" : ""
                          }`}
                        />
                        {errors.estado && touched.estado ? (
                          <span className="error-text">{errors.estado}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-2 px-1 py-1">
                      <div className="form-group">
                        <label>Número:</label>
                        <Field
                          type="text"
                          name="numero"
                          placeholder="ex. 1"
                          className={`form-control ${
                            errors.numero && touched.numero ? "invalid" : ""
                          }`}
                        />
                        {errors.numero && touched.numero ? (
                          <span className="error-text">{errors.numero}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 px-1 py-1">
                      <div className="form-group">
                        <label>Bairro:</label>
                        <Field
                          type="text"
                          name="bairro"
                          placeholder="bairro"
                          className={`form-control ${
                            errors.bairro && touched.bairro ? "invalid" : ""
                          }`}
                        />
                        {errors.bairro && touched.bairro ? (
                          <span className="error-text">{errors.bairro}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-3 px-1 py-1">
                      <div className="form-group">
                        <label>Cidade:</label>
                        <Field
                          type="text"
                          name="cidade"
                          placeholder="cidade"
                          className={`form-control ${
                            errors.cidade && touched.cidade ? "invalid" : ""
                          }`}
                        />
                        {errors.cidade && touched.cidade ? (
                          <span className="error-text">{errors.cidade}</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 px-1 py-1">
                      <div className="form-group">
                        <label>Logradouro:</label>
                        <Field
                          type="text"
                          name="logradouro"
                          placeholder="logradouro"
                          className={`form-control ${
                            errors.logradouro && touched.logradouro
                              ? "invalid"
                              : ""
                          }`}
                        />
                        {errors.logradouro && touched.logradouro ? (
                          <span className="error-text">{errors.logradouro}</span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="col-md-6 px-1 py-1">
                      <Field
                        type="password"
                        name="senha"
                        placeholder="Senha"
                        className={`form-control ${
                          errors.senha && touched.senha ? "invalid" : ""
                        }`}
                      />
                      {errors.senha && touched.senha ? (
                        <span className="error-text">{errors.senha}</span>
                      ) : null}
                    </div>
                    <div className="col-md-6 px-1 py-1">
                      <input
                        type="password"
                        placeholder="Confirmar senha"
                        onChange={(e) => setSenha(e.target.value)}
                        className={`form-control ${
                          touched.senha && values.senha !== senha ? "invalid" : ""
                        }`}
                      />
                      {touched.senha && values.senha !== senha ? (
                        <span className="error-text">Senhas não conferem</span>
                      ) : null}
                    </div> */}
                    <div className="col-md-12 px-1 text-right">
                      <br />
                      <button
                        type="submit"
                        onClick={() => {
                          if (!isValid) {
                            Alert.warning("Preencha todos os campos destacados!");
                          }
                        }}
                        className="btn-alterar-novo"
                      >
                        Salvar alterações
                      </button>
                    </div>
                    <div className="col-md-4 px-1 py-1">
                      <br />
                      {/* <button
                        type="reset"
                        className="btn btn-block btn-outline-danger"
                      >
                        Limpar
                      </button> */}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="alterarSenha bg-branco mt-1 mx-auto">
            <div className="dadosUsu bg-branco my-1 mx-auto">
              <h5 className="my-2 ml-2">
                <span>Alterar</span> senha
              </h5>
            </div>
                <Formik
                  className="col-12"
                  initialValues={{
                    senhaAtual: "",
                    senha: "",
                  }}
                  validationSchema={contatoEsquemaSenhaNova}
                  onSubmit={(values) => {
                    handleSubmitSenha(values);
                  }}
                >
                {({
                  errors,
                  touched,
                  values,
                  isValid,
                }) => (
                <Form className="container-fluid">
                  <div className="mx-2 my-3">
                      <div className="col-md-5">
                        <label>Senha atual: </label>
                        <Field
                          type="password"
                          name="senhaAtual"
                          placeholder="digite sua senha atual"
                          className={`form-control ${
                            errors.senhaAtual && touched.senhaAtual
                              ? "invalid"
                              : ""
                          }`}
                        />
                        {errors.senhaAtual && touched.senhaAtual ? (
                          <span className="error-text">{errors.senhaAtual}</span>
                        ) : null}
                      </div>
                      <div className="row mleft mt-3">
                        <div className="mt-2 col-md-5">
                          <label>Nova senha: </label>
                          <div className="input-group">
                            <Field
                              type={type}
                              name="senha"
                              placeholder="digite sua nova senha"
                              className={`form-control ${
                                errors.senha && touched.senha ? "invalid" : ""
                              }`}
                            />

                            <div
                              className="input-group-prepend tipo2"
                              onClick={handleVisibility}
                            >
                              <span className="input-group-text" id="basic-addon2">
                                <span className={`tipo ${icon}`}></span>
                              </span>
                            </div>
                          </div>
                          {errors.senha && touched.senha ? (
                            <span className="error-text">{errors.senha}</span>
                          ) : null}
                        </div>

                        <div className="mt-2 col-md-5">
                          <label>Confirme sua nova senha: </label>
                          <input
                            type="password"
                            placeholder="digite novamente sua nova senha"
                            onChange={(e) => setSenha(e.target.value)}
                            className={`form-control  ${
                              touched.senha && values.senha !== senha
                                ? "invalid"
                                : ""
                            }`}
                          />
                          {touched.senha && values.senha !== senha ? (
                            <span className="error-text">Senhas não conferem</span>
                          ) : null}
                        </div>
                        <div className="col-md-2">
                          <button
                            type="submit"
                            onClick={() => {
                              if (!isValid) {
                                Alert.warning("Preencha todos os campos!");
                              }
                            }}
                            className="btn-alterar-senha-novo"
                          >
                            Salvar senha
                          </button>
                        </div>
                      </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </Container>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
  );
};

export default Perfil;
