import React from "react";
import { Link } from "react-router-dom";
import "./card.css";

const Card = (props) => {
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
      {props.redirect ? (
        <a href={props.uri} target="_blanck">
          <div className="card text-center pt-5 pb-5">
              <img
                className="img-responsive imgCard mx-auto"
                src={`${props.icon}`}
                alt="logo iLegis"
              />
            <span className="mt-4 font">{props.titulo}</span>
          </div>
        </a>
      ) : (
        <Link className="no-decoration" to={props.uri}>
          <div className="card text-center pt-5 pb-5">
              <img
                className="img-responsive imgCard mx-auto"
                src={`${props.icon}`}
                alt="logo iLegis"
              />
            <span className="mt-4 font">{props.titulo}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Card;
