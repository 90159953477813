import React, { useState, useEffect } from "react";
import Alert from "react-s-alert";
import AppHeader from "./componentes/AppHeader";
import PrivateRoute from "./componentes/PrivateRoute";
import { Route, Switch, Router } from "react-router-dom";
import { createHashHistory } from "history";
import Loader from "react-loader-spinner";

import { getCurrentUser } from "./util/Api";
import { ACCESS_TOKEN } from "./constantes";

import Login from "./paginas/Login";
import Signup from "./paginas/Signup";
import Modulos from "./paginas/modulos";
import Opcao from "./paginas/OpcaoTeste"
import Perfil from "./paginas/Perfil";
import Pagamentos from "./paginas/Pagamentos";
import Contratar from "./paginas/Contratar";
import ContratarModulo from "./paginas/ContratarModulo";
import Cadastros from "./paginas/Cadastros";
import Consultorias from "./paginas/Consultorias";
import Consultoria from "./paginas/Consultoria";
import RedefinirSenha from "./paginas/RedefinirSenha";
import ConfirmarEmail from "./paginas/ConfirmarEmail";
import Dashboard from "./paginas/Dashboard";
// import Conteudos from "./paginas/Conteudos";
// import Conteudo from "./paginas/Conteudo";
// import Contato from "./paginas/Contato";
// import Noticias from "./paginas/Noticias";
// import Artigos from "./paginas/Artigos";
// import Blog from "./paginas/ListaBlog";
// import Blog from "./paginas/BlogRss";
// import CadastroPagina from "./paginas/Blog";
// import CadastroPaginaNova from "./paginas/Blog/nova";
// import Pagina from "./paginas/pagina";
// import Podcast from "./paginas/Podcast";
import Usuarios from "./paginas/Usuarios";
// import QuemSomos from "./paginas/QuemSomos";
// import Comentarios from "./paginas/Comentarios";
// import TodosComentarios from "./paginas/TodosComentarios";
// import Palestras from "./paginas/palestras";
import ModuloPago from "./paginas/ModulosPago";
import Pagamento from "./componentes/Pagamento.jsx";
import PagamentoModulo from "./componentes/PagamentoModulo.jsx";
import PjaCadastro from "./paginas/PjaCadastro";
import Texto from "./paginas/Texto";
import TextoNovo from "./paginas/Texto/novo";
import TextoNovoEdit from "./paginas/Texto/edit";
// import Assinador from "./paginas/CertificadoAssinatura/Assinador";
import Pacotes from "./paginas/Pacotes";
import HelpLegis from "./paginas/HelpLegis";
import RedefinirSenhaEmail from "./paginas/RedefinirSenhaEmail";
import Cupons from "./paginas/Cupons";
import Modelos from "./paginas/Texto/Modelos"
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";

const history = createHashHistory();

const rotasAdm = [
  "/dashboard",
  "/pacotes",
  "/conteudos",
  "/usuarios",
  "/comentarios",
  "/cadastroPja",
  "/cupons",
];

const Routes = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [apareceRodape, setApareceRodape] = useState(true);

  const loadCurrentlyLoggedInUser = (pagina) => {
    if(pagina === undefined){
      pagina = "/modulos";
    }
    setLoading(true);

    getCurrentUser()
      .then((data) => {
        setAuthenticated(true);
        setCurrentUser(data);
        setLoading(false);
        history.push(pagina);
      })
      .catch((error) => {
        localStorage.removeItem(ACCESS_TOKEN);
        setLoading(false);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    setAuthenticated(false);
    setCurrentUser(null);
    Alert.info("Logout efetuado com sucesso!");
  };

  // useEffect(() => {
  //   loadCurrentlyLoggedInUser();
  // }, []); 

  useEffect(() => {
    if (
      rotasAdm.includes(history.location.pathname) &&
      (!currentUser || currentUser.nivel === "CLIENTE")
    ) {
      if (!currentUser) {
        history.push("/login");
      }
      history.push("/modulos");
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  return (
    <Router history={history}>
      {loading ? (
        <div className="loading">
          <Loader
            type="BallTriangle"
            color="#00BFFF"
            height={200}
            width={200}
          />
        </div>
      ) : null}
      { authenticated ? (
        <AppHeader
        currentUser={currentUser}
        authenticated={authenticated}
        onLogout={handleLogout}
        history={history}
      /> ) 
      : null
      }
      <Route
          exact
          path="/login"
          render={(props) => (
            <Login
              setLoading={setLoading}
              loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
              authenticated={authenticated}
              {...props}
            />
          )}
      />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Login
              setLoading={setLoading}
              loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
              authenticated={authenticated}
              {...props}
            />
          )}
        ></Route>
        <Route
          exact
          path="/signup"
          render={(props) => (
            <Signup
              setLoading={setLoading}
              authenticated={authenticated}
              {...props}
            />
          )}
        />
        <Route
          path="/redefinirSenha/:hash"
          exact
          render={(props) => (
            <RedefinirSenha setLoading={setLoading} {...props} />
          )}
        />
        <Route
          path="/redefinirSenha"
          exact
          render={(props) => (
            <RedefinirSenhaEmail setLoading={setLoading} {...props} />
          )}
        />
        {/* <Route
          path="/blog"
          exact
          render={(props) => (
            <Blog setLoading={setLoading} {...props} tipo="BLOG" />
          )}
        /> */}
        {/* <Route
          path="/quemSomos"
          exact
          render={(props) => (
            <QuemSomos setLoading={setLoading} {...props} tipo="QuemSomos" />
          )}
        /> */}
        {/* <Route
          path="/todosComentarios"
          exact
          render={() => (
            <TodosComentarios setLoading={setLoading} tipo="TodosComentarios" />
          )}
        /> */}
        {/* <Route
          path="/podcasts"
          exact
          render={(props) => <Podcast setLoading={setLoading} {...props} />}
        />
        <Route
          path="/palestras"
          exact
          render={(props) => (
            <Palestras setLoading={setLoading} {...props} tipo="Palestras" />
          )}
        /> */}
        {/* <Route
          path="/assinador-digital"
          exact
          render={(props) => (
            <Assinador setLoading={setLoading} {...props} tipo="Assinador" />
          )}
        />
        <Route
          path="/noticias"
          exact
          render={(props) => (
            <Noticias setLoading={setLoading} {...props} tipo="Noticias" />
          )}
        />
        <Route
          path="/artigos"
          exact
          render={(props) => (
            <Artigos setLoading={setLoading} {...props} tipo="Artigos" />
          )}
        /> */}
        <Route
          path="/confirmarEmail/:hash"
          exact
          render={(props) => (
            <ConfirmarEmail setLoading={setLoading} {...props} />
          )}
        />
        {/* <Route
          exact
          path="/contato"
          render={(props) => <Contato setLoading={setLoading} {...props} />}
        /> */}
        {/* <Route
          path="/blog/:id"
          exact
          render={(props) => (
            <Pagina
              goBack="/blog"
              setLoading={setLoading}
              {...props}
              tipo="BLOG"
            />
          )}
        /> */}
        <PrivateRoute
          exact
          path="/modulos"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Modulos}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/opcao"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Opcao}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        {/* <PrivateRoute
          exact
          path="/comentarios"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Comentarios}
        /> */}
        <PrivateRoute
          exact
          path="/cadastroPja"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={PjaCadastro}
        />
        <PrivateRoute
          exact
          path="/perfil"
          authenticated={authenticated}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Perfil}
        />
        <PrivateRoute
          exact
          path="/pagamentos"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Pagamentos}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/contratar"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Contratar}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/contratar/:id"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Contratar}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/contratarModulo"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={ContratarModulo}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/contratarModulo/:id"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={ContratarModulo}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/moduloPago"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={ModuloPago}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/cadastros"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Cadastros}
        />
        {/* <PrivateRoute
          exact
          path="/planos"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Planos}
        /> */}
        <PrivateRoute
          exact
          path="/usuarios"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Usuarios}
        />
        <PrivateRoute
          exact
          path="/consultorias"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Consultorias}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Dashboard}
        />
        <PrivateRoute
          exact
          path="/consultoria"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Consultoria}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          exact
          path="/consultoria/:id"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Consultoria}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        {/* <PrivateRoute
          exact
          path="/conteudos"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Conteudos}
        />
        <PrivateRoute
          exact
          path="/conteudo"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Conteudo}
        />
        <PrivateRoute
          exact
          path="/conteudo/:id"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Conteudo}
        /> */}
        {/* <PrivateRoute
          path="/site/blog"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={CadastroPagina}
        />
        <PrivateRoute
          path="/site/blog/nova"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={CadastroPaginaNova}
        />
        <PrivateRoute
          path="/site/blog/editar/:id"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={CadastroPaginaNova}
        /> */}
        <PrivateRoute
          path="/texto"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Texto}
        />
        <PrivateRoute
          path="/modelos/:id"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Modelos}
        />
        <PrivateRoute
          path="/pja"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={TextoNovo}
        />
        <PrivateRoute
          path="/pagamento/:id"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Pagamento}
          // loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          path="/pagamentoModulo/:id"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={PagamentoModulo}
          // loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
        <PrivateRoute
          path="/texto/:id"
          exact
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={TextoNovoEdit}
        />
        <PrivateRoute
          exact
          path="/pacotes"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Pacotes}
        />
         <PrivateRoute
          exact
          path="/cupons"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={Cupons}
        />
        <PrivateRoute
          exact
          path="/help-Legis"
          authenticated={authenticated}
          setLoading={setLoading}
          currentUser={currentUser}
          component={HelpLegis}
          loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
        />
      </Switch>
      <Alert
        stack={{ limit: 3 }}
        timeout={3000}
        position="top-right"
        effect="slide"
        offset={65}
      />
    </Router>
  );
};

export default Routes;
