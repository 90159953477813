import React, { useState, useEffect, forwardRef } from 'react';
import styles from './BotaoFiltro.module.css';

const BotaoFiltro = forwardRef(({ dados, onUpdateFilterCount }, ref) => {
  const [options, setOptions] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('selectedFilters'));
    if (savedFilters) {
      setSelectedFilters(savedFilters);
      onUpdateFilterCount(savedFilters);
    }
  }, []);

  useEffect(() => {
    onUpdateFilterCount(selectedFilters);
  }, [selectedFilters]);

  const toggleOptions = (category) => {
    setOptions((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleCheckboxChange = (category, item) => {
    setSelectedFilters((prev) => {
      const categoryFilters = prev[category] || [];
      const newFilters = categoryFilters.includes(item.name)
        ? categoryFilters.filter((filter) => filter !== item.name)
        : [...categoryFilters, item.name];

      const updatedFilters = {
        ...prev,
        [category]: newFilters,
      };

      localStorage.setItem('selectedFilters', JSON.stringify(updatedFilters));

      return updatedFilters;
    });
  };

  const generateURL = () => {
    const queryString = Object.entries(selectedFilters)
      .map(([category, filters]) => {
        if (filters.length > 0) {
          const filterValues = filters.join(',');
          return `${category.toLowerCase()}=${filterValues}`;
        }
        return null;
      })
      .filter(Boolean)
      .join('&');

    localStorage.setItem("URL", "pagina=1&" + queryString);
    return `?${queryString}`;
  };

  const categoryMapping = {
    tribunal: 'Tribunal',
    origem: 'Origem',
    tipo_documento: 'Documento',
    relator: 'Relator',
    orgao_julgador: 'Órgão Julgador',
    classe_recurso: 'Classe',
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.content}>
        <span style={{ marginBottom: '-10px', display: "flex", justifyContent: "space-between" }}>
          <span>Filtrar por</span>
          <span
            style={{
              color: "#6495ed",
              cursor: "pointer"
            }}
            onClick={() => {
              localStorage.removeItem("URL");
              localStorage.removeItem("selectedFilters");
              setSelectedFilters({});
            }}
          >
            Limpar todos
          </span>
        </span>
        {Object.keys(dados).map((category) => (
          <div key={category}>
            <div className={styles.button} onClick={() => toggleOptions(category)}>
              <span>{categoryMapping[category]}</span>
              <span style={{ display: "flex", gap: "15px" }}>
                <span className={styles.numberSelected} style={{ display: selectedFilters[category]?.length > 0 ? "flex" : "none" }}>{selectedFilters[category]?.length || 0}</span>
                <span
                  className="fa fa-angle-right"
                  style={{
                    transition: 'transform 0.3s ease',
                    transform: options[category] ? 'rotate(90deg)' : '',
                  }}
                ></span>
              </span>
            </div>
            {options[category] && (
              <div className={styles.options}>
                {dados[category].map((item, index) => (
                  <div className={styles.checks} key={index}>
                    <input
                      type="checkbox"
                      name={item.name}
                      id={item.name}
                      onChange={() => handleCheckboxChange(category, item)}
                      checked={selectedFilters[category]?.includes(item.name) || false}
                    />
                    <label htmlFor={item.name}>{item.label}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <div style={{ display: "none" }}>
          <span>URL Gerada:</span>
          <p>{generateURL()}</p>
        </div>
      </div>
    </div>
  );
});

export default BotaoFiltro;