import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Listagem from "../../../componentes/Listagem";
import FormGroup from "../../../componentes/form-group";
import { getSecoes, salvarSecao, excluirSecao } from "../../../util/Api";
import "../CadastroRol/index.css";

const esquema = Yup.object().shape({
  titulo: Yup.string().min(3, "Obrigatório").required("Obrigatório"),
});

const CadastroSecao = (props) => {
  const [secao, setSecao] = useState({ content: [] });
  const [atualiza, setAtualiza] = useState(false);
  const formikRef = useRef();
  const [classBtn, setClassBtn] = useState("btn-success");

  const findSecao = () => {
    props.setLoading(true);
    getSecoes().then((data) => {
      setSecao({ content: data });
      props.setLoading(false);
    });
  };

  useEffect(() => {
    findSecao();
    // eslint-disable-next-line
  }, []);

  const excluir = (rol) => {
    excluirSecao(rol.id)
      .then(() => {
        Alert.warning("Seção excluída com sucesso!");
        findSecao();
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  const editarSecao = async (procedimento) => {
    await setAtualiza(true);
    setClassBtn("btn-info");
    formikRef.current.values.titulo = procedimento.titulo;
    formikRef.current.values.id = procedimento.id;
    await setAtualiza(false);
  };

  const salvar = (formulario) => {
    salvarSecao(formulario)
      .then(() => {
        if (classBtn === "btn-success") {
          Alert.success("Seção cadastrada com sucesso!");
        } else {
          Alert.success("Seção alterada com sucesso!");
        }
        findSecao();
        formulario.titulo = "";
        formulario.id = null;
        setClassBtn("btn-success");
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  return (
    <div className="container robotR">
      <div className="row">
        {atualiza ? null : (
          <div className="col-12 mt-2">
            <h4>Cadastro de Seções</h4>
          </div>
        )}
      </div>
      <Formik
        className="row"
        innerRef={formikRef}
        initialValues={{
          titulo: "",
          id: null,
        }}
        validationSchema={esquema}
        onSubmit={(values) => {
          salvar(values);
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <div className="col-lg-12">
              <div className="bs-component">
                <div className="row">
                  <div className="col-md-7">
                    <FormGroup
                      label="Título: "
                      htmlFor="inputTipoDeProcedimento"
                    >
                      <Field
                        type="text"
                        placeholder="digite o nome da seção a ser cadastrada"
                        className={`form-control ${
                          errors.titulo && touched.titulo ? "invalid" : ""
                        }`}
                        name="titulo"
                      />
                      {errors.titulo && touched.titulo ? (
                        <span className="text-error">{errors.titulo}</span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-5 mb-2 ptop">
                    <button
                      type="submit"
                      onClick={() => {
                        if (!isValid) {
                          Alert.warning("Preencha todos os campos destacados");
                        }
                      }}
                      className="btn buttonSalvarRol mx-1"
                    >
                      Salvar
                    </button>
                    <button type="reset" className="btn buttonLimparRol mx-1">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-1 bg-branco">
        <Listagem
          tituloPlano
          paginacao={secao}
          botaoEditar={editarSecao}
          botaoExcluir={excluir}
        />
      </div>  
    </div>
  );
};

export default CadastroSecao;
