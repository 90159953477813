import React, { useEffect, useState } from "react";
import GoBack from "../../componentes/goBack";
import { getModulos } from "../../util/Api";
import { useParams } from "react-router-dom";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado";

const Cotratar = (props) => {
  const [modulos, setModulos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modulo, setModulo] = useState({});
  const { id } = useParams();

  function enviarPlano(modulo) {
    setModulo(modulo);
    props.history.push("/pagamentoModulo/" + modulo.id);
  }

  useEffect(() => {
    props.setLoading(true);
    getModulos().then((data) => {
      setModulos(data);
      props.setLoading(false);
      if (id) {
        setModulo(data.find((p) => p.id === parseInt(id)));
        setIsOpen(true);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="container bodyFont">
        <div className="mt-5"><GoBack link={"/modulos"} /></div>
        {isOpen ? null : null}
        {modulo ? null : null}
        <div className="row mt-5 fundoPjaNao">
          <div className="col-5 mt-2">
            <p className="escritoPena p-4">
            Ah que pena :( <br/> No momento você não possui nenhum <br/> plano ativo. Comece um agora mesmo
            </p>
          </div>
          {/* <div className="col-12 text-center my-3">
            <PagseguroPaymatch
              loadCurrentlyLoggedInUser={props.loadCurrentlyLoggedInUser}
              plano={modulo}
              modulo={true}
              setLoading={props.setLoading}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div> */}
          {modulos.map((p) => {
            return (
              <>
                <div className="col-md-2 pjaNomeEmbaixo">{p.titulo}</div>
                <div className="col-md-5" key={p.id}>
                  <div
                    className="m-1"
                    onClick={() => enviarPlano(p)}
                    // () => setAtivo(modulo.id)
                  >
                    <section className="fundoPjaCard mt-3">
                        <h2 className="tituloCardPja p-3">{p.titulo}</h2>
                          <div className="cardPreco mx-auto cardPrecoBorda">
                            <h4 className="text-center corPlano">
                              <span className="de">De 129,00/mês</span><br/>
                              <span className="por">Por</span><br/>
                              {(p.valor / p.parcela).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })} <span className="por">/mês</span>
                            </h4>
                          </div>
                          <button type="button" className="btn botaoNovoContratar mx-auto mt-4">Começar agora</button>
                    </section>
                    </div>
                    <p className="paraPagar">Nosso sistema não armazena nenhum meio de pagamento. <br/> Todo processo de compra é feito pelo nosso parceiro Mercado Pago</p>
                </div>
              </> 
            );
          })}
        </div>
      </div>
      <AppFooterLogado></AppFooterLogado>
    </div>
  );
};

export default Cotratar;
