import React, { useEffect } from "react";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import { Link } from "react-router-dom";

const Opcao = (props) => {
  //const { currentUser, loadCurrentlyLoggedInUser } = props;
  useEffect(() => {
    // setLoading(true);
    // loadCurrentlyLoggedInUser();
    // eslint-disable-next-line
  }, []);

  return(
    <div id="modulo" className="container mt-5 pt-5 robotR">
      <div className="row mt-5 justify-content-between bg-light">
        <div className="col-md-6 tstGratis p-4">
            <h4 className="pjaTitulo">Teste Gratis </h4>
            <p className="paragrafoPja mb-5">
            Teste Gratis o sistema por 7 Dias!<br/> 
            </p>
            <Link className="pjaButton mt-5" to="/pja" >Utilizar 7 dias Gratis</Link>
        </div>
        <div className="col-md-6 assPlanos p-4">
            <h4 className="pjaTitulo">Assine ja!</h4>
            <p className="paragrafoPja mb-5">
              Assine um de nossos planos e desfrute do sistema com tranquilidade.<br/>
            </p>
            <Link className="helpButton mt-5" to="/contratarModulo">Assinar um Plano</Link>
        </div>
              
      </div>
      <AppFooterLogado/>
    </div>
  )
  };

export default Opcao;
