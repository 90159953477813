import React, { useState, useEffect } from "react";
import Listagem from "../../componentes/Listagem";
import GoBack from "../../componentes/goBack";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { getTransacoes, atualizarStatusPagamento } from "../../util/Api";
import Alert from "react-s-alert";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado.jsx";
import iconeX from "../../assets/imagens/icones/x-circle.png";

const Pagamentos = (props) => {
  const [transacoes, setTransacoes] = useState({ content: [] });
  const [filtro, setFiltro] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [pagamento, setPagamento] = useState({});
  const { setLoading } = props;

  useEffect(() => {
    setLoading(true);
    filtrarTransacoes();
    // eslint-disable-next-line
  }, []);

  const atualizarStatus = (id) => {
    setLoading(true);
    atualizarStatusPagamento(id)
      .then(() => {
        setLoading(false);
        getTransacoes(filtro).then((data) => {
          data.changePage = pageChange;
          setTransacoes(data);
          setLoading(false);
        });
        Alert.info("Transação atualizada com sucesso!");
      })
      .catch((error) => {
        setLoading(false);
        Alert.error(
          (error && error.message) ||
            "Desculpe, ocorreu um erro durante a atualização da transação, por favor tente novamente!"
        );
      });
  };

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    getTransacoes(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setTransacoes(data);
      setLoading(false);
    });
  };

  const filtrarTransacoes = () => {
    getTransacoes(filtro).then((data) => {
      data.changePage = pageChange;
      setTransacoes(data);
      setLoading(false);
    });
  }

  const visualizar = (item) => {
    setPagamento(item);
    setIsOpen(true);
  };

  return (
    <div className="bg-light">
    <div className="container robotR">
    <div className="row pt-5">
      <div className="col-md-9">
        <GoBack link={"/modulos"} />
      </div>
      <div className="col-md-3">
          <a href="#/help-legis" className="btn btn btn-voltar-novo">Voltar</a>
      </div>
        <Modal
          className="robotR"
          show={isOpen}
          onHide={() => setIsOpen(false)}
          size="lg"
        >
          <Modal.Header className="fundoC">
            <h5>Status do pagamento: <span className="font-weight-bold"> {pagamento?.plano ? "Help Legis" : "PJA"} - {moment(pagamento.data).format("DD/MM/YYYY HH:mm:ss")}</span></h5>
            <button className="btn btn-sm btnNoBack" type="button" onClick={() => setIsOpen(false)}><img src={iconeX ? iconeX : null} className="mx-1" alt="botão fechar"/></button>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <span className="robotB">Cliente:</span>
                  <p>{pagamento?.cliente?.nome}</p>
                  <hr />
                </div>
                <div className="col-md-6">
                  <span className="robotB">Status:</span>
                  {pagamento?.statusPagamento === "AUTORIZADO" || pagamento?.statusPagamento === "APROVADO"? (
                    <p>Autorizado</p>
                  ): null}

                  {pagamento?.statusPagamento === "PENDENTE" ? (
                    <p>Pendente</p>
                  ): null}

                  {pagamento?.statusPagamento === "REJECTED" ? (
                    <p>Recusado</p>
                  ): null}
                  <hr />
                </div>
                {pagamento?.plano ? (
                    <div className="col-md-6">
                      <span className="robotB">Consultorias restantes:</span>
                    <p>{pagamento?.execucoes}</p>
                    <hr />
                  </div>
                ): null}
                {pagamento.urlBoleto ? (
                  <div className="col-md-6">
                    <span className="robotB">Tipo de Pagamento:</span>
                    <p>
                      <a href={pagamento.urlBoleto} target="_blanck">
                        Boleto - Ver Boleto
                      </a>
                    </p>
                    <hr />
                  </div>
                ) : (
                  <div className="col-md-6">
                    <span className="robotB">Tipo de Pagamento:</span>
                    <p>
                      Cartão de Crédito <br />
                      Quantidade de vezes: {pagamento.parcela}
                    </p>
                    <hr />
                  </div>
                )}

                {pagamento?.plano ? (
                  <>
                    <div className="col-md-6">
                      <span className="robotB">Pacote:</span>
                      <p>{pagamento?.plano?.titulo}</p>
                      <hr />
                    </div>
                    <div className="col-md-6">
                      <span className="robotB">Descrição do Pacote:</span>
                      <p>{pagamento?.plano?.descricao}</p>
                      <hr />
                    </div>
                  </>
                ) : null}
                {pagamento?.modulo ? (
                  <>
                    <div className="col-md-6">
                      <span className="robotB">Módulo:</span>
                      <p>{pagamento?.modulo?.titulo}</p>
                      <hr />
                    </div>
                    <div className="col-md-6">
                      <span className="robotB">Descrição do Módulo:</span>
                      <p>{pagamento?.modulo?.descricao}</p>
                      <hr />
                    </div>
                  </>
                ) : null}
                {props.currentUser?.nivel === "ADM" &&
                pagamento?.statusPagamento !== "AUTORIZADO" ? (
                  <div className="col-md-12 text-center">
                    <button
                      className="btn buttonSalvarPeticao"
                      onClick={() => {
                        atualizarStatus(pagamento?.id);
                      }}
                    >
                      Atualizar Status
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="col-md-12 mt-3">
          <Listagem
            titulo={<h4>Pagamentos</h4>}
            dataPagamento
            cliente
            acaoNewPagamento
            servico
            statusPagamento
            servicoQual
            currentUser={props.currentUser}
            setIten={visualizar}
            paginacao={transacoes}
          />
        </div>
      </div>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
    </div>
  );
};

export default Pagamentos;
