import React from 'react'
import styles from './Popup.module.css'

function Popup(props) {
  return (props.trigger) ? (
    <div className={styles.popup}>
      <div className={styles.popupinner}>
        { props.children }
      </div>
    </div>
  ) : "";
}

export default Popup