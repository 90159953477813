import React, { useEffect, useState } from "react";
import { dashboard } from "../../util/Api";
import "./index.css";
import GoBack from "../../componentes/goBack";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
  const [dados, setDados] = useState({});

  const { setLoading } = props;

  useEffect(() => {
    setLoading(true);
    dashboard()
      .then((data) => {
        setDados(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    setInterval(() => {
      dashboard()
        .then((data) => {
          setDados(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }, 30000);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div id="dash" className="container">
        <div className="row mt-3">
        <GoBack link={"/modulos"} />
          <div className="col-md-12 mb-lg-0 mb-3 left-grid fonteRSobre pb-3">
            <div className="heading">
              <h3 className="heading text-uppercase mb-2 pb-2 bur">
                <span>Visão</span> geral
              </h3>
            </div>
          </div>
          <div className="col-12 px-2 py-2">
            <div className="row">
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-users mt-2"></span>
                  </div>
                  <div className="card-body text-center">
                    <h1 className="">
                      {dados.ativos > 0 ? dados.ativos : "0"}
                    </h1>
                    <Link
                      to="/usuarios"
                      className="btn btn-primary btn-sm mb-2"
                    >
                      Ver usuários
                    </Link>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-1 card-title">
                      {dados.ativos > 1 ? "Clientes ativos" : "Cliente ativo"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-edit mt-2"></span>
                  </div>
                  <div className="card-body">
                    <h1 className="text-center">{dados.planos}</h1>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-1 card-title">
                      {dados.planos > 1
                        ? "Planos cadastrados"
                        : "Plano cadastrado"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-usd mt-2"></span>
                  </div>
                  <div className="card-body text-center">
                    <h1 className="text-center">{dados.transacoesPagas}</h1>
                    <Link
                      to="/pagamentos"
                      className="btn btn-primary btn-sm mb-2"
                    >
                      Ver pagamentos
                    </Link>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.transacoesPagas > 1
                        ? "Transações pagas"
                        : "Transação paga"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-money mt-2"></span>
                  </div>
                  <div className="card-body">
                    <h1 className="text-center">
                      {(dados.valoresPlanos / 1).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h1>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.valoresPlanos > 1
                        ? "Valores recebidos ou a receber"
                        : "Valor recebido ou a receber"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-file-text-o mt-2"></span>
                  </div>
                  <div className="card-body">
                    <h1 className="text-center">{dados.qtdConsultoria}</h1>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.qtdConsultoria > 1
                        ? "Consultorias aprovadas"
                        : "Consultoria aprovada"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-folder-open-o mt-2"></span>
                  </div>
                  <div className="card-body">
                    <h1 className="text-center">{dados.qtdConsAberta}</h1>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.qtdConsAberta > 1
                        ? "Consultorias abertas"
                        : "Consultoria aberta"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-folder mt-2"></span>
                  </div>
                  <div className="card-body">
                    <h1 className="text-center">{dados.qtdConsFechada}</h1>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.qtdConsFechada > 1
                        ? "Consultorias finalizadas"
                        : "Consultoria finalizada"}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="card-group col-md-3">
                <div className="card m-2">
                  <div className="card-header text-center">
                    <span className="fa fa-globe mt-2"></span>
                  </div>
                  <div className="card-body">
                    <ul className="lista">
                      {dados.estados &&
                        dados.estados.map((estado) => {
                          return (
                            <li className="">{`${estado.sigla} - ${estado.qtd}`}</li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="card-footer">
                    <h4 className="text-center mt-2">
                      {dados.qtdRegistroEst > 1 ? "Estados" : "Estado"}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">{JSON.stringify(dados)}</div>
          <div>
            <h1>{dados.ativos}</h1>
          </div> */}
        </div>
      </div>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
  );
};

export default Dashboard;
