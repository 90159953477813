import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Listagem from "../../../componentes/Listagem";
import FormGroup from "../../../componentes/form-group";
import {
  getTextos,
  salvarTexto,
  excluirTexto,
  getRolsOrdenados,
  getSecoesOrdenadas,
} from "../../../util/Api";
import "./index.css";

const esquema = Yup.object().shape({
  subtitulo: Yup.string()
    .min(1, "Digite ao menos uma letra")
    .required("Obrigatório"),
  descricao: Yup.string()
    .min(1, "Digite ao menos uma letra")
    .required("Obrigatório"),
  // rol: Yup.object().shape({
  //   id: Yup.number().min(1, "Você precisa escolher um rol"),
  // }),
  // secao: Yup.object().shape({
  //   id: Yup.number().min(1, "Você precisa escolher uma seção"),
  // }),
});

const CadastroTexto = (props) => {
  const [texto, setTexto] = useState({ content: [] });
  const [rol, setRol] = useState([]);
  const [secao, setSecao] = useState([]);
  const [atualiza, setAtualiza] = useState(false);
  const formikRef = useRef();
  const [classBtn, setClassBtn] = useState("btn-success");

  const findTexto = () => {
    props.setLoading(true);
    getTextos().then((data) => {
      setTexto({ content: data });
      props.setLoading(false);
    });
  };

  const findRol = () => {
    props.setLoading(true);
    getRolsOrdenados().then((data) => {
      setRol(data);
      props.setLoading(false);
    });
  };

  const findSecao = () => {
    props.setLoading(true);
    getSecoesOrdenadas().then((data) => {
      setSecao(data);
      props.setLoading(false);
    });
  };

  useEffect(() => {
    findTexto();
    findRol();
    findSecao();
    // eslint-disable-next-line
  }, []);

  const excluir = (texto) => {
    excluirTexto(texto.id)
      .then(() => {
        Alert.warning("Texto excluído com sucesso!");
        findTexto();
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  const editarTexto = async (procedimento) => {
    await setAtualiza(true);
    setClassBtn("btn-info");
    formikRef.current.values.subtitulo = procedimento.subtitulo;
    formikRef.current.values.decreto = procedimento.decreto;
    formikRef.current.values.id = procedimento.id;
    formikRef.current.values.descricao = procedimento.descricao;
    await setAtualiza(false);
  };

  const salvar = (formulario) => {
    if (formulario?.rol?.id === null) {
      Alert.warning("Selecione um rol");
      return;
    }
    if (formulario?.secao?.id === null) {
      Alert.warning("Selecione uma seção");
      return;
    }
    salvarTexto(formulario)
      .then(() => {
        if (classBtn === "btn-success") {
          Alert.success("Texto cadastrado com sucesso!");
        } else {
          Alert.success("Texto alterado com sucesso!");
        }
        findTexto();
        formulario.subtitulo = "";
        formulario.decreto = "";
        formulario.id = null;
        formulario.descricao = "";
        setClassBtn("btn-success");
      })
      .catch((error) => {
        Alert.error((error && error.message) || "deu ruim!");
      });
  };

  return (
    <div className="container robotR">
      <div className="row">
        {atualiza ? null : (
          <div className="col-12 mt-2">
            <h4>Cadastro de Textos</h4>
          </div>
        )}
      </div>
      <Formik
        className="row"
        innerRef={formikRef}
        initialValues={{
          subtitulo: "",
          decreto: "",
          descricao: "",
          rol: {
            id: null,
          },
          secao: {
            id: null,
          },
          id: null,
        }}
        validationSchema={esquema}
        onSubmit={(values) => {
          salvar(values);
        }}
      >
        {({ errors, touched, isValid }) => (
          <Form>
            <div className="col-lg-12">
              <div className="bs-component">
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup
                      label="Subtítulo"
                      htmlFor="inputTipoDeProcedimento"
                    >
                      <Field
                        type="text"
                        placeholder="digite o subtítulo da seção a ser cadastrado"
                        className={`form-control ${
                          errors.subtitulo && touched.subtitulo ? "invalid" : ""
                        }`}
                        name="subtitulo"
                      />
                      {errors.subtitulo && touched.subtitulo ? (
                        <span className="text-error erroSelectTexto">
                          {errors.subtitulo}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup
                      label="Decreto"
                      htmlFor="inputTipoDeProcedimento"
                    >
                      <Field
                        type="text"
                        placeholder="digite o decreto a ser cadastrado"
                        className={`form-control ${
                          errors.decreto && touched.decreto ? "invalid" : ""
                        }`}
                        name="decreto"
                      />
                      {errors.decreto && touched.decreto ? (
                        <span className="text-error erroSelectTexto">
                          {errors.decreto}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="Rol " htmlFor="inputTipoDeProcedimento">
                      <Field name="rol.id" as="select" className="form-control">
                        <option value="" selected disabled hidden>
                          Selecione o rol
                        </option>
                        {rol.map((r) => {
                          return (
                            <option value={r.id} key={r.id}>
                              {r.titulo}
                            </option>
                          );
                        })}
                      </Field>
                      {errors?.rol?.id && touched?.rol?.id ? (
                        <span className="text-error erroSelectTexto">
                          {errors?.rol?.id}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup
                      label="Seção "
                      htmlFor="inputTipoDeProcedimento"
                    >
                      <Field
                        name="secao.id"
                        as="select"
                        className="form-control"
                      >
                        <option value="" selected disabled hidden>
                          Selecione a seção
                        </option>
                        {secao.map((s) => {
                          return (
                            <option value={s.id} key={s.id}>
                              {s.titulo}
                            </option>
                          );
                        })}
                      </Field>
                      {errors?.secao?.id && touched?.secao?.id ? (
                        <span className="text-error erroSelectTexto">
                          {errors?.secao.id}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <FormGroup
                      label="Texto "
                      htmlFor="inputTipoDeProcedimento"
                    >
                      <Field
                        type="text"
                        as="textarea"
                        placeholder="digite o texto a ser cadastrado"
                        className="form-control"
                        name="descricao"
                      />
                      {errors.descricao && touched.descricao ? (
                        <span className="text-error erroSelectTexto">
                          {errors.descricao}
                        </span>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-md-4 my-2">
                    <button
                      type="submit"
                      onClick={() => {
                        if (!isValid) {
                          Alert.warning("Preencha todos os campos destacados");
                        }
                      }}
                      className="btn buttonSalvarRol mx-1"
                    >
                      Salvar
                    </button>
                    <button type="reset" className="btn buttonLimparRol mx-1">
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Listagem
        titulo={<h5>Textos cadastrados</h5>}
        subtitulo
        decreto
        rol
        secao
        verTexto
        paginacao={texto}
        botaoEditar={editarTexto}
        botaoExcluir={excluir}
      />
    </div>
  );
};

export default CadastroTexto;
