import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Alert from "react-s-alert";
import { useParams } from 'react-router-dom';
import GoBack from "../../../componentes/goBack.jsx";
import {
    salvarCabecalhoImg,
    salvarCabecalhoTexto,
    salvarMarca,
    salvarRodapeImg,
    salvarRodapeTexto
  } from "../../../util/Api";
import AppFooterLogado from "../../../componentes/AppFooterLogado";

const Modelo = (props) => {
    const { id } = useParams();
    // const [tipo, setTipo] = useState();
    const [isImagem, setIsImagem] = useState(true);
    const [isImagemRodape, setIsImagemRodape] = useState(true);
    const [texto, setTexto] = useState('');
    const [textoRodape, setTextoRodape] = useState('');
    const formDataCabecalho = new FormData();
    const formDataMarca = new FormData();
    const formDataRodape = new FormData();

    const handleInputChange = (event) => {
        // Atualiza o estado com o valor do campo textarea
        setTexto(event.target.value);
    };

    const handleInputChangeRodape = (event) => {
        // Atualiza o estado com o valor do campo textarea
        setTextoRodape(event.target.value);
    };

    const handleImagemChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            formDataCabecalho.append('file', file);
        //   enviarImagemEditor(formData).then(async (data) => {
        //     await setTextoHtml(
        //       RichTextEditor.createValueFromString(
        //         `${textoHtml.toString(
        //           "html"
        //         )} <p><img src="${'data:image/png;base64,' + data}" style="max-width: 400px; height: auto;"/></p>`,
        //         "html"
        //       )
        //     );
        //   });
        }
    };

    const handleImagemChangeRodape = (event) => {
        const file = event.target.files[0];
        if (file) {
            formDataRodape.append('file', file);
        //   enviarImagemEditor(formData).then(async (data) => {
        //     await setTextoHtml(
        //       RichTextEditor.createValueFromString(
        //         `${textoHtml.toString(
        //           "html"
        //         )} <p><img src="${'data:image/png;base64,' + data}" style="max-width: 400px; height: auto;"/></p>`,
        //         "html"
        //       )
        //     );
        //   });
        }
    };

    const handleImagemChangeMarca = (event) => {
        const file = event.target.files[0];
        if (file) {
            formDataMarca.append('file', file);
        //   enviarImagemEditor(formData).then(async (data) => {
        //     await setTextoHtml(
        //       RichTextEditor.createValueFromString(
        //         `${textoHtml.toString(
        //           "html"
        //         )} <p><img src="${'data:image/png;base64,' + data}" style="max-width: 400px; height: auto;"/></p>`,
        //         "html"
        //       )
        //     );
        //   });
        }
    };

    const limpaCabecalho = () => {
        setTexto('');
    };

    const limpaRodape = () => {
        setTextoRodape('');
    };

    const salvarCabecalho = () => {
        if(isImagem === true){
            salvarCabecalhoImg(formDataCabecalho)
                .then((data) => {
                    Alert.info("Cabeçalho salvo com sucesso!");
                    limpaCabecalho();
                })
                .catch(({ response }) => {
                  props.setLoading(false);
                //   Alert.warning(
                //     response && response.data
                //       ? response.data.Error
                //       : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
                //   );
            });
        } else {
            salvarCabecalhoTexto(texto)
                .then((data) => {
                    Alert.info("Cabeçalho salvo com sucesso!");
                    limpaCabecalho();
                //   setLoading(false);
                  // props.setIsOpen(false);
                //   Alert.info(data.ResponseDetail.Description, { timeout: 10000 });
                //   if (isBoleto) {
                //     window.open(data.ResponseDetail.BankSlipUrl, "_blank");
                //   } else {
                //     loadCurrentlyLoggedInUser("/modulos");
                //   }
                })
                .catch(({ response }) => {
                  props.setLoading(false);
                //   Alert.warning(
                //     response && response.data
                //       ? response.data.Error
                //       : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
                //   );
            });
        }
       
    };

    const saveMarca = () => {
        salvarMarca(formDataMarca)
            .then((data) => {
                Alert.info("Marca d'água salva com sucesso!");
                // limpaCabecalho();
            //   setLoading(false);
                // props.setIsOpen(false);
            //   Alert.info(data.ResponseDetail.Description, { timeout: 10000 });
            //   if (isBoleto) {
            //     window.open(data.ResponseDetail.BankSlipUrl, "_blank");
            //   } else {
            //     loadCurrentlyLoggedInUser("/modulos");
            //   }
            })
            .catch(({ response }) => {
                props.setLoading(false);
            //   Alert.warning(
            //     response && response.data
            //       ? response.data.Error
            //       : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
            //   );
        });
    };

    const salvarRodape = () => {
        if(isImagemRodape === true){
            salvarRodapeImg(formDataRodape)
                .then((data) => {
                    Alert.info("Rodapé salvo com sucesso!");
                    limpaRodape();
                //   setLoading(false);
                  // props.setIsOpen(false);
                //   Alert.info(data.ResponseDetail.Description, { timeout: 10000 });
                //   if (isBoleto) {
                //     window.open(data.ResponseDetail.BankSlipUrl, "_blank");
                //   } else {
                //     loadCurrentlyLoggedInUser("/modulos");
                //   }
                })
                .catch(({ response }) => {
                  props.setLoading(false);
                //   Alert.warning(
                //     response && response.data
                //       ? response.data.Error
                //       : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
                //   );
            });
        } else {
            salvarRodapeTexto(textoRodape)
                .then((data) => {
                    Alert.info("Rodapé salvo com sucesso!");
                    limpaRodape();
                //   setLoading(false);
                  // props.setIsOpen(false);
                //   Alert.info(data.ResponseDetail.Description, { timeout: 10000 });
                //   if (isBoleto) {
                //     window.open(data.ResponseDetail.BankSlipUrl, "_blank");
                //   } else {
                //     loadCurrentlyLoggedInUser("/modulos");
                //   }
                })
                .catch(({ response }) => {
                  props.setLoading(false);
                //   Alert.warning(
                //     response && response.data
                //       ? response.data.Error
                //       : "Houve um erro, por favor verifique se preencheu corretamente o cadastro!"
                //   );
            });
        }
       
    };

    useEffect(() => {

    }, []);

  return (
    <div className="bg-light">
      <Container className="pt-5">
        <GoBack link={`/texto/${id}`} />
        <h4>Cadastre novos modelos para suas petições</h4>
            {/* <hr /> */}
            <hr />
            <p className="font-weight-bold">Cabeçalho</p>
            <section className="row">
                <div className="col-md-6">
                      <label>Deseja escolher uma imagem ou escrever algo? </label>
                      <div className="">
                        <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="customRadioInline" checked={isImagem ? true : false} onClick={() => setIsImagem(true)} className="custom-control-input"></input>
                                <label className="custom-control-label" for="customRadioInline1">Imagem</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="customRadioInline" onClick={() => setIsImagem(false)} className="custom-control-input"></input>
                                <label className="custom-control-label" for="customRadioInline2">Texto</label>
                        </div>
                      </div>
                </div>
                {isImagem ? 
                    <>
                        <div className="col-md-6 mb-1">
                            <form className="">
                                <label className=""> Selecione uma imagem: </label> <br />
                                <input type="file" accept="image/*" onChange={handleImagemChange} />
                            </form>
                        </div>
                    </> : 
                    <>
                        <div className="col-md-6 mb-1">
                            <form className="">
                                <label>Digite o texto que deseja:</label>
                                <textarea type="text" value={texto} onChange={handleInputChange} className="form-control" placeholder="digite aqui"/>
                            </form>
                        </div> 
                    </>    
                }

                <div className="col-md-6">
                    <button
                    className="btn btn-outline-info btn-sm mx-2"
                    type="button"
                    // onClick={() => {
                    //   gerarPdfTextoCliente(textoCliente.id).then((data) => {
                    //     saveAs(data, textoCliente.titulo + ".pdf");
                    //   });
                    // }}
                    onClick={() => {
                        salvarCabecalho();
                    }}
                    >
                    Salvar cabeçalho
                    </button>    
                </div>  
            </section>
            <hr />
        
            <p className="font-weight-bold">Marca d'água</p>
            <section className="row">
                <div className="col-md-6 mb-1">
                    <form className="">
                        <label className=""> Selecione uma imagem: </label> <br />
                        <input type="file" accept="image/*" onChange={handleImagemChangeMarca} />
                        {/* <span>Selecionar imagem</span>
                        </label> */}
                    </form>
                </div>
                <div className="col-md-6">
                    <button
                    className="btn btn-outline-info btn-sm mx-2"
                    type="button"
                    // onClick={() => {
                    //   gerarPdfTextoCliente(textoCliente.id).then((data) => {
                    //     saveAs(data, textoCliente.titulo + ".pdf");
                    //   });
                    // }}
                    onClick={() => {
                        saveMarca();
                    }}
                    >
                    Salvar marca d'água
                    </button>    
                </div>   
            </section>
            <hr />
            <p className="font-weight-bold">Rodapé</p>   

            <section className="row">
                <div className="col-md-6">
                      <label>Deseja escolher uma imagem ou escrever algo? </label>
                      <div className="">
                        <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline3" name="customRadioInline1" checked={isImagemRodape ? true : false} onClick={() => setIsImagemRodape(true)} className="custom-control-input"></input>
                                <label className="custom-control-label" for="customRadioInline3">Imagem</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline4" name="customRadioInline1" onClick={() => setIsImagemRodape(false)} className="custom-control-input"></input>
                                <label className="custom-control-label" for="customRadioInline4">Texto</label>
                        </div>
                      </div>
                </div>
                {isImagemRodape && isImagemRodape === true ? 
                    <>
                        <div className="col-md-6 mb-1">
                            <form className="">
                                <label className=""> Selecione uma imagem: </label> <br />
                                <input type="file" accept="image/*" onChange={handleImagemChangeRodape} />
                            </form>
                        </div>
                    </> : 
                    <>
                        {isImagemRodape === false ? 
                            <>
                                <div className="col-md-6 mb-1">
                                    <form className="">
                                        <label>Digite o texto que deseja:</label>
                                        <textarea type="text" value={textoRodape} onChange={handleInputChangeRodape} className="form-control" placeholder="digite aqui"/>
                                    </form>
                                </div> 
                            </> : null}
                      
                    </>    
                }  
                <div className="col-md-6">
                    <button
                    className="btn btn-outline-info btn-sm mx-2"
                    type="button"
                    // onClick={() => {
                    //   gerarPdfTextoCliente(textoCliente.id).then((data) => {
                    //     saveAs(data, textoCliente.titulo + ".pdf");
                    //   });
                    // }}
                    onClick={() => {
                        salvarRodape();
                    }}
                    >
                    Salvar cabeçalho
                    </button>    
                </div>  
            </section>
                <hr />
        <AppFooterLogado/>
      </Container>  
    </div>
  );
};

export default Modelo;
