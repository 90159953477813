import React, { useState, useEffect } from "react";
import Listagem from "../../componentes/Listagem";
import { filtroConsultoria, finalizarConsultoria } from "../../util/Api";

const Consultorias = (props) => {
  const { currentUser, setLoading } = props;
  const [pagina, setPagina] = useState({ content: [] });
  const [filtro, setFiltro] = useState({});

  useEffect(() => {
    setLoading(true);
    filtroConsultoria(filtro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [0]);

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    filtroConsultoria(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
  };

  const goVisualizar = (item) => {
    props.history.push("/consultoria/" + item.id);
  };

  return (
    <div className="container robotR">
      <div className="row mt-5">
        {currentUser?.nivel === "CLIENTE" ? (
          <div className="col-12">
            <h3 className="ml-2">
              Consultorias contratadas restantes:{" "}
              <span className="font-weight-bold">
                {currentUser?.consultorias}
              </span>
            </h3>
          </div>
        ) : null}
        <div className="col-12">
          <Listagem
            novoLancamento={
              currentUser?.nivel === "CLIENTE" ? "/consultoria" : ""
            }
            linkNovoLancamento={"/consultoria"}
            labelLink={
              currentUser?.nivel === "CLIENTE" ? "Solicitar Consultoria" : ""
            }
            data
            cliente={currentUser?.nivel === "CLIENTE"}
            statusConsultoria
            acao
            tituloConsultoria
            paginacao={pagina}
            setIten={goVisualizar}
            custonConfirmFunction={async (item) => {
              await finalizarConsultoria(item.id);
              await pageChange(filtro.pagina);
            }}
            custonConfirmTitulo="Deseja mesmo finalizar esta consultoria?"
            custonConfirmLabel="Após finalizada, o cliente não poderá mais enviar mensagens, deseja continuar?"
            currentUser={currentUser}
          />
        </div>
      </div>
    </div>
  );
};

export default Consultorias;
