import React, { useState, useEffect, useRef } from "react";
import Alert from "react-s-alert";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import GoBack from "../../componentes/goBack";
import Listagem from "../../componentes/Listagem";
import FormGroup from "../../componentes/form-group";
import { getPlanos, salvarPlano, excluirPlano } from "../../util/Api";
import Modulos from "../ModulosPago/index.jsx";
import "./index.css";

const esquema = Yup.object().shape({
    titulo: Yup.string().min(3, "Obrigatório").required("Obrigatório"),
    descricao: Yup.string()
      .min(5, "Poucos caracteres")
      .max(255, "Muitos caracteres!")
      .required("Obrigatório"),
    valor: Yup.number().required("Obrigatório").min(1, "Obrigatório"),
    valorDesconto: Yup.number().min(0, "Obrigatório").nullable(),
    execucoes: Yup.number().required("Obrigatório").min(1, "Obrigatório"),
    parcela: Yup.number()
      .required("Obrigatório")
      .min(1, "Mínimo 1 parcela")
      .max(12, "Máximo 12 parcelas"),
  });

const Pacotes = (props) => {
    const [planos, setPlanos] = useState({ content: [] });
    const formikRef = useRef();
    const [classBtn, setClassBtn] = useState("btn-success-novo");
    const [tabNow, setTabNow] = useState("pacotes-tab");
    const [atualiza, setAtualiza] = useState(false);

    const findPlanos = () => {
        props.setLoading(true);
        getPlanos().then((data) => {
          setPlanos({ content: data });
          props.setLoading(false);
        });
      };

      const excluir = (plano) => {
        excluirPlano(plano.id)
          .then(() => {
            Alert.warning("Pacote excluído com sucesso!");
            findPlanos();
          })
          .catch((error) => {
            Alert.error((error && error.message) || "deu ruim!");
          });
      };

      useEffect(() => {
        findPlanos();
        // eslint-disable-next-line
      }, []);
    
      const editarPlano = async (procedimento) => {
        await setAtualiza(true);
        setClassBtn("btn-info-new-forms");
        formikRef.current.values.titulo = procedimento.titulo;
        formikRef.current.values.descricao = procedimento.descricao;
        formikRef.current.values.valor = procedimento.valor;
        formikRef.current.values.execucoes = procedimento.execucoes;
        formikRef.current.values.valorDesconto = procedimento.valorDesconto;
        formikRef.current.values.id = procedimento.id;
        formikRef.current.values.parcela = procedimento.parcela;
        await setAtualiza(false);
      };

      const salvar = (formulario) => {
        salvarPlano(formulario)
          .then(() => {
            if (classBtn === "btn-success-novo") {
              Alert.info("Pacote cadastrado com sucesso!");
            } else {
              Alert.info("Pacote alterado com sucesso!");
            }
            findPlanos();
            formulario.titulo = "";
            formulario.descricao = "";
            formulario.valor = 0;
            formulario.execucoes = 0;
            formulario.id = null;
            formulario.parcela = 0;
            setClassBtn("btn-success-novo");
          })
          .catch((error) => {
            Alert.error((error && error.message) || "deu ruim!");
          });
    };

return (
    <div className="bg-light">
    <div className="container robotR pt-5">
        <GoBack link={"/cadastros"} />
        <div className="row mb-2">
            <h4 className="col-md-10">{ tabNow === "pacotes-tab" ? "Cadastrar Pacotes" : "Cadastro PJA"}</h4>
            {atualiza ? null : null}
            <div className="col-md-1">
                <a href="#/cadastros" className="btn btn-layout float-right">Voltar</a>
            </div>
        </div>
        <section className="pt-3">
            <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
            <li className="nav-item col-md-6">
                <a className="nav-link active color" id="pacotes-tab" data-toggle="tab" href="#pacotesTab" role="tab" aria-controls="pacotesTab" aria-selected="true" onClick={() => setTabNow("pacotes-tab")}>Consultorias</a>
            </li>
            <li className="nav-item col-md-6">
                <a className="nav-link color" id="pja-tab" data-toggle="tab" href="#pja" role="tab" aria-controls="pja" aria-selected="false" onClick={() => setTabNow("pja")}>PJA</a>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="pacotesTab" role="tabpanel" aria-labelledby="pacotes-tab">
                <div className="bg-branco">
                    <Formik
                        className="row"
                        innerRef={formikRef}
                        initialValues={{
                        titulo: "",
                        descricao: "",
                        execucoes: "",
                        valor: 0,
                        parcela: 0,
                        id: null,
                        }}
                        validationSchema={esquema}
                        onSubmit={(values) => {
                        salvar(values);
                        }}
                    >
                        {({ errors, touched, isValid }) => (
                        <Form>
                            <div className="col-lg-12">
                            <div className="bs-component">
                                <div className="row">
                                <div className="col-md-6">
                                    <FormGroup
                                    label="Título"
                                    htmlFor="inputTipoDeProcedimento"
                                    >
                                    <Field
                                        type="text"
                                        className={`form-control ${
                                        errors.titulo && touched.titulo ? "invalid" : ""
                                        }`}
                                        name="titulo"
                                        placeholder="Insira um nome do pacote"
                                    />
                                    {errors.titulo && touched.titulo ? (
                                        <span className="text-error">{errors.titulo}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-6">
                                    <FormGroup label="Descrição" htmlFor="inputDescricao">
                                    <Field
                                        type="text"
                                        className={`form-control ${
                                        errors.descricao && touched.descricao ? "invalid" : ""
                                        }`}
                                        name="descricao"
                                        placeholder="Insira uma descrição"
                                    />
                                    {errors.descricao && touched.descricao ? (
                                        <span className="text-error">{errors.descricao}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup label="Valor R$" htmlFor="inputValor">
                                    <Field
                                        type="number"
                                        step="0.01"
                                        className={`form-control ${
                                        errors.valor && touched.valor ? "invalid" : ""
                                        }`}
                                        name="valor"
                                        placeholder="00,00"
                                    />
                                    {errors.valor && touched.valor ? (
                                        <span className="text-error">{errors.valor}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                {/* <div className="col-lg-4">
                                    <FormGroup label="Valor do desconto: " htmlFor="inputValor">
                                    <Field
                                        type="number"
                                        step="0.01"
                                        className={`form-control ${
                                        errors.valorDesconto && touched.valorDesconto
                                            ? "invalid"
                                            : ""
                                        }`}
                                        name="valorDesconto"
                                    />
                                    {errors.valorDesconto && touched.valorDesconto ? (
                                        <span className="text-error">
                                        {errors.valorDesconto}
                                        </span>
                                    ) : null}
                                    </FormGroup>
                                </div> */}
                                <div className="col-lg-4">
                                    <FormGroup
                                    label="Quantidade de consultorias"
                                    htmlFor="inputValor"
                                    >
                                    <Field
                                        type="number"
                                        step="1"
                                        className={`form-control ${
                                        errors.execucoes && touched.execucoes ? "invalid" : ""
                                        }`}
                                        name="execucoes"
                                        placeholder="Somente números"
                                    />
                                    {errors.execucoes && touched.execucoes ? (
                                        <span className="text-error">{errors.execucoes}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-lg-4">
                                    <FormGroup label="Parcelas" htmlFor="inputValor">
                                    <Field
                                        type="number"
                                        step="1"
                                        className={`form-control ${
                                        errors.execucoes && touched.execucoes ? "invalid" : ""
                                        }`}
                                        name="parcela"
                                        placeholder="0"
                                    />
                                    {errors.parcela && touched.parcela ? (
                                        <span className="text-error">{errors.parcela}</span>
                                    ) : null}
                                    </FormGroup>
                                </div>
                                </div>

                                <button
                                type="submit"
                                onClick={() => {
                                    if (!isValid) {
                                    Alert.warning("Preencha todos os campos destacados");
                                    }
                                }}
                                className={`btn ${classBtn} mb-3 mr-3`}
                                >
                                {classBtn === "btn-success-novo" ? "Salvar" : "Editar"}
                                </button>
                                <button type="reset" className="btn btn-voltar-novo mb-3">
                                    Limpar
                                </button>
                            </div>
                            </div>
                        </Form>
                        )}
                    </Formik>
                </div>
                <div className="bg-branco mt-3">
                    <Listagem
                        titulo={<h5>Pacotes Consultorias cadastrados</h5>}
                        tituloPlano
                        valor
                        descricao
                        parcela
                        paginacao={planos}
                        botaoEditar={editarPlano}
                        botaoExcluir={excluir}
                    />          
                </div>    
            </div>
            <div className="tab-pane fade" id="pja" role="tabpanel" aria-labelledby="pja-tab">
                <div className="bg-branco">
                    <Modulos setLoading={props.setLoading}/>
                </div>
            </div>
            </div> 
        </section>
    </div>
    </div>
);

};

export default Pacotes;