import React from "react";
import GoBack from "../../componentes/goBack";
import Card from "../../componentes/card";
import cadastros from "../../assets/imagens/icones/modulos.png";
import pjaModulo from "../../assets/imagens/icones/pjaModulo.png";
import cupom from "../../assets/imagens/icones/descontoIcone.png";
import AppFooterLogado from "../../componentes/AppFooterLogado";

const modulos = [
  {
    uri: "/pacotes",
    icon: cadastros,
    titulo: "Módulos",
  },
  {
    uri: "/cadastroPja",
    icon: pjaModulo,
    titulo: "PJA Legis",
  },
  {
    uri: "/cupons",
    icon: cupom,
    titulo: "Cupons de desconto",
  },
  // {
  //   uri: "/podcast",
  //   icon: "fa-podcast",
  //   titulo: "Podcast",
  // },
  // {
  //   uri: "/conteudos",
  //   icon: "fa-book",
  //   titulo: "Conteudos",
  //   permissao: ["ADM"],
  // },
];

const checaPermissao = (modulo, usuario) => {
  if (!modulo.permissao) {
    return true;
  } else {
    return modulo.permissao.find((m) => m === usuario.nivel);
  }
};

const Cadastros = (props) => {
  const { currentUser } = props;

  return currentUser ? (
    <div className="container mt-5">
      <GoBack link={"/modulos"} />
      <div id="cadastro" className="row mt-5">
        {modulos.map((modulo) => {
          return checaPermissao(modulo, currentUser) ? (
            <Card key={modulo.titulo} {...modulo} />
          ) : null;
        })}
      </div>
      <AppFooterLogado/>
    </div>
  ) : null;
};

export default Cadastros;
