import React, { useEffect } from "react";
import { validarHash, ativaeUsuario } from "../../util/Api";
import { useParams } from "react-router-dom";
import Alert from "react-s-alert";

const ConfirmarEmail = (props) => {
  const { hash } = useParams();

  useEffect(() => {
    props.setLoading(true);
    validarHash(hash)
      .then(() => {
        ativaeUsuario(hash)
          .then(() => {
            Alert.info("Email validado com sucesso");
            props.history.push("/login");
            props.setLoading(false);
          })
          .catch(() => {
            Alert.error("Hash de validação invalida");
            props.history.push("/");
            props.setLoading(false);
          });
      })
      .catch(() => {
        Alert.error("Hash de validação invalida");
        props.history.push("/");
        props.setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return <div className="container mt-5"></div>;
};

export default ConfirmarEmail;
