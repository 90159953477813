import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getSecoes, filtroTextoRol, getRolsPorTipoOrdenados } from "../util/Api";
import Select from 'react-select';
import ListaJusrisprudencia from "./ListaJusrisprudencia.jsx";
import buscador from "../assets/imagens/icones/buscador.png";
import pesquisarIcone from "../assets/imagens/icones/pesquisar.png";
import Popup from "./Popup.jsx";
// import copiar from "../assets/imagens/icones/copiar.png";
import "./listaRols.css";

const ListaRols = (props) => {
  const [rols, setRols] = useState([]);
  const [rolsTraba, setRolsTraba] = useState([]);
  const [rolsPrevid, setRolsPrevid] = useState([]);
  const [secoes, setSecoes] = useState([]);
  const [listaBusca, setListaBusca] = useState([]);
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState();
  const [filtro, setFiltro] = useState({
    idRol: null,
    idSecao: null,
    texto: "",
    tipoRol: null,
    pagina: null
  });
  const [qtdResults, setQtdResults] = useState(0);
  const [mostrarResults, setMostrarResults] = useState(false);
  const [isBuscaJuris, setIsBuscaJuris] = useState(false);
  const { setLoading } = props;
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [rolBusca, setRolBusca] = useState();
  const [rolPrevi, setRolPrevi] = useState();
  const [rolTraba, setRolTraba] = useState();
  const [secaoBusca, setSecaoBusca] = useState();
  const [tipoFind, setTipoFind] = useState("CIVEL");
  const [tipo, setTipo] = useState("CIVEL");

  const onRadioChange = e => {
    setTipo(e.target.value)
  }

  const handleRadioClick = (value) => {
    // setTipoFind(value)
    if (tipoFind === value) {
      setTipoFind('CIVEL');
    } else {
      setTipoFind(value);
    }
  };

  const tipoFormatado = (value) => {
    if (value === 'CIVEL') {
      return "Cível";
    }
    if (value === 'PREVIDENCIARIO') {
      return "Previdenciário";
    }
    if (value === 'TRABALHISTA') {
      return "Trabalhista";
    }
  };

  // const tipoBotao = (changeEvent) => {
  //   this.setTipo(changeEvent.target.value);
  // };

  const filtrar = (rolBuscaItem, secaoBuscaItem, tipo, page, filtroOverride) => {
    setIsSearchable(true);
    setIsClearable(true);
    setLoading(true);
    setButtonPopup(true);
    const novo = filtroOverride || { ...filtro, idRol: rolBuscaItem?.value, idSecao: secaoBuscaItem?.value, tipoRol: tipo, pagina: page || 1 };
    // console.log(novo);
    setPage(page || 1)
    setFiltro(novo);
    filtroTextoRol(novo).then((data) => {
      // console.log(data);
      setQtdResults(data.totalResults);
      setTotalPages(data.totalPages);
      setListaBusca(data.results.sort((a, b) => {
        const a1 = a.decreto.toUpperCase();
        const a2 = b.decreto.toUpperCase();

        if (a1 > a2) return 1;
        if (a1 < a2) return -1;
        return 0;
      }));
      setMostrarResults(true);
      setLoading(false);
    });
  };

  // useEffect(() => {
  //   if(tipoFind === 'CIVEL'){
  //     setRolPrevi('');
  //     setRolTraba('');
  //   }

  //   if(tipoFind === 'PREVIDENCIARIO'){
  //     setRolBusca('');
  //     setRolTraba('');
  //   }

  //   if(tipoFind === 'TRABALHISTA'){
  //     setRolPrevi('');
  //     setRolBusca('');
  //   }
  // }, [tipoFind]);

  useEffect(() => {
    // getRolsOrdenados().then((data) => {
    //   setRols(data);
    // });
    // getRolsPorTipoOrdenados(tipoFind).then((data) => {
    //   // console.log('rols tipo', data)
    //   setRols(data);
    // });
    getSecoes().then((data) => {
      setSecoes(data);
    });

    getRolsPorTipoOrdenados("CIVEL").then((data) => {
      // console.log('rols tipo', data)
      setRols(data);
    });

    getRolsPorTipoOrdenados("PREVIDENCIARIO").then((data) => {
      // console.log('rols tipo', data)
      setRolsPrevid(data);
    });

    getRolsPorTipoOrdenados("TRABALHISTA").then((data) => {
      // console.log('rols tipo', data)
      setRolsTraba(data);
    });

    // getRolsPorTipoOrdenados(tipoFind).then((data) => {
    //   // console.log('rols tipo', data)
    //   // console.log('tipo do find: ', tipoFind)
    //   if(tipoFind === 'CIVEL'){
    //     setRols(data);
    //     setRolPrevi('');
    //     setRolTraba('');
    //   }

    //   if(tipoFind === 'PREVIDENCIARIO'){
    //     setRolsPrevid(data);
    //     setRolBusca('');
    //     setRolTraba('');
    //   }

    //   if(tipoFind === 'TRABALHISTA'){
    //     setRolsTraba(data);
    //     setRolPrevi('');
    //     setRolBusca('');
    //   }
    // });
  }, []);

  const [buttonPopup, setButtonPopup] = useState(false)

  const ArrowLeft = "fa fa-step-backward"
  const ArrowBigLeft = "fa fa-fast-backward"
  const ArrowRight = "fa fa-step-forward"
  const ArrowBigRight = "fa fa-fast-forward"

  const updatePages = (term) => {
    setFiltro((prevFiltro) => {
      // console.log(filtro)
      let newPage;
      switch (term) {
        case "backAll":
          newPage = 1;
          break;

        case "back":
          newPage = page > 1 ? page - 1 : 1;
          break;

        case "forward":
          newPage = page < totalPages ? page + 1 : page;
          break;

        case "forwardAll":
          newPage = totalPages;
          break;

        default:
          console.error("Opção inválida");
          return prevFiltro;
      }

      const updatedFiltro = { ...prevFiltro, pagina: newPage };
      setPage(newPage);
      filtrar(updatedFiltro.idRol, updatedFiltro.idSecao, updatedFiltro.tipoRol, newPage, updatedFiltro);
      return updatedFiltro;
    });
  };

  return (
    <div className="">
      <Popup trigger={buttonPopup}>
        <div className="popup-header">
          <button type="button" className="btnPopup" onClick={(e) => setButtonPopup(false)}>✖</button>
          <p className="popup-title">Resultados</p>
          <div className="col-7 my-2">
            <h5 className="resultsEncontrados">{mostrarResults ? qtdResults > 1 ? qtdResults + ' resultados encontrados' : qtdResults + ' resultado encontrado' : null}</h5>
          </div>
        </div>
        <section className="container-jurisprudencia" style={{ marginTop: "10px" }}>
          {listaBusca.map((l) => (
            <div className="col-12 container-jurisprudencia-sem my-2" key={l.id}>
              <span className="fontNegrito">
                <b>Ação: </b>
              </span>
              <span>{l.rol.titulo}</span>
              {l.rol.tipo ?
                <>
                  <br />
                  <span className="fontNegrito">
                    <b>Tipo: </b>
                  </span>
                  <span>{tipoFormatado(l.rol.tipo)}</span>
                </> :
                <>

                </>
              }
              <br />
              <span className="fontNegrito">
                <b>Subtítulo: </b>
              </span>
              <span>{l.subtitulo}</span>
              {/* <button className="btn btnCopiar ml-5" type="button">
                  <img src={copiar ? copiar : null} className="mx-1" alt="botão copiar"/>
                </button> */}
              <br />
              <span className="fontNegrito">
                <b>Seção: </b>
              </span>
              <span>{l.secao.titulo}</span>
              <br />
              <span className="fontNegrito">
                <b>Fonte normativa: </b>
              </span>
              <span>{l.decreto}</span>
              <br />
              <span className="fontNegrito">
                {l.secao.titulo === 'VÍDEOS RECOMENDADOS' && (l.descricao.includes('https') || l.descricao.includes('youtube')) ? (
                  <b>Vídeo recomendado: </b>
                ) : (
                  <b>Descrição: </b>
                )}
              </span>
              {l.secao.titulo === 'VÍDEOS RECOMENDADOS' && (l.descricao.includes('https') || l.descricao.includes('youtube')) ? (
                <span><a href={l.descricao} target="_blank" rel="noopener noreferrer">Clique aqui</a></span>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: l.descricao }} />
              )}
              <hr />
            </div>
          ))}
        </section>
        {qtdResults && (
          <div className="paginacao" style={{ bottom: "20px", width: "97.5%", margin: "0 0 0 4px" }}>
            <span style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="input-group-prepend tipo2"
                onClick={() => updatePages("backAll")}
              >
                <span className="input-group-text" id="basic-addon2">
                  <span className={`tipo ${ArrowBigLeft}`}></span>
                </span>
              </div>
              <div
                className="input-group-prepend tipo2"
                onClick={() => updatePages("back")}
              >
                <span className="input-group-text" id="basic-addon2">
                  <span className={`tipo ${ArrowLeft}`}></span>
                </span>
              </div>
            </span>
            <div
              className="input-group-prepend tipo2"
              style={{ width: "100%", cursor: "default" }}
            >
              <span className="input-group-text" id="basic-addon2" style={{ width: "100%", justifyContent: "center" }}>
                {qtdResults ? (
                  <>Página {page} de {totalPages}</>
                ) : (
                  <>Carregando...</>
                )}
              </span>
            </div>
            <span style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="input-group-prepend tipo2"
                onClick={() => updatePages("forward")}
              >
                <span className="input-group-text" id="basic-addon2">
                  <span className={`tipo ${ArrowRight}`}></span>
                </span>
              </div>
              <div
                className="input-group-prepend tipo2"
                onClick={() => updatePages("forwardAll")}
              >
                <span className="input-group-text" id="basic-addon2">
                  <span className={`tipo ${ArrowBigRight}`}></span>
                </span>
              </div>
            </span>
          </div>
        )}
      </Popup>
      <div className="container-fluid">
        <div className="row mt-1">
          <div className="col-12">
            <h5><img src={buscador ? buscador : null} className="mx-1" alt="botão buscador" />Buscador</h5>
          </div>
          <div className="col-12">
            <p className="pAcao">
              A pesquisa pode ser realizada por Ações, e as Seções a serem consultadas incluem: Previsão Legal, Jurisprudência e Doutrina.
            </p>
          </div>
          <hr className="widHR" />
          <section className="text-center ml-5">
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadioInline1" name="customRadioInline" checked={isBuscaJuris ? false : true} onClick={() => setIsBuscaJuris(false)} className="custom-control-input"></input>
              <label className="custom-control-label" for="customRadioInline1">Seções</label>
            </div>
            <div className="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadioInline2" name="customRadioInline" onClick={() => setIsBuscaJuris(true)} className="custom-control-input"></input>
              <label className="custom-control-label" for="customRadioInline2">Buscador Jurisprudência</label>
            </div>
          </section>
          <hr className="widHR" />
        </div>
        <div className="row">
          {!isBuscaJuris ? (
            <>
              <div className="">
                {/* <hr className="widHR" /> */}
                <section className="text-center ml-2">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioFind1" name="customFind" value="CIVEL" onClick={() => handleRadioClick("CIVEL")} checked={tipo === "CIVEL"} onChange={onRadioChange} className="custom-control-input"></input>
                    <label className="custom-control-label" for="customRadioFind1">Cível</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioFind2" name="customFind" value="PREVIDENCIARIO" onClick={() => handleRadioClick("PREVIDENCIARIO")} checked={tipo === "PREVIDENCIARIO"} onChange={onRadioChange} className="custom-control-input"></input>
                    <label className="custom-control-label" for="customRadioFind2">Previdenciário</label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioFind3" name="customFind" value="TRABALHISTA" onClick={() => handleRadioClick("TRABALHISTA")} checked={tipo === "TRABALHISTA"} onChange={onRadioChange} className="custom-control-input"></input>
                    <label className="custom-control-label" for="customRadioFind3">Trabalhista</label>
                  </div>
                </section>
                <hr className="widHR" />
              </div>
              <div className="col-12 my-2">
                {tipoFind === 'CIVEL' ?
                  <>
                    <label>Ação Cível</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione"
                      // defaultValue={rols[0]}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="rols"
                      options={
                        rols.map((rol) => (
                          { value: rol?.id, label: rol?.titulo }
                        ))
                      }
                      onChange={setRolBusca}
                      style={{
                        color: 'hsl(0, 0%, 0%)',
                        display: 'inline-block',
                        fontSize: 12,
                        fontStyle: 'italic',
                        marginTop: '1em',
                      }}
                    />
                  </>
                  : null}

                {tipoFind === 'PREVIDENCIARIO' ?
                  <>
                    <label>Ação Previdenciária</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione"
                      // defaultValue={rols[0]}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="rols"
                      options={
                        rolsPrevid.map((rol) => (
                          { value: rol?.id, label: rol?.titulo }
                        ))
                      }
                      onChange={setRolPrevi}
                      style={{
                        color: 'hsl(0, 0%, 0%)',
                        display: 'inline-block',
                        fontSize: 12,
                        fontStyle: 'italic',
                        marginTop: '1em',
                      }}
                    />
                  </>
                  : null}

                {tipoFind === 'TRABALHISTA' ?
                  <>
                    <label>Ação Trabalhista</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Selecione"
                      // defaultValue={rols[0]}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      name="rols"
                      options={
                        rolsTraba.map((rol) => (
                          { value: rol?.id, label: rol?.titulo }
                        ))
                      }
                      onChange={setRolTraba}
                      style={{
                        color: 'hsl(0, 0%, 0%)',
                        display: 'inline-block',
                        fontSize: 12,
                        fontStyle: 'italic',
                        marginTop: '1em',
                      }}
                    />
                  </>
                  : null}

              </div>
              <div className="col-12">
                <label>Seções</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Selecione"
                  // defaultValue={rols[0]}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  name="secoes"
                  options={
                    secoes.map((secao) => (
                      { value: secao?.id, label: secao?.titulo }
                    ))
                  }
                  onChange={setSecaoBusca}
                  style={{
                    color: 'hsl(0, 0%, 0%)',
                    display: 'inline-block',
                    fontSize: 12,
                    fontStyle: 'italic',
                    marginTop: '1em',
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <ReactTooltip />
                <label>
                  Pesquise por palavras
                  <i
                    className="fa fa-info ml-2"
                    data-tip="separe as palavras que deseja buscar por ; ex: carro; casa; avião"
                  />
                </label>
                <input
                  type="text"
                  placeholder="digite palavras ou texto que deseja buscar"
                  className="form-control"
                  value={filtro.texto}
                  onChange={(e) => setFiltro({ ...filtro, texto: e.target.value })}
                />
                <hr />
              </div>
              <div className="col-7 my-2">
                <h5 className="resultsEncontrados">{mostrarResults ? qtdResults > 1 ? qtdResults + ' resultados encontrados' : qtdResults + ' resultado encontrado' : null}</h5>
              </div>
              <div className="col-5 my-2">
                {tipoFind === 'CIVEL' ?
                  <>
                    <button className="btn btnRolsPesquisar btn-sm" type="button" onClick={() => { filtrar(rolBusca, secaoBusca, tipo); setQtdResults(null) }}>
                      <img src={pesquisarIcone ? pesquisarIcone : null} className="mx-1" alt="botão pesquisar" />
                      Buscar
                    </button>
                  </>
                  :
                  null
                }

                {tipoFind === 'PREVIDENCIARIO' ?
                  <>
                    <button className="btn btnRolsPesquisar btn-sm" type="button" onClick={() => { filtrar(rolPrevi, secaoBusca, tipo); setQtdResults(null) }}>
                      <img src={pesquisarIcone ? pesquisarIcone : null} className="mx-1" alt="botão pesquisar" />
                      Buscar
                    </button>
                  </>
                  :
                  null
                }

                {tipoFind === 'TRABALHISTA' ?
                  <>
                    <button className="btn btnRolsPesquisar btn-sm" type="button" onClick={() => { filtrar(rolTraba, secaoBusca, tipo, 1); setQtdResults(null) }}>
                      <img src={pesquisarIcone ? pesquisarIcone : null} className="mx-1" alt="botão pesquisar" />
                      Buscar
                    </button>
                  </>
                  :
                  null
                }
              </div>
              {/* {listaBusca.length === 0 ? <p>Sem dados</p> : null} */}
            </>
          ) : (
            <>
              <div className="col-md-12">
                <ListaJusrisprudencia setLoading={setLoading} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListaRols;
