export const ACCESS_TOKEN = "accessToken";

const urls = {
  dev: "http://localhost:3000",
  test: "https://ilegis.polygonsolucoes.com.br",
  prod: "https://app.ilegis.com.br",
};

const apiUrls = {
  dev: "http://localhost:8080",
  test: "https://apiilegis.polygonsolucoes.com.br",
  prod: "https://api.ilegis.com.br",
};

const chaveReCaptcha = {
  dev: "6LfkWvoUAAAAAK2g1f-5_4CFfAh4P_9nMkegQqqJ",
  test: "6LfkWvoUAAAAAK2g1f-5_4CFfAh4P_9nMkegQqqJ",
  prod: "6LfR8mMqAAAAANcskZOvBvrcKszH-znHq8LXtuWN",
};
  //prod: "6LfCMJIlAAAAAKHATuY65_YLFHog0JgAPCBiIqzy",

export const API_BASE_URL = apiUrls[process.env.REACT_APP_STAGE]
  ? apiUrls[process.env.REACT_APP_STAGE]
  : apiUrls.dev;

export const API_BASE_URL_IMAGE = API_BASE_URL + "/publico/foto/";

export const CHAVE_RECAPTCHA = chaveReCaptcha[process.env.REACT_APP_STAGE]
  ? chaveReCaptcha[process.env.REACT_APP_STAGE]
  : chaveReCaptcha.dev;

export const URL_NOW = urls[process.env.REACT_APP_STAGE]
  ? urls[process.env.REACT_APP_STAGE]
  : urls.dev;

export const IS_PROD = process.env.REACT_APP_STAGE === urls.prod ? true : false;
