import React from "react";
import "./campos.css"

function FormGroup(props) {
  return (
    <div className="form-group campos">
      <label htmlFor={props.htmlFor}>{props.label}</label>
      {props.children}
    </div>
  );
}

export default FormGroup;
