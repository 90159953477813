import React, { useEffect, useState } from "react";
import GoBack from "../../componentes/goBack";
import { Container, Modal, Button } from "react-bootstrap";
import { getPlanos, getCnsultoriaById, getConsultoriaByIdByStatus,
  filtroConsultoria, finalizarConsultoria, getCnsultoria } from "../../util/Api";
import { useParams } from "react-router-dom";
import "./index.css";
import AppFooterLogado from "../../componentes/AppFooterLogado";
import Listagem from "../../componentes/Listagem";
import Consultoria from "../../paginas/Consultoria";
import iconeX from "../../assets/imagens/icones/x-circle.png";
import { Link } from "react-router-dom";
import aprovada from "../../assets/imagens/icones/consultoria.png";
import aberta from "../../assets/imagens/icones/aberta.png";
import finalizada from "../../assets/imagens/icones/finalizada.png";
import vendido from "../../assets/imagens/icones/vendido.png";
import moment from "moment";


const HelpLegis = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const [planos, setPlanos] = useState([]);
  const [plano, setPlano] = useState({});

  const [pagina, setPagina] = useState({ content: [] });
  const [filtro, setFiltro] = useState({});

  const [isConsultoriaAntes, setIsConsultoriaAntes] = useState();
  const [consultoriaPorStatus, setConsultoriaPorStatus] = useState([]);
  const [tabNow, setTabNow] = useState("pacotes-tab");
  const qtdConsultorias = props.currentUser.consultorias;
  const { setLoading, currentUser, loadCurrentlyLoggedInUser } = props;
  const [ativo, setAtivo] = useState(false);
  const handleClose = () => setAtivo(false);
  const [consultoriaMostrar, setConsultoriaMostrar] = useState(null);
  const [consultoria, setConsultoria] = useState(null);

  function enviarPlano(plano) {
    // window.location.push("/consultoria/" + plano.id);
    props.history.push("/pagamento/" + plano.id);
    // setPlano(plano);
    // setIsOpen(true);
  }

  const pageChange = (pagina) => {
    const novoFiltro = { ...filtro, pagina: pagina };
    setFiltro(novoFiltro);
    setLoading(true);
    filtroConsultoria(novoFiltro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getPlanos().then((data) => {
      setPlanos(data);
      props.setLoading(false);
      if (id) {
        setPlano(data.find((p) => p.id === parseInt(id)));
        setIsOpen(true);
      }
    });

    filtroConsultoria(filtro).then((data) => {
      data.changePage = pageChange;
      setPagina(data);
      setLoading(false);
    });

    getCnsultoriaById(props.currentUser.id).then((data) => {
      setIsConsultoriaAntes(data);
      props.setLoading(false);
    });

    getConsultoriaByIdByStatus(props.currentUser.id).then((data) => {
      setConsultoriaPorStatus(data);
      props.setLoading(false);
    });

    if (consultoriaMostrar) {
      setLoading(true);
      getCnsultoria(consultoriaMostrar).then((data) => {
        setLoading(false);
        setConsultoria(data);
      });
    }
    loadCurrentlyLoggedInUser("/help-legis");
    // eslint-disable-next-line
  }, [consultoriaMostrar]);

  const goVisualizar = (item) => {
    setConsultoriaMostrar(item.id);
    setAtivo(true);
    // props.history.push("/consultoria/" + item.id);
  };

  return (
    <div className="bg-light">
      <Modal
        id="modCome"
        show={ativo}
        onHide={() => setAtivo(false)}
        size="xl"
        className="modal-xl animate__animated"
        >
        <Modal.Header className="row text-center">
          <div className="col-md-2">
            <Button className="btn buttonBaixarPeticao animate__animated animate__fadeInUpBig" onClick={handleClose}>
              Fechar
            </Button>
          </div>
          <Modal.Title className="col-md-4">
            {consultoria ? (
            <>
              <h5 className="titModal animate__animated animate__fadeInUpBig">Consultoria n° {consultoria.id} de{" "}
                {moment(consultoria.data).format("DD/MM/YYYY")}
                {" - "}
                {consultoria.status ? "Aberta" : "Finalizada"}
              </h5>  
            </>
            ) : null}
          </Modal.Title>
          <div className="col-md-6">
            <button className="btn btn-sm btnNoBack animate__animated animate__fadeInUpBig" type="button" onClick={handleClose}><img src={iconeX ? iconeX : null} className="mx-1" alt="botão fechar"/></button>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center row">
          <Consultoria 
            currentUser={currentUser} 
            setLoading={setLoading} 
            id={consultoriaMostrar} 
            loadCurrentlyLoggedInUser={loadCurrentlyLoggedInUser}
          />
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
      <Container className="pt-5">
          <GoBack link={"/modulos"} />
          {/* eu vou colocar o redirecionamento caso tenha consultas para a listagem e se tiver consultas, porém acabadas deixar um botão para ir ver as consultorias antigas */}

          {props.currentUser.consultorias > 0 || props.currentUser.nivel === "ADM" || isConsultoriaAntes > 0 ? (
              <>
                <div className="row">
                  {isOpen ? null : null}
                  {plano ? null : null}
                  {tabNow ? null : null}
                  <div className="col-md-8">
                    <h4 className="textoPJALegis">Help Legis</h4>
                  </div>
                  <div id="solicita" className="col-md-4 mb-2">
                    {props.currentUser.nivel !== "ADM" ? (
                      <>
                        <Link to="/consultoria" className="btn buttonSalvarPeticao">
                          Solicitar
                        </Link>
                      </>
                    ) : null }
                    <Link to="/modulos" className="btn buttonBaixarPeticao mx-2">
                      Voltar
                    </Link>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-md-3 mx-1 cardConsultas">
                      <div className="float-left mt-3">
                        <img src={aprovada ? aprovada : null} className="mx-1" alt="botão aprovada"/>
                      </div>
                      <div className="mt-4">
                        <p className="pStatusConsultas">
                        {props.currentUser.nivel === "CLIENTE" ? 
                        <>{qtdConsultorias > 1 ? "Consultorias aprovadas" : "Consultoria aprovada"}</> :
                        <>{consultoriaPorStatus.consultoriasAprovadas > 1 ? "Consultorias aprovadas" : "Consultoria aprovada"}</>}
                          <br />
                          <span className="nStatusConsultas">
                            {props.currentUser.nivel === "CLIENTE" ?
                              <>
                                {qtdConsultorias}
                              </> : 
                              <>
                                {consultoriaPorStatus.consultoriasAprovadas === null ? 0 : consultoriaPorStatus.consultoriasAprovadas}
                              </>
                            }
                          </span>
                        </p>
                      </div>
                  </div>  
                  <div className="col-md-3 mx-1 cardConsultas">
                    <div className="float-left mt-3">
                      <img src={aberta ? aberta : null} className="mx-1" alt="botão aprovada"/>
                    </div>
                    <div className="mt-4">
                      <p className="pStatusConsultas">
                        {consultoriaPorStatus.consultoriasAbertas > 1 ? "Consultorias abertas" : "Consultoria aberta"}
                        <br />
                        <span className="nStatusConsultas">
                            {consultoriaPorStatus.consultoriasAbertas}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 mx-1 cardConsultas">
                    <div className="float-left mt-3">
                      <img src={finalizada ? finalizada : null} className="mx-1" alt="botão aprovada"/>
                    </div>
                    <div className="mt-4">
                      <p className="pStatusConsultas">
                        {consultoriaPorStatus.consultoriasFinalizadas > 1 ? "Consultorias finalizadas" : "Consultoria finalizada"}
                        <br />
                        <span className="nStatusConsultas">
                            {consultoriaPorStatus.consultoriasFinalizadas}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                <section className="pt-3 col-md-12">
                    <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
                      <li className="nav-item col-md-6">
                          <a className="nav-link active color" id="pacotes-tab" data-toggle="tab" href="#pacotesTab" role="tab" aria-controls="pacotesTab" aria-selected="true" onClick={() => setTabNow("pacotes-tab")}>Consultorias</a>
                      </li>
                      {/* <li className="nav-item col-md-6">
                          <a className="nav-link color" id="pja-tab" data-toggle="tab" href="#pja" role="tab" aria-controls="pja" aria-selected="false" onClick={() => setTabNow("pja")}>PJA</a>
                      </li> */}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="pacotesTab" role="tabpanel" aria-labelledby="pacotes-tab">
                        <div className="bg-branco">
                          <Listagem
                              data
                              cliente={currentUser?.nivel === "CLIENTE"}
                              statusConsultoria
                              verConsultoria
                              acaoConsultoria
                              tituloConsultoria
                              paginacao={pagina}
                              setIten={goVisualizar}
                              custonConfirmFunction={async (item) => {
                                await finalizarConsultoria(item.id);
                                await pageChange(filtro.pagina);
                              }}
                              custonConfirmTitulo="Deseja mesmo finalizar esta consultoria?"
                              custonConfirmLabel="Após finalizada, o cliente não poderá mais enviar mensagens, deseja continuar?"
                              currentUser={currentUser}
                          />
                      </div>    
                    </div>
                    <div className="tab-pane fade" id="pja" role="tabpanel" aria-labelledby="pja-tab">
                        <div className="bg-branco">
                            
                        </div>
                    </div>
                    </div> 
                </section>    
                </div>
              </>
            ) : (
              <>
              <div className="row fundoHelpNao">
                <div className="col-5 mt-2">
                  <p className="escritoPena p-4">
                    Ah que pena :( <br /> No momento você não possui nenhum <br />{" "}
                    plano ativo. Comece um agora mesmo
                  </p>
                </div>
                {/* <div className="col-12 text-center my-3">
                  <PagseguroPaymatch
                    loadCurrentlyLoggedInUser={props.loadCurrentlyLoggedInUser}
                    plano={plano}
                    modulo={false}
                    setLoading={props.setLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </div> */}
                <div className="col-md-2 pjaNomeEmbaixo">Help Legis</div>
                <div className="m-1">
                {planos.filter(p => p.titulo === "Consulta Individual").map(plano => {
                  return (
                    <section className="fundoPjaCard mt-3">
                      <h2 className="tituloCardPja p-3">
                        Help Legis - {plano.titulo}
                      </h2>
                      <div className="cardPreco mx-auto cardPrecoBorda">
                        <h4 className="text-center corPlano pt-4">
                          <span className="apartirDe">A partir de</span>
                          <br />
                          {(plano.valor/1).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                        </h4>
                      </div>
                      <button
                        type="button"
                        onClick={() => enviarPlano(plano)}
                        className="btn botaoNovoContratar mx-auto mt-4">
                        Começar agora
                      </button>
                    </section>
                 );
                })}    
                </div>
                
                {/* <p className="paraPagar">Nosso sistema não armazena nenhum meio de pagamento. <br/> Todo processo de compra é feito pelo nosso parceiro Mercado Pago</p> */}
                {isConsultoriaAntes > 0 ? (
                  <>
                    <div className="col-12">
                      <Link to="/consultorias" className="btn buttonAtualizarListagem">
                        Ver minhas consultorias antigas
                      </Link>
                    </div>
                  </>
                ): null}
              </div>

              <div className="row">
                <h5 className="col-md-12 my-3">Confira nossos planos</h5>
                {planos.filter(plano => plano.titulo !== "Consulta Individual").map(p => {
                  return (
                    <div className="col-md-3">
                      {/* className={`container ${isFixed ? "fixed-bottom mb-5" : "my-2"}`} */}
                      <div
                        className={`${
                          p.titulo === "Ouro"
                            ? "cardNovoPlanosOuro"
                            : "cardNovoPlanos"
                        } `}
                        key={p.id}
                      >
                        {p.titulo === 'Ouro' ? (<><img src={vendido ? vendido : null} className="mx-1 maisVendido text-center" alt="botão mais vendido"/></>) : null}
                        <div className={``}>
                          <div className="">
                            <h2
                              className={`${
                                p.titulo === "Ouro"
                                  ? "tituloCardNovoOuro"
                                  : "tituloCardNovo"
                              } mt-3 pt-3`}
                            >
                              {p.titulo}
                            </h2>
                          </div>
                          <div className="text-center">
                            <p
                              className={`${
                                p.titulo === "Ouro"
                                  ? "paragrafoCardNovoOuro"
                                  : "paragrafoCardNovo"
                              }`}
                            >
                              {p.descricao}
                            </p>
                            <hr
                              className={`${
                                p.titulo === "Ouro" ? "hrClassOuro" : "hrClass"
                              }`}
                            />
                            <h4 className={`${
                                p.titulo === "Ouro" ? "corPlanoBranco" : "corPlano"
                              }`}>
                              {" "}
                              <span className="text-muted">{p.parcela}x</span>{" "}
                              {(p.valor / p.parcela).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h4>
                            <button
                              // onClick={() => {
                              //     setAtivo(true);
                              // }}
                              type="button"
                              className={`btn ${
                                p.titulo === "Ouro"
                                  ? "buttonContratarPacotesOuro"
                                  : "buttonContratarPacotes"
                              } mt-5`}
                              onClick={() => enviarPlano(p)}
                            >
                              Começar agora
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <p className="pDescPlanoNovo">
                          <small className="text-muted">
                            ***Total:{" "}
                            {(p.valor - p.valorDesconto).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </small>
                          <br />
                          <small className="text-muted">
                            {(p.valor / p.execucoes).toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}{" "}
                            cada consultoria
                          </small>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              </>
            )}
      </Container>
      <AppFooterLogado isFixed={false}></AppFooterLogado>
    </div>
  );
};

export default HelpLegis;
