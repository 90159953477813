import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Alert from "react-s-alert";
import ReactTooltip from "react-tooltip";
import { getJurisprudencia, getJurisprudenciaCompleto } from "../util/Api";
import pesquisarIcone from "../assets/imagens/icones/pesquisar.png";
// import copiar from "../assets/imagens/icones/copiar.png";
import iconeX from "../assets/imagens/icones/x-circle.png";
import "./listaRols.css";
import PopupJurisprudencia from "./PopupJurisprudencia";

import filterlist from '../util/filtro.json'
import BotaoFiltro from "./BotaoFiltro";

const ListaRols = (props) => {
  const [busca, setBusca] = useState({ items: [] });
  const [ativo, setAtivo] = useState(false);
  const [dados, setDados] = useState({});
  const [filtro, setFiltro] = useState({
    inicio: null,
    fim: null,
    texto: "",
    pagina: 1,
  });
  const [qtdResults, setQtdResults] = useState();
  const [totalPages, setTotalPages] = useState();
  const [mostrarResults, setMostrarResults] = useState(false);

  const Filter = "fa fa-filter";
  const [showFilter, setShowFilter] = useState(false)

  const ArrowLeft = "fa fa-step-backward"
  const ArrowBigLeft = "fa fa-fast-backward"
  const ArrowRight = "fa fa-step-forward"
  const ArrowBigRight = "fa fa-fast-forward"

  function handleVisibility() {
    if (showFilter)
      setShowFilter(false)
    else
      setShowFilter(true)
  }

  const filterRef = useRef(null);

  // Event listener to handle clicks outside of the filter component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filtrar = (filtro) => {
    if (!filtro.texto || filtro.texto === "") {
      Alert.warning(
        "Preencha o campo texto para efetuar a busca de jurisprudências."
      );
      return;
    }

    props.setLoading(true);

    getJurisprudencia(filtro).then((data) => {
      // console.log(data)
      if (!data?.HasError && typeof data === "object") {
        let dados = {};
        dados.items = data.items.map(item => {
          return {
            ...item,
            ementa: item?.ementa
              ?.replace(`${filtro.texto}`, `${filtro.texto}`)
              .replace(`${filtro.texto.toUpperCase()}`, `${filtro.texto.toUpperCase()}`)
          }
        });
        setBusca(dados);
        setMostrarResults(true);
        setQtdResults(data.paginator.total);
        setTotalPages(data.paginator.total_pages)
      } else {
        Alert.warning("Ops! Houve um erro, tente novamente mais tarde.");
      }
      props.setLoading(false);
    });
  };

  const buscar = () => {
    let queryString = localStorage.getItem("URL") || "pagina=1&";
    localStorage.setItem("URL", queryString);

    let novo = { ...filtro, pagina: 1, filters: queryString };
    setFiltro(novo);
    filtrar(novo);
    setButtonPopup(true);
    // console.log(novo);
  };

  const proxima = () => {
    let novo = { ...filtro, pagina: filtro.pagina + 1 };
    setFiltro(novo);
    filtrar(novo);
  };

  const anterior = () => {
    let novo = { ...filtro, pagina: filtro.pagina - 1 };
    setFiltro(novo);
    filtrar(novo);
  };

  const [buttonPopup, setButtonPopup] = useState(false)

  const [filterCount, setFilterCount] = useState(0); // State to keep track of selected filters

  // Função para atualizar o contador de filtros selecionados
  const updateFilterCount = (selectedFilters) => {
    const count = Object.values(selectedFilters).reduce((acc, filters) => acc + filters.length, 0);
    setFilterCount(count);
  };

  // useEffect para definir o valor inicial de filterCount ao carregar o componente
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('selectedFilters'));
    if (savedFilters) {
      updateFilterCount(savedFilters);
    }
  }, []);

  const [page, setPage] = useState(1)

  const updatePages = (term) => {
    const oldURL = localStorage.getItem("URL");

    switch (term) {
      case "backAll":
        setPage(() => {
          const newPage = 1
          const updatedURL = oldURL.replace(/(pagina=)\d+/, `pagina=${newPage}`);
          localStorage.setItem("URL", updatedURL);
          // console.log(updatedURL);
          buscar();
          return newPage;
        });
        break;

      case "back":
        if (page > 1) {
          setPage(prevPage => {
            const newPage = prevPage - 1;
            const updatedURL = oldURL.replace(/(pagina=)\d+/, `pagina=${newPage}`);
            localStorage.setItem("URL", updatedURL);
            // console.log(updatedURL);
            buscar();
            return newPage;
          });
        }
        break;

      case "forward":
        if (page < totalPages) {
          setPage(prevPage => {
            const newPage = prevPage + 1;
            const updatedURL = oldURL.replace(/(pagina=)\d+/, `pagina=${newPage}`);
            localStorage.setItem("URL", updatedURL);
            // console.log(updatedURL);
            buscar();
            return newPage;
          });
        }
        break;

      case "forwardAll":
        setPage(() => {
          const newPage = totalPages
          const updatedURL = oldURL.replace(/(pagina=)\d+/, `pagina=${newPage}`);
          localStorage.setItem("URL", updatedURL);
          // console.log(updatedURL);
          buscar();
          return newPage;
        });
        break;

      default:
        console.error("Opção inválida");
    }
  };

  return (
    <div className="">
      <PopupJurisprudencia trigger={buttonPopup}>
        <div className="adjust-size">
          <div className="popup-header2">
            <button type="button" className="btnPopup" onClick={(e) => setButtonPopup(false)}>✖</button>
            <p className="popup-title">Resultados</p>
            <div className="col-7 my-2">
              <h5 className="resultsEncontrados">{mostrarResults ? qtdResults > 1 ? qtdResults + ' resultados encontrados' : qtdResults + ' resultado encontrado' : null}</h5>
            </div>
          </div>
          <section className="container-jurisprudencia2">
            {busca.items && busca.items.map((l) => (
              <div className="col-12 container-jurisprudencia-sem my-1" key={l.id}>
                <span>
                  <b>Tipo: </b>
                  {l.tipo}
                </span>
                {/* <button className="btn btnCopiar ml-5" type="button">
                        <img src={copiar ? copiar : null} className="mx-1" alt="botão copiar"/>
                  </button> */}
                <br />
                <span>
                  <b>Recurso: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.recurso_nome }} />
                </span>
                <br />
                <span>
                  <b>Número: </b>
                  {l.numero}
                </span>
                <br />
                <span>
                  <b>Enunciado: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.enunciado }}></div>
                </span>
                <br />
                {l?.origem?.nome ? (
                  <span>{`${l.origem.nome} - ${l.origem.sigla}`}</span>
                ) : null}
                <br />
                <span>
                  <b>Órgão: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.orgao_julgador }} />
                </span>
                <br />
                <span>{`Julgamento: ${l.data_julgamento} - Publicação: ${l.data_publicacao ? l.data_publicacao : "-"
                  }`}
                </span>
                <br />
                <span>
                  <b>Tribunal: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.tribunal.sigla }} />
                </span>
                <br />
                <span>
                  <b>Ementa: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.ementa }} />
                </span>
                <br />
                <span>
                  <b>Decisão: </b>
                  <div dangerouslySetInnerHTML={{ __html: l.decisao }} />
                </span>
                <br />
                <span>
                  <b>Resumo: </b>
                  <div>
                    {l.snippets?.inteiro_teor?.fieldsnippets?.[0]?.content && (
                      <div dangerouslySetInnerHTML={{ __html: l.snippets.inteiro_teor.fieldsnippets[0].content }} />
                    )}
                    {l.snippets?.ementa?.fieldsnippets?.[0]?.content && (
                      <div dangerouslySetInnerHTML={{ __html: l.snippets.ementa.fieldsnippets[0].content }} />
                    )}
                  </div>
                </span>
                <br />
                <button className="btn btn-info btn-sm" onClick={() => {
                  props.setLoading(true);
                  getJurisprudenciaCompleto(l.link_api, filtro) // l.link_api, filtro 
                    .then((data) => {
                      setDados(data)
                      setAtivo(true)
                      props.setLoading(false);
                    })
                    .catch(() => {
                      props.setLoading(false);
                      Alert.warning("Ops! Houve um erro ao buscar o texto completo, tente novamente mais tarde.", { timeout: 15000 })
                    })
                }}>Visualizar completo <i className="fa fa-eye" /></button>
                <hr />
              </div>
            ))}
          </section>
          {qtdResults && (
            <div className="paginacao">
              <span style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="input-group-prepend tipo2"
                  onClick={() => updatePages("backAll")}
                >
                  <span className="input-group-text" id="basic-addon2">
                    <span className={`tipo ${ArrowBigLeft}`}></span>
                  </span>
                </div>
                <div
                  className="input-group-prepend tipo2"
                  onClick={() => updatePages("back")}
                >
                  <span className="input-group-text" id="basic-addon2">
                    <span className={`tipo ${ArrowLeft}`}></span>
                  </span>
                </div>
              </span>
              <div
                className="input-group-prepend tipo2"
                style={{ width: "100%", cursor: "default" }}
              >
                <span className="input-group-text" id="basic-addon2" style={{ width: "100%", justifyContent: "center" }}>
                  {qtdResults ? (
                    <>Página {page} de {totalPages}</>
                  ) : (
                    <>Carregando...</>
                  )}
                </span>
              </div>
              <span style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="input-group-prepend tipo2"
                  onClick={() => updatePages("forward")}
                >
                  <span className="input-group-text" id="basic-addon2">
                    <span className={`tipo ${ArrowRight}`}></span>
                  </span>
                </div>
                <div
                  className="input-group-prepend tipo2"
                  onClick={() => updatePages("forwardAll")}
                >
                  <span className="input-group-text" id="basic-addon2">
                    <span className={`tipo ${ArrowBigRight}`}></span>
                  </span>
                </div>
              </span>
            </div>
          )
          }
        </div>
      </PopupJurisprudencia>
      <div className="container-fluid">
        <Modal show={ativo} onHide={() => setAtivo(false)} size="xl" className="modal-xl animate__animated" style={{ zIndex: "30000" }}>
          <Modal.Header>
            <Modal.Title>Visualização</Modal.Title>
            <button className="btn btn-sm btnNoBack" type="button" onClick={() => setAtivo(false)}><img src={iconeX ? iconeX : null} className="mx-1" alt="botão fechar" /></button>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 container-jurisprudencia-modal-juris">
                  <span>
                    <b>Tipo: </b>
                    {dados.tipo}
                  </span>
                  <br />
                  <span>
                    <b>Recurso: </b>
                    {dados.recurso_nome}
                  </span>
                  <br />
                  <span>
                    <b>Número: </b>
                    {dados.numero}
                  </span>
                  <br />
                  <span>
                    <b>Número: </b>
                    {dados.numero}
                  </span>
                  {dados?.origem?.nome ? (
                    <span>{`${dados.origem.nome} - ${dados.origem.sigla}`}</span>
                  ) : null}
                  <br />
                  <span>
                    <b>Orgão: </b>
                    <div dangerouslySetInnerHTML={{ __html: dados.orgao_julgador }} />
                  </span>
                  <br />
                  <span>{`julgamento: ${dados.data_julgamento} - publicação: ${dados.data_publicacao ? dados.data_publicacao : "-"
                    }`}</span>
                  <br />
                  <span>
                    <b>Tribunal: </b>
                    <div dangerouslySetInnerHTML={{ __html: dados?.tribunadados?.sigla }} />
                  </span>
                  <br />
                  <span>
                    <div dangerouslySetInnerHTML={{ __html: dados.texto_inteiro_teor }} />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-info btn-sm" onClick={() => setAtivo(false)}>
              <i className="fa fa-close" /> Fechar
            </button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          {/* <div className="col-12">
            <button className="btn btn-primary" onClick={buscar}>
              <span className="fa fa-search p-1"></span>
              Buscar
            </button>
          </div> */}
          <div className="col-12 mb-2">
            <ReactTooltip html={true} />
            <label>
              Pesquise por palavras
              <i
                className="fa fa-info ml-2"
                data-tip="Busque por tribunais, Ementa, Decisão ex: trf4<br/>
                O termo a ser pesquisado. Você pode pesquisar entre aspas duplas<br/>
                ou simples para match perfeito, além de poder utilizar os operadores<br/>
                lógicos AND e OR para conjunção e disjunção, e o operador NOT para excluir palavras."
              />
            </label>
            <div className="input-group">
              <input
                type="text"
                placeholder="digite palavras ou texto que deseja buscar"
                className="form-control"
                value={filtro.texto}
                onChange={(e) => setFiltro({ ...filtro, texto: e.target.value })}
              />
              <div
                className="input-group-prepend tipo2"
                onClick={handleVisibility}
              >
                <span className="input-group-text" id="basic-addon2">
                  <span className={`tipo ${Filter}`}></span>
                </span>
              </div>
            </div>
            <span className="numberSelected" style={{ display: filterCount > 0 ? "flex" : "none" }}>{filterCount}</span>
            {showFilter && (
              <BotaoFiltro
                ref={filterRef}
                dados={filterlist}
                onUpdateFilterCount={updateFilterCount}
              />
            )}
          </div>
          <div className="col-6">
            <ReactTooltip />
            <label>
              Data início
              <i
                className="fa fa-info ml-2"
                data-tip="Início do período de busca"
              />
            </label>
            <input
              type="date"
              className="form-control"
              value={filtro.inicio}
              onChange={(e) => setFiltro({ ...filtro, inicio: e.target.value })}
            />
          </div>
          <div className="col-6">
            <ReactTooltip />
            <label>
              Data fim
              <i
                className="fa fa-info ml-2"
                data-tip="Fim do período de busca"
              />
            </label>
            <input
              type="date"
              className="form-control"
              value={filtro.fim}
              onChange={(e) => setFiltro({ ...filtro, fim: e.target.value })}
            />
          </div>
          <div className="col-7 my-2">
            <h5 className="resultsEncontrados">{mostrarResults ? qtdResults > 1 ? qtdResults + ' resultados encontrados' : qtdResults + ' resultado encontrado' : null}</h5>
          </div>
          <div className="col-5 my-2">
            <button className="btn btnRolsPesquisar btn-sm" type="button" onClick={buscar}>
              <img src={pesquisarIcone ? pesquisarIcone : null} className="mx-1" alt="botão pesquisar" />
              Buscar
            </button>
          </div>
          {busca?.paginator ? (
            <div className="col-12 mb-3 contariner-paginacao">
              <button
                className="btn btn-primary btn-sm"
                disabled={busca?.paginator?.current_page <= 1}
                onClick={anterior}
              >
                Anterior
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={proxima}
                disabled={
                  busca?.paginator?.current_page ===
                  busca?.paginator?.total_pages
                }
              >
                Próxima
              </button>
            </div>
          ) : null}
          {/* {busca.items.length === 0 ? <p>Sem dados</p> : null} */}
        </div>
      </div>
    </div>
  );
};

export default ListaRols;