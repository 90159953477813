import React, { useState, useEffect } from "react";
import GoBack from "../../componentes/goBack";
import CadastroRol from "../Otimize/CadastroRol";
import CadastroSecao from "../Otimize/CadastroSecao";
import CadastroTexto from "../Otimize/CadastroTexto";
// import "./index.css";


const PjaCadastro = (props) => {
    const [tabNow, setTabNow] = useState("rols-tab");
    const { setLoading } = props;


    useEffect(() => {
        // tabNowText();
        // eslint-disable-next-line
    }, []);

return (
    <div className="bg-light">
    <div className="container robotR pt-5">
        <GoBack link={"/cadastros"} />
        <div className="row mb-2">
            <h4 className="col-md-10">Cadastro PJA</h4>
            {tabNow ? null : null}
            <div className="col-md-1">
                <a href="#/cadastros" className="btn btn-layout float-right">Voltar</a>
            </div>
        </div>
        <section className="pt-3">
            <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
            <li className="nav-item col-md-4">
                <a className="nav-link active color" id="rols-tab" data-toggle="tab" href="#rolsTab" role="tab" aria-controls="rolsTab" aria-selected="true" onClick={() => setTabNow("rols-tab")}>Rols</a>
            </li>
            <li className="nav-item col-md-4">
                <a className="nav-link color" id="secoes-tab" data-toggle="tab" href="#secoes" role="tab" aria-controls="secoes" aria-selected="false" onClick={() => setTabNow("secoes")}>Seções</a>
            </li>
            <li className="nav-item col-md-4">
                <a className="nav-link color" id="texto-tab" data-toggle="tab" href="#texto" role="tab" aria-controls="texto" aria-selected="false" onClick={() => setTabNow("texto")}>Textos</a>
            </li>
            </ul>
            <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="rolsTab" role="tabpanel" aria-labelledby="rols-tab">
                <div className="bg-branco">
                    <CadastroRol setLoading={setLoading}/>
                </div>    
            </div>
            <div className="tab-pane fade" id="secoes" role="tabpanel" aria-labelledby="secoes-tab">
                <div className="bg-branco">
                    <CadastroSecao setLoading={setLoading}/>
                </div>
            </div>
            <div className="tab-pane fade" id="texto" role="tabpanel" aria-labelledby="texto-tab">
                <div className="bg-branco">
                    <CadastroTexto setLoading={setLoading}/>
                </div>
            </div>
            </div> 
        </section>
    </div>
    </div>
);

};

export default PjaCadastro;